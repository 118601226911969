import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { SidebarService } from '../sidebar/sidebar.service';
import { Util } from 'src/app/app.util';
import { AuthService } from 'src/app/authentication/auth.service';
import { ReturnsService } from '../returns/returns.service';
import { GlobalService } from 'src/app/shared/services/global-service';
import { RepairService } from '../repair/repair.service';
import { ComponentComunicationSer } from 'src/app/shared/call-function-service';
import { CallShipmentFunctionSrc } from 'src/app/shared/call-shipmentfunction';
import { CommonService } from '../../shared/common.service'
import { NSUpdateModel } from "src/app/pages/portal/models/cp-repair.model";
declare var $: any;
@Component({
  selector: 'app-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.css']
})
export class CommandComponent implements OnInit {
  @Input() queue: string;
  @Input() module_code: any;
  @Input() IsScanBoxVisible: boolean;
  @Input() IsOpenScan: boolean;
  @Input() Breadcrumb: string;
  @Input() placeholderText: string;
  @Input() isAutoFocus: boolean;
  @Input() UniqValue :string;
  @Output() toggleScanBox = new EventEmitter<any>();
  @ViewChild('headerInput', {static: false}) headerInput: ElementRef;


  scanRMA: string = ''
  home_route: string = '';
  _route: string;
  _Timezones: any = [];
  Timezone: number;
  IsOpenScan1: boolean;
  ScanPalceHolder: string = "Scan RMA# or CO-Tracking#";
  ipAddress: string = 'NA';
  ip: any;
  count: number = 0;
  queueState: any;
  queueType: any;
  breadcrumb_arr: any = [];
  //@ViewChild('autoFocous') vc: any;
 
  @ViewChild("autoFocous") private autoFocous: ElementRef;
  constructor(private router: Router, private _menu: SidebarService, private _util: Util,
    private _auth: AuthService,
    private _returnService: ReturnsService,
    private _repairService: RepairService,
    private _router: Router,
    private cdref: ChangeDetectorRef,
    private _callShipmentFunctionSrc: CallShipmentFunctionSrc,
    private _glob: GlobalService,
    private _communicate: ComponentComunicationSer,
    private commonService: CommonService

  ) {

  }

  ngOnInit() {
    this.IsOpenScan1 = false;
    let _Timezones = this._glob.utilities.storage.get('_Timezones')
    if (!_Timezones) {
      this._menu.getAllTimeZone().subscribe(res => {
        this._Timezones = res.data[0];
        this._glob.utilities.storage.set('_Timezones', JSON.stringify(this._Timezones))
        this.Timezone = JSON.parse(this._auth.getAuthorizationUser()).tz;
      }, error => { });
    }
    else {
      this._Timezones = JSON.parse(_Timezones)[0];
      this.Timezone = JSON.parse(this._auth.getAuthorizationUser()).tz;
    }


  }



  setLanding() {
    this._menu.resetLanding(this.router.url).subscribe(
      data => {
        this._util.success("Your landing page has been reset.", "success");
        localStorage.removeItem('msglog');
        let aUserId = Number(sessionStorage.getItem('authUserId'));
        this._auth.SaveUserEventsLog(aUserId, this._auth.loginUserID, sessionStorage.getItem('navmenu'), sessionStorage.getItem('navurl'), 'Reset the landing page', this.ipAddress);
      },
      error => {
        this._util.error(error, 'error')
      }
    );
  }
  resetLanding() {
    this._menu.resetLanding('/back-office/home').subscribe(
      data => {
        this._util.success("Your landing page has been reset.", "success");
        localStorage.removeItem('msglog');
        let aUserId = Number(sessionStorage.getItem('authUserId'));
        this._auth.SaveUserEventsLog(aUserId, this._auth.loginUserID, sessionStorage.getItem('navmenu'), sessionStorage.getItem('navurl'), 'Reset the landing page', this.ipAddress);
      },
      error => {
        this._util.error(error, 'error')
      }
    );
  }
  async scanRMAByRMANumber(RMANumber: string, queue: string) {
    if (!RMANumber) {
      debugger
      return;
    }
    debugger;
    var workingQueue = ['glb', 'arr', 'rec', 'rplc', 'oow', 'cosad', 'repd', 'rjct', 'initiate']
    var previousQueue = ['created', 'arrivalQueue', 'repairShipment']
    //======new code for redirect on proper queue========
    if (workingQueue.indexOf(this.queueState) != -1 && previousQueue.indexOf(queue) == -1) {
      this.geturl(RMANumber);
    }
    else { // ====Previous working code======
      if (queue == 'created') {
        // CHeck permission
        if (!this._glob.utilities.CheckModuleEditPermission()) {
          this._glob.utilities.notify.warning('You do not have access to this section, please contact your System Administrator.');
          return;
        }


        this._returnService.scanRMA(RMANumber.replace('+', '-'), 'created', 'received').subscribe(_result => {

          if (_result.recordsets[0][0].rma_number && _result.recordsets[0][0].rma_number != '') {

            this._returnService.automaticClosureOfItems({ return_order_number: _result.recordsets[0][0].rma_number }).subscribe(automaticClosureResult => {

            });
            sessionStorage.setItem("OpenQueue", '');
            this._util.success("RMA# / Tracking# " + _result.recordsets[0][0].rma_number + " has been scanned successfully.", "Success");
            if (_result.recordsets[0][0].tracking_number || _result.recordsets[0][0].rma_number) {
              if (_result.recordsets[0][0] && _result.recordsets[0][0].RmaProcessEvent) {
               this.rmaProcess(_result.recordsets[0][0].rma_number, _result.recordsets[0][0].brandCode);
              }
              this.scanRMA = '';
              this._router.navigate(['back-office/returns/queue/received/' + _result.recordsets[0][0].rma_number + '/0']);
            }
          }
          else if (_result.recordsets[0][0].ErrorMessage == ' This RMA has already been scanned') {
            //==========update RA in Netsuite=============
            // var _NSUpdateModel:NSUpdateModel = new NSUpdateModel()
            // var raData = JSON.parse( _result.recordsets[1][0]['RAUpdateData'])
            // _NSUpdateModel.type = raData[0]['updatetype'];//"update"; // done
            // _NSUpdateModel.transtype = raData[0]['transType'];//"RA"; // done
            // _NSUpdateModel.nstransid = raData[0]['RAID'];//netSuite && netSuite['CaseID'];
            // _NSUpdateModel.value = raData[0]['statusVal'].find(x=>x.statusName=='Partially Received')['statusValue']
            // _NSUpdateModel.field = raData[0]['updateField']
            // this._repairService.CreateUpdateRAinNS(_NSUpdateModel)
            //===============================================
            this._router.navigate(['back-office/returns/queue/' + _result.recordsets[0][0].Status.toLowerCase() + '/' + RMANumber + '/0']);
          }
          else {
            this._util.error(_result.recordsets[0][0].ErrorMessage, "Error");
          }
        });
      }
      else if (queue == 'arrivalQueue') {
        if (this._glob.utilities.CheckModuleEditPermissionForRepair('edit')) {
          this.scanRMAforRepair(RMANumber);
        }
        else {
          this._util.warning("You don't have permission. Please contact to admin.", "Warning");
        }

      }
      else if (queue == 'awaiting_repair') {
        if (this._glob.utilities.CheckModuleEditPermissionForRepair('assign')) {
          this.AssignRmaUrl(RMANumber);
        }
        else {
          this._util.warning("You don't have permission. Please contact to admin.", "Warning");
        }

      }

      else if (queue == 'repairShipment') {
        if (this._glob.utilities.CheckModuleEditPermissionForRepair('generateshippinglabel')) {

          // var retUrl = await this.geturlAsync(RMANumber)
          // if (retUrl.recordsets[0][0]['redirecturl'].includes('/back-office/repair/queue/repd/')) {
          this._callShipmentFunctionSrc.openScannedRMA(RMANumber);
          this.scanRMA = '';
          this.IsOpenScan = false;
          // }
          // else if (retUrl.recordsets[0][0]['redirecturl'].includes('shipment')) {
          //   this._util.error("The RMA#" + RMANumber + " has been already shipped.", 'error')
          // }
          // else if (retUrl.recordsets[0][0]['redirecturl']) {
          //   this.router.navigate([retUrl.recordsets[0][0]['redirecturl']]);
          // }


          // else {
          //   this._util.error("Invalid RMA# " + RMANumber, 'error')
          // }
        }
        else {
          this._util.warning("You don't have permission. Please contact to admin.", "Warning");
        }

      }
      else if (queue == 'in_repair' || queue == 'repair_Type') {
        this.geturl(RMANumber);
      }
      else {

        // Check Permission
        if (this._glob.utilities.CheckModuleEditPermission()) {
          this.scanRMA = '';
          this.IsOpenScan = false;
          this._router.navigate(['back-office/returns/queue/' + queue + "/" + RMANumber + '/0']);
        }
        else {
          this._glob.utilities.notify.warning('You do not have access to this section, please contact your System Administrator.');
        }


      }
    }
  }

  rmaProcess(rma,brandCode) {
    let requestBody = {
      brandCode: brandCode,
      rmaNumber: rma,
      status:'Received'
    }
    this._returnService.RMAProcess(requestBody).subscribe((result) => {
      if (result.isSuccessful) {
        if (result.resultSet && result.resultSet.length>0) {
          result.resultSet.forEach(element => {
            if (!element.isSuccessful) {
              if (element.apiType =="inventorytransfer") {
                this._util.error("Inventory transfer is failed on NetSuite", "Alert");
              } else if (element.apiType =="itemreceipt") {
                this._util.error("Item Receipt is failed on NetSuite", "Alert");
              } else if (element.apiType =="updaterma") {
                this._util.error("RMA updattion is failed on NetSuite", "Alert");
              }
            }
          });
        }
      }
    }, (error) => {
      this._util.error(error, "Alert");
    });
  }


  scanRMAforRepair(RMANumber) {
    try {
      this._repairService
        .scanRMA(RMANumber, 'arr', "initiated", null)
        .subscribe((_result) => {
          debugger;
          if (_result.recordsets[0][0].Status == 1) {
            this._util.success("RMA# / Tracking# " + RMANumber + " has been scanned successfully.", "Success");
            if (_result.recordsets[0][0].rma_number) {
              this.scanRMA = '';
              this._router.navigate(['back-office/repair/queue/arr/' + _result.recordsets[0][0].rma_number + '/0']);
              //==========update RA in Netsuite=============
              try {
                var _NSUpdateModel: NSUpdateModel = new NSUpdateModel()
                _NSUpdateModel.type = _result.recordsets[1][0]['updatetype'];//"update"; // done
                _NSUpdateModel.transtype = _result.recordsets[1][0]['transType'];//"RA"; // done
                _NSUpdateModel.nstransid = _result.recordsets[1][0]['RAID'];//netSuite && netSuite['CaseID'];
                _NSUpdateModel.value = _result.recordsets[1][0]['statusVal']
                _NSUpdateModel.field = _result.recordsets[1][0]['updateField']
                //_NSUpdateModel.return_header_id =''
                _NSUpdateModel.rmaNumber = RMANumber
                //this._repairService.CreateUpdateRAinNS(_NSUpdateModel)
                this._repairService.UpdateRAinNSObservable(_NSUpdateModel)
                  .subscribe(result=>{
                      //==============item receipt=======================
                      var ItemReceipt = {"type": "create","transtype": "ItemReceipt","nstransid":_result.recordsets[1][0]['RAID'],"rmaNumber":RMANumber,'return_header_id':''}
                      this._repairService.CreateUpdateRAinNS(ItemReceipt)
                      //=================================================
                  },error=>{})
                
              }catch(ex){}
            }
            this.IsOpenScan = false;
            this.scanRMA = '';
          } else if (_result.recordsets[0][0].Status == 0) {
            if (_result.recordsets[0][0].ErrorMessage == 'RMA# already Scanned') {
              this.geturl(RMANumber);
            }
            //redirect to respective queue detail page
            else {
              this._util.error(_result.recordsets[0][0].ErrorMessage, "Error");
            }
          }
        });
    } catch (Error) {
      this._util.error(Error.message, "Error");
    }
  }

  resetTimezone() {
    this._menu.resetTimezone(this.Timezone).subscribe(
      data => {
        this._util.success("Your timezone has been reset.", "success");
        localStorage.removeItem('msglog');
        let aUserId = Number(sessionStorage.getItem('authUserId'));
        this._auth.SaveUserEventsLog(aUserId, this._auth.loginUserID, sessionStorage.getItem('navmenu'), sessionStorage.getItem('navurl'), 'Reset timezone', this.ipAddress);
      },
      error => {
        this._util.error(error, 'error')
      }
    );
  }
  toggleScan() {
    this.toggleScanBox.next();
  }
  ngOnChanges() {
    this.scanRMA = '';
    if( this.autoFocous)
    {
    this.autoFocous.nativeElement.focus();
    }
    var _thisRoute = this._menu.menus && this._menu.menus.filter(f => this.router.url.indexOf(f.routeKey) > -1)[0] || null;
    if (!this.Breadcrumb) {
      this._route = _thisRoute ? _thisRoute.description : '';
    }
    else {
      this._route = this.Breadcrumb;
    }
    
  
 

  }
  //============get redirect url from RMA scan===============
  async geturlAsync(rmanumber) {
    var retData = await this.commonService.getRedirectUrlAsync(rmanumber);
    return retData;
  }

  geturl(RMANumber) {
    if ((RMANumber && RMANumber.length == 0) || RMANumber == 'N/A')
      return;
    RMANumber =  btoa(RMANumber);//encodeURIComponent(RMANumber);
    this.commonService.getRedirectUrl(RMANumber)
      .subscribe(result => {
        if (result.recordsets[0][0]["redirecturl"]) {
          if (result.recordsets[0][0]["redirecturl"] == '/back-office/repair/queue/arr') {
            this.scanRMAforRepair(RMANumber);
          }
          else {
            this.router.navigate([result.recordsets[0][0]["redirecturl"]]);
          }
        }
        else {
          var tt = RMANumber;
          RMANumber = '';
          this.scanRMA = '';
          // this._util.error('RMA number : '+tt+' not found.',"Alert");
          this._util.error('Please enter a valid RMA# / Tracking#.', "Alert");

        }
      },
        err => {

        });
  }

  AssignRmaUrl(RMANumber) {
    if (RMANumber && RMANumber.length == 0)
      return;
    this.commonService.getAssignRmaUrl(RMANumber, 0)
      .subscribe(result => {

        if (result.recordsets[0][0]["statuValue"] == 1) {
          this.router.navigate([result.recordsets[0][0]["redirecturl"]]);
          this._util.success(result.recordsets[0][0]["message"], "Alert");
        }
        else if (result.recordsets[0][0]["statuValue"] == 2) {
          this._util.error(result.recordsets[0][0]["message"], "Alert");
        }
        else {
          var tt = RMANumber;
          RMANumber = '';
          // this._util.error('RMA number : '+tt+' not found.',"Alert");
          this._util.error('RMA# not found. Please enter a valid RMA#.', "Alert");

        }
      },
        err => {

        });
  }
  ngAfterViewInit() {

    this.router.events.forEach((event) => {

      if (event instanceof NavigationStart) {
      }

      else if (event instanceof NavigationEnd) {
        this.home_route = (this.router.url.split('/')[1] == 'back-office' ? '/back-office' : '/manage') + '/home';
        var _thisRoute = this._menu.menus && this._menu.menus.filter(f => this.router.url.indexOf(f.routeKey) > -1)[0] || null;

        if (!this.Breadcrumb && this.count == 0) {
          this.count = this.count + 1;
          this._route = _thisRoute ? _thisRoute.description : '';
          // For Return Inititate
          if (this.router.url.split('/').indexOf('initiate') > -1) {
            this._route = 'RMA Request';
          }
          if (_thisRoute?.module_code && _thisRoute?.module_code != "") {
            this._glob.utilities.storage.set('module_code', _thisRoute.module_code)
          }


          sessionStorage.setItem('navmenu', this._route);
          sessionStorage.setItem('navurl', this.router.url);
          if (this._route != "") {
            let userid = this._auth.loginUserID;
            var action = localStorage.getItem('msglog');
            if (action == '' || action == null || action === undefined)
              action = 'Navigated to ' + this._route;
            localStorage.removeItem('msglog');
            let aUserId = Number(sessionStorage.getItem('authUserId'));
            this.ipAddress = localStorage.getItem('ipaddress');
            this._auth.SaveUserEventsLog(aUserId, userid, this._route, this.router.url, action, this.ipAddress);
            this.count = 0;
          }
        }
        else {
          this._route = this.Breadcrumb;
        }
        this.breadcrumb_arr = this._route.split('/');
        var urlParts = this.router.url.split('/');
        this.queueState = urlParts[urlParts.length - 1]
        this.queueType = urlParts.findIndex(x => x == 'repair') == -1 ? 'return' : 'repair';
      }
    });
    //this.autoFocous.nativeElement.focus();
  }

 
  

}
