import { EventEmitter, Injectable, Output } from "@angular/core"
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ComponentComunicationSer {
  private isEditAcces = new ReplaySubject<boolean>(1);
  private isAddressvalue = this.isEditAcces.asObservable();

  private isAddAcces = new ReplaySubject<boolean>(1);
  private isAddressAddvalue = this.isAddAcces.asObservable();

  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  scannedRMA = new EventEmitter();

  constructor() { }

  onFirstComponentButtonClick(name) {
    this.invokeFirstComponentFunction.emit(name);
  }
  sendEditAccess(value) {
    this.isEditAcces.next(value);
  }

  getEditAcces() {
    return this.isAddressvalue;
  }

  sendAddAccess(value) {
    this.isAddAcces.next(value);
  }

  getAddAccess() {
    return this.isAddressAddvalue;
  }

}



