import { Injectable, Inject } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Language } from './common.model';
import { ConfigurationConstants } from '../shared/constants';
import { AuthService } from '../authentication/auth.service';
import { Step1FilterModel } from '../pages/portal/models/repair.model';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
@Injectable()
export class CommonService {
  private carrierAPI: string;
  private baseUrl: string;
  private _auth: AuthService;
  private httpBGClient: HttpClient;
  constructor(private http: HttpClient
    , private auth: AuthService, handler: HttpBackend) {
    this.httpBGClient = new HttpClient(handler)
    this.baseUrl = ConfigurationConstants.BASEURL;
    this.carrierAPI = ConfigurationConstants.BASEAPIURLForCarrier; //  fetch the carrier URL
    this._auth = auth;
  }
  phoneOnly(phone): boolean {
    let patt = /^([0|\+[0-9]{1,6})?([0-9]{9})$/;
    let result = patt.test(phone);
    return result;
  }
  getFreightFacility(data): Observable<any> {
    return this.http.post(`${this.baseUrl}repair/GetFreightFacility`, JSON.stringify(data));

  }
  getRegionLookup(): Observable<any> {
    return this.http.get(this.baseUrl + "common/regions/");
  }

  getBrandRegionLookup(): Observable<any> {
    return this.http.get(this.baseUrl + "common/getBrandReason/");
  }

  getRepairItems(startIndex, endIndex, sortColumn, sortDirection, filterValue, partnerID, requireQty): Observable<any> {

    return this.http.get(`${this.baseUrl}common/repairitems/${startIndex}/${endIndex - startIndex}/${sortColumn}/${sortDirection}/${filterValue}/${partnerID}/${requireQty}`);
  }

  // getAirportLookup(): Observable<any> {
  //   return this.http.get(this.baseUrl + "common/airports/");
  // }

  getAllLanguages(): Observable<any> {
    return this.http.get(this.baseUrl + "common/languages");
  }

  //getAllLocations not exists in any component
  // getAllLocations(): Observable<any> {
  //   return this.http.get(this.baseUrl + "common/AllLocations");
  // }

  getAllNodes(): Observable<any> {
    return this.http.get(this.baseUrl + "common/Node");
  }

  getTypeLookUpByName(typegroup: string): Observable<any> {
    return this.http.get(this.baseUrl + "common/typelookup/" + typegroup);
  }

  // getTypeLookUpsByName(typegroups: Array<string>): Observable<any> {
  //   return this.http.post(`${this.baseUrl}common/typelookups`, JSON.stringify(typegroups));
  // }

  // loadUsers(): Observable<any> {
  //   return this.http.get(this.baseUrl + "common/users");
  // }

  loadUOM(): Observable<any> {
    return this.http.get(this.baseUrl + "common/GetUOMs");
  }

  loadRoles(rt: string = null): Observable<any> {
    return this.http.get(this.baseUrl + "common/roles/" + rt);
  }

  // loadCarriers(): Observable<any> {
  //   return this.http.get(this.baseUrl + "common/GetCarriers");
  // }

  loadPartners(): Observable<any> {
    return this.http.get(this.baseUrl + "common/partners");
  }

  loadPartnersByUsers(userID: number): Observable<any> {
    return this.http.get(this.baseUrl + "common/partners/assigned/" + userID);
  }

  loadBrands(): Observable<any> {
    return this.http.get(this.baseUrl + "common/brands");
  }
  // loadReturnReasons(): Observable<any> {
  //   return this.http.get(this.baseUrl + "common/returnreasons");
  // }

  loadReasonRules(): Observable<any> {
    return this.http.get(this.baseUrl + "common/returnrules");
  }


  // getModuleStatus(moduleId: number): Observable<any> {
  //   return this.http.get(this.baseUrl + "common/status/" + moduleId);
  // }

  getModuleActions(moduleId: number): Observable<any> {
    return this.http.get(this.baseUrl + "common/action/" + moduleId);
  }

  getDefaultPartner(): Observable<any> {

    return this.http.get(this.baseUrl + 'common/defaultpartner/');
  }

  //getNewDefaultPartner not exists in any component
  // getNewDefaultPartner(): Observable<any> {

  //   return this.http.get(this.baseUrl + 'common/newdefaultpartner/');
  // }

  //getWFHierarchy not exists in any component
  // getWFHierarchy(moduleId: number): Observable<any> {
  //   return this.http.get(this.baseUrl + "common/hierarchy/" + moduleId);
  // }

  loadPermissionByModule(userId: number, partnerId: number, Module: string): Observable<any> {
    return this.http.get(`${this.baseUrl}module/GetPermissionByModule/${userId}/${partnerId}/${Module}`);
  }

  //getDashBoardCode method not exists in any component
  // getDashBoardCode(): Observable<any> {

  //   return this.http.get(this.baseUrl + 'common/DashBoardCode/');
  // }

  UploadUserImage(formdata: any): Observable<any> {
    return Observable.create(observer => {
      var req = new XMLHttpRequest();
      req.onreadystatechange = function (me: any = this) {
        if (req.readyState === 4 && this.status == 200) {
          var response = JSON.parse(req.responseText);
          observer.next(response);
          //call complete if you want to close this stream (like a promise)
          observer.complete();
        }
      }
      req.open("POST", `${this.baseUrl}common/userimage`);
      req.setRequestHeader('Authorization', 'Bearer ' + this._auth.getAuthorizationToken());
      req.setRequestHeader('scope', this._auth.getScope());
      req.setRequestHeader('user', this.auth.getAuthorizationUser());
      req.send(formdata);
    });
  }
  getBoxData(status): Observable<any> {
    return this.http.get(this.baseUrl + `common/boxdata/${status}`);
  }
  globallist(parm: any): Observable<any> {
    return this.http.post(`${this.baseUrl}common/globallist`, parm);
  }
  globalOnClick(payload: any, procedure: string): Observable<any> {
    return this.http.post(`${this.baseUrl}common/globalOnClick/${procedure}`, payload);
  }
  PostNetSuiteOrderData(order_number: string, returnData: string,portal:string, data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}repair/PostNetSuiteOrderData/${order_number}/${returnData}/${portal}`, data);// JSON.stringify(data) );
  }
  GetStateList(countryid: string): Observable<any> {
    const authUser = `{}`
    let access_token = ''
    try {
      access_token = JSON.parse(atob(localStorage.getItem(window.location.hostname))).access_token;

    }
    catch
    {

    }

    var httpOptions = {
      headers: new HttpHeaders({
        'scope-header': 'x-get-request',
        'Content-Type': 'application/json',
        'ud': btoa(btoa(authUser)),
        'scope': 's',
        'Authorization': 'Bearer ' + access_token
      })
    };
    return this.httpBGClient.get(
      `${this.baseUrl}repair/GeStateByCountryID/${countryid}`, httpOptions
    );
  }
  GenerateServiceNotes(data: any): Observable<any> {
    return this.http.post(`${this.carrierAPI}api/carrier/HTMLToPdf`, JSON.stringify(data));
  }
  GenerateServiceNotesBG(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(`${this.carrierAPI}api/carrier/HTMLToPdf`, JSON.stringify(data),httpOptions);
  }
  UpdateShippingLabel(data: any): Observable<any> {

    return this.http.post(`${this.baseUrl}portal/repair/UpdateShippingLabel`, JSON.stringify(data));
  }

  UpdateCustomerAddressStep4(data: any): Observable<any> {

    return this.http.post(`${this.baseUrl}portal/repair/UpdateCustomerAddressStep4`, JSON.stringify(data));
  }


  SendMail() {
    return this.http.post(`${this.baseUrl}repair/SendMailCV`, '');
  }
  //=================serivce for NS===========
  async CreateUpdateRAinNS(data: any): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })//,
    };
    return await this.httpBGClient.post(`${this.baseUrl}netSuite/createUpdateRA`, JSON.stringify(data), httpOptions).toPromise();
  }

  CreateUpdateRAinNSObservable(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })//,
    };
    return this.httpBGClient.post(`${this.baseUrl}netSuite/createUpdateRA`, JSON.stringify(data), httpOptions);
  }
  GetInboundSOData(SONumber: string): Observable<any> {
    return this.http.get(`${this.baseUrl}repair/GetInboundSOData/${SONumber}/${'ss'}`);
  }
  saveRMAOrderDetail_New(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}repair/SaveRMA`,
      JSON.stringify(data)
    );

  }
  // stripe payment

  paymentIntent(obj: any): Observable<any> {

    let host = obj.access_token;
    const config = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('authorization', host)
      .set('_sk', obj.return_order_number)

    return this.http.post(`${this.carrierAPI}api/carrier/create-payment-intent`, obj, {
      headers: config,
    })
  }

  SoundKlubRegistered(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}netSuite/soundKlubRegistered`,
      JSON.stringify(data)
    );
  }
  // generate the upload images and docurment in the aure blobs.
  uploadImages(_formData: any): Observable<any> {

    return this.http.post(`${this.baseUrl}portal/uploads/files`, _formData);
  }

  searchOrderByNetSuite(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}netSuite/getNetSuiteOrderNew`, JSON.stringify(data));
  }
  loadReturnData(): Observable<any> {
    return this.http.get(`${this.baseUrl}portal/repair/ReturnData`);
  }
  //-- model list
  getmodellist(productTypeID): Observable<any> {
    return this.http.get(
      `${this.baseUrl}repair/getmodellist/${productTypeID}`
    );
  }
  // -- get product type list
  getProductType(brand): Observable<any> {
    return this.http.get(
      `${this.baseUrl}repair/producttype/${brand}`
    );
  }
  // Get Orders List
  getOrdersList(
    filterModel: Step1FilterModel
  ): Observable<any> {

    let _IsGuidedSearch = filterModel.IsGuidedSearch == true ? 1 : 0;
    let isAdvancedSearch = false;
    return this.http.get(
      `${this.baseUrl}repair/getOrdersList/${filterModel.order_no}/${filterModel.productTypeID}/${filterModel.Email_ID}/${_IsGuidedSearch}/${filterModel.ReturnData}/${isAdvancedSearch}/${filterModel.source}`
    );
  }
  getOrdersListNew(
    filterModel: Step1FilterModel
  ): Observable<any> {

    let _IsGuidedSearch = filterModel.IsGuidedSearch == true ? 1 : 0;
    let isAdvancedSearch = false;
    return this.http.get(
      `${this.baseUrl}repair/getOrdersListNew/${filterModel.order_no}/${filterModel.productTypeID}/${filterModel.Email_ID}/${_IsGuidedSearch}/${filterModel.ReturnData}/${isAdvancedSearch}/${filterModel.source}/null/search`
    );
  }
  // Generate shipping label
  GenerateShipLabel(data: any): Observable<any> {
    if (data.brand.toLowerCase() == "zw")
      return this.http.post(`${this.carrierAPI}api/carrier/GetLabelByPackage`, JSON.stringify(data));
    else
      return this.http.post(`${this.carrierAPI}api/carrier`, JSON.stringify(data));
  }

  PostDataToServer(proc_name: string, data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}common/postdataserver/${proc_name}`,
      JSON.stringify(data)
    );
  }

  // addree validation
  UpsAddressValidation(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}uspsTest/uspsaddressnew`,
      JSON.stringify(data)

    );

  }

  //======get redirect url for workbench===========
  getRedirectUrl(returnnumber): Observable<any> {
    debugger;
    return this.http.get(`${this.baseUrl}gdpr/getWorkBenchRoutingData/${returnnumber}`);
  }

  //======get redirect url for workbench===========
  getAssignRmaUrl(returnnumber, userId): Observable<any> {
    //returnnumber =  encodeURIComponent(returnnumber);
    returnnumber = btoa(returnnumber);
    return this.http.get(`${this.baseUrl}gdpr/getAssignRmaUrl/${returnnumber}/${userId}`);
  }

  async getRedirectUrlAsync(returnnumber: any): Promise<any> {
    //returnnumber = encodeURIComponent(returnnumber);
    returnnumber = btoa(returnnumber);
    return await this.http.get(`${this.baseUrl}gdpr/getWorkBenchRoutingData/${returnnumber}`).toPromise();
  }

  getPageSettings(): Observable<any> {
    let para = {};
    return this.http.post(
      `${this.baseUrl}common/postdataserver/usp_get_Page_control_setting`,
      JSON.stringify(para)
    );
  }

  save_send_deactivated_brand_email(data): Observable<any> {
    return this.http.post(
      `${this.baseUrl}lookup/new_save_send_deactivated_brand_email`,
      JSON.stringify(data)
    );
  }

  ProductCatelogFileUpload(data): Observable<any> {
    return this.http.post(`${this.baseUrl}productcatalog/ImportProductCatalog`, data);
  }
  PartCatelogFileUpload(data): Observable<any> {
    return this.http.post(`${this.baseUrl}productcatalog/ImportProductPartCatalog`, data);
  }
  ProductCatelogImportData(data): Observable<any> {
    debugger
    return this.http.post(`${this.baseUrl}productcatalog/ProductCatalogSaveData`, data);
  }
  PartCatelogImportData(data): Observable<any> {
    debugger
    return this.http.post(`${this.baseUrl}productcatalog/PartCatalogSaveData`, data);
  }
  downloadExcelFile(data, filename = 'ExcelTemplate') {
    // Excel Title, Header, Data
    const title = 'Excel Template';
    const header = 
    [ 'ProductSKU','PartSKU','Quantity'];

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Excel File');


// Add Row and formatting
    //const subTitleRow = worksheet.addRow(['Date : 06-09-2020']);

    //worksheet.mergeCells('A1:D2');
                // Add Header Row
                const headerRow = worksheet.addRow(header);

                // Cell Style : Fill and Border
                    headerRow.eachCell((cell, number) => {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    bgColor: { argb: 'FFFFFF' },
                    fgColor: { argb: 'FFFFFF' }
                  };
                  cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                });

// Blank Row
  
// Footer Row
    const footerRow = worksheet.addRow(['This is system generated excel sheet']);
    footerRow.getCell(1).fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFFFFF' }
};
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

// Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

// Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  fs.saveAs(blob, 'ExcelTemplate.xlsx');
});

  }
  //--Dheer-----

  downloadProductCatalogExcelFile(data, filename = 'data') {
    debugger

    let excelData = this.ConvertToExcelProductCatalog(data, []);
    console.log(excelData)
    let blob = new Blob(['\ufeff' + excelData], { type: 'text/xls;charset=utf-8;' });
    let downloadLink = document.createElement("a");

    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      downloadLink.setAttribute("target", "_blank");
    }
    downloadLink.setAttribute("href", url);
    downloadLink.setAttribute("download", filename + ".xls");
    downloadLink.style.visibility = "hidden";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  ConvertToExcelProductCatalog(objArray, headerList) {
    debugger
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
     let row = 'ProductName,Description,Category,ItemType,Sku,Upc,Ean,SerialNumber,Brand,Organization,ImageUrl,UnitPrice,CurrencyCode,Length,Width,Height,DimensionUom,Weight,WeightUom,Color,Size,IsBer,IsReturnable,WarrantyDays';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
  downloadProductCatalogCsvFile(data, filename = 'data') {
    let csvData = this.ConvertToCSVProductCatalog(data, []);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSVProductCatalog(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
     let row = 'ProductName,Description,Category,ItemType,Sku,Upc,Ean,SerialNumber,Brand,Organization,ImageUrl,UnitPrice,CurrencyCode,Length,Width,Height,DimensionUom,Weight,WeightUom,Color,Size,IsBer,IsReturnable,WarrantyDays';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
  //--End-----
  //--download .csv file
  downloadCsvFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, [ 'ProductSKU','PartSKU','Quantity']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array =
        typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'SNO, ';
    for (let index in headerList) {
        row += headerList[index] + ', ';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';    
    for (let i = 0; i < array.length; i++) {
        let line = (i + 1);
        for (let index in headerList) {
            let head = headerList[index];
            line += array[i][head];
        }
        str += line;
    }
    return str;
}
  ConverttoTxt(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
     let row = 'ProductName,Description,Category,ItemType,Sku,Upc,Ean,SerialNumber,Brand,Organization,ImageUrl,UnitPrice,CurrencyCode,Length,Width,Height,DimensionUom,Weight,WeightUom,Color,Size,IsBer,IsReturnable,WarrantyDays';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
  //--download .txt file
  downloadTxtFile(data, filename = 'data') {
    debugger
    let txtData = this.ConverttoTxt(data, []);
    console.log(txtData)
    let blob = new Blob(['\ufeff' + txtData], { type: 'text/txt;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".txt");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getBrandDIConfiguration(PartnerId): Observable<any> {
    return this.http.get(
      `${this.baseUrl}common/getBrandDI/${PartnerId}`
    );
  }
  searchOrderByRLApi(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}RLApi/OrderFetch`, JSON.stringify(data));
  }

  getDetailsForLabel(rmaNumber: any): Observable<any> {
    return this.http.get(`${this.baseUrl}common/getDetailsForLabel/${rmaNumber}`);
  }

  updateRMALabel(rmaNumber: any, payload:any): Observable<any> {
    return this.http.post(`${this.baseUrl}common/updateRMALabel/${rmaNumber}`,payload);
  }

  getlabels(payload: any): Observable<any> {
    if (payload.brand.toLowerCase() == "zw")
      return this.http.post(`${this.carrierAPI}api/carrier/GetLabelByPackage`, JSON.stringify(payload));
    else
      return this.http.post(`${this.carrierAPI}api/carrier`, JSON.stringify(payload));
  }

StoreAssginedtoUser(): Observable<any> {
  return this.http.get(`${this.baseUrl}common/StoreAssginedtoUser`);
}
/** BO Guided Search Post Return and Repair Start RK */

postOrdersList(
  filterModel: Step1FilterModel,
  extra:any=null
): Observable<any> {

  let _IsGuidedSearch = filterModel.IsGuidedSearch == true ? 1 : 0;
  let isAdvancedSearch = false;
  let userId = this._auth.getUserEmailCPPortal();
var data = { "order_no": filterModel.order_no, "productTypeID": filterModel.productTypeID,
  "Email_ID": filterModel.Email_ID, "IsGuidedSearch": _IsGuidedSearch, "ReturnData": filterModel.ReturnData, "isAdvancedSearch": filterModel.isAdvancedSearch,
   data: extra, "source": filterModel.source, "email": userId,
   "guided": "guided" }
    return this.http.post(`${this.baseUrl}portal/repair/postgetOrdersListBO/`, JSON.stringify(data));
}

/** BO Guided Search Post Return and Repair End RK */


/** Download Invalid RMA Start */

downloadInvalidRMAExcelFile(dataList, filename = 'data',headreList) {

          // Excel Title, Header, Data
          const title = 'Excel Template';
          // Create workbook and worksheet
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Excel File');
          // Add headers
          const headers = Object.keys(dataList[0]);
          const headerRow = worksheet.addRow(headers);
          // Cell Style : Fill and Border
                            headerRow.eachCell((cell, number) => {
                          cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            bgColor: { argb: 'FFFFFF' },
                            fgColor: { argb: 'FFFFFF' }
                          };
                          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                        });
          // Add data
          dataList.forEach((item) => {
          const row:any = [];
            headers.forEach((header) => {
              row.push(item[header]);
            });
            worksheet.addRow(row);
          });
          // Generate Excel File with given name
          workbook.xlsx.writeBuffer().then((data: any) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, filename+'.xlsx');
          });



   
  }
/** Download Invalid RMA Start */

}