import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigurationConstants } from '../../shared/constants'
import { Menu } from './menu.model';
import { TypedJson } from '../../app.util';
import { GlobalService } from 'src/app/shared/services/global-service';


declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class SidebarService {

  endpoint_url: string = ConfigurationConstants.BASEURL + "menu/listUserMenus";
  userRole_url: string = ConfigurationConstants.BASEURL + "menu/listUserRole";
  private _menus: Array<any> = new Array<any>();
  private _Menus: Array<any> = new Array<any>();

  get Menus(): Array<Menu> {
    return JSON.parse(localStorage.getItem('routes'));
  }
  set Menus(lv: Array<Menu>) {
    this._Menus = lv;
    localStorage.setItem('routes', JSON.stringify(this._Menus));
  }

  get menus(): Array<any> {
    return JSON.parse(localStorage.getItem('resource'));
  }
  set menus(lv: Array<any>) {
    this._menus = lv;
    localStorage.setItem('resource', JSON.stringify(this._menus));
  }

  constructor(private http: HttpClient, private _gv: GlobalService) { }

  getDyMenu(): Observable<any> {
    return this.http.get(`${this.endpoint_url}`).pipe(
      map((res: Response) => this.extractData(res)));
  }
  getDyMenuSync() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(`${this.endpoint_url}`)
        .toPromise()
        .then(
          res => {
            resolve((res: Response) => this.extractData(res));
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }
  //==========GetUserRoleAsync===============
  getUserRoleSync() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(`${this.userRole_url}`)
        .toPromise()
        .then(
          res => {
            this._gv.utilities.storage.set('RLRole', JSON.stringify(res[1]));
            this._gv.utilities.storage.set('UserBrands', JSON.stringify(res[2]));
            //console.log(JSON.parse(this._gv.utilities.storage.get('RLRole')));
            //resolve((res: Response) => this.extractData(res));
            resolve(res[0]);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }
  async extractData(_Menus) {
    debugger;
    this.Menus = $.map(TypedJson.parse<Array<Menu>>(_Menus), function (v, i) { return new Menu(v); });
    var MenuRoutes = [];
    for (let p of _Menus) {

      if (JSON.parse(p.Childs).length > 0) {
        var childMenus = JSON.parse(p.Childs.toString());
        for (let m of childMenus) {
          if (m.Childs.length > 0) {
            for (let c of m.Childs) {
              MenuRoutes.push({ module_code: c.module_code, 'routeKey': c.NavigateURL, 'title': c.Title, 'description': c.Description, 'parent': [m.Title, p.Title] });
            }
          }
          else {
            MenuRoutes.push({ module_code: m.module_code, 'routeKey': m.NavigateURL, 'title': m.Title, 'description': m.Description, 'parent': [p.Title] });
          }
        }
      }
      else {
        MenuRoutes.push({ module_code: p.module_code, 'routeKey': p.NavigateURL, 'title': p.Title, 'description': p.Description, 'parent': '' });
      }
    }
    // let m =  JSON.parse(JSON.stringify(MenuRoutes));
    // m = m.filter(x => x.module_code == 'M2391')[0];
    // m.routeKey = "/back-office/returns/rmacreate";
    // MenuRoutes.push(m);

    this.menus = MenuRoutes;
  }
  resetLanding(route) {
    return this.http.post(`${ConfigurationConstants.BASEURL}common/resetLanding`, { _route: route });
  }
  resetTimezone(_tz) {
    return this.http.post(`${ConfigurationConstants.BASEURL}common/resetTimezone`, { tz: _tz });
  }
  getAllTimeZone(): Observable<any> {
    return this.http.get(`${ConfigurationConstants.BASEURL}TimeZones/`);
  }
}
