import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackOfficeComponent } from '../back-office/back-office.component';
import { AuthGuard } from '../auth.guard';


const routes: Routes = [
  {
    path: '',
    component: BackOfficeComponent,
    children: [
      { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
      { path: 'partners', loadChildren: () => import('./facilities/facilities.module').then(m => m.FacilitiesModule), canActivateChild: [AuthGuard] },
      // { path: 'accounts', loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule), canActivateChild: [AuthGuard] },
      { path: 'brands', loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule), canActivateChild: [AuthGuard] },
      //{ path: 'brands', loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule), canActivateChild: [AuthGuard] },
      { path: 'returnreason', loadChildren: () => import('./returnreason/returnreason.module').then(m => m.ReturnReasonModule), canActivateChild: [AuthGuard] },
      { path: 'rules', loadChildren: () => import('./rules/rule.module').then(m => m.RuleModule), canActivateChild: [AuthGuard] },
      { path: 'notificationtemplate', loadChildren: () => import('./grid/grid.module').then(m => m.GridModule), canActivateChild: [AuthGuard] },
      { path: 'notificationtemplate/:code', loadChildren: () => import('./notificationtemplate/notificationtemplate.module').then(m => m.NotificationTemplateModule), canActivateChild: [AuthGuard] },
      { path: 'modelmaster', loadChildren: () => import('./modelmaster/modelmaster.module').then(m => m.ModelMasterModule), canActivateChild: [AuthGuard] },
      { path: 'role', loadChildren: () => import('./role/role.module').then(m => m.RoleModule), canActivateChild: [AuthGuard] },
      { path: 'grade', loadChildren: () => import('./grademaster/grademaster.module').then(m => m.GrademasterModule) },
      { path: 'fuser/PTR', loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivateChild: [AuthGuard] },
      { path: 'rluser', loadChildren: () => import('./rluser/rluser.module').then(m => m.RluserModule), canActivateChild: [AuthGuard] },
      { path: 'location', loadChildren: () => import('./location/location.module').then(m => m.LocationModule), canActivateChild: [AuthGuard] },
      { path: 'region', loadChildren: () => import('./region/region.module').then(m => m.RegionModule), canActivateChild: [AuthGuard] },
      { path: 'country', loadChildren: () => import('./Country/Country.module').then(m => m.CountryModule), canActivateChild: [AuthGuard] },
      { path: 'issue', loadChildren: () => import('./Issue/Issue.module').then(m => m.IssueModule), canActivateChild: [AuthGuard] },
      { path: 'defectCode', loadChildren: () => import('./defect-code/defect-code.module').then(m => m.DefectCodeModule), canActivateChild: [AuthGuard] },
      { path: 'category', loadChildren: () => import('./product-category/product-category.module').then(m => m.ProductCategoryModule), canActivateChild: [AuthGuard] },
      { path: 'payment-configuration', loadChildren: () => import('./payment-configuration/payment-config.module').then(m => m.PaymentConfigModule), canActivateChild: [AuthGuard] },   
      { path: 'language', loadChildren: () => import('./language/language.module').then(m => m.LanguageModule), canActivateChild: [AuthGuard] },
      { path: 'returns', loadChildren: () => import('./returns/returns.module').then(m => m.ReturnsModule), canActivateChild: [AuthGuard] },
      { path: 'reports', loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule), canActivateChild: [AuthGuard] },
      { path: 'reports/activity', loadChildren: () => import('./reports/activity/activity.module').then(m => m.ActivityModule), canActivateChild: [AuthGuard] },
      { path: 'reports/closedrma', loadChildren: () => import('./reports/closedrma/closedrma.module').then(m => m.ClosedrmaModule), canActivateChild: [AuthGuard] },
      { path: 'reports/teamproductivity', loadChildren: () => import('./reports/teamproductivity/teamproductivity.module').then(m => m.TeamproductivityModule), canActivateChild: [AuthGuard] },
      { path: 'page-control-setting', loadChildren: () => import('./page-control-setting/page-control-setting.module').then(m => m.PageControlSettingModule), canActivateChild: [AuthGuard] },
      { path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule) },
      { path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule), canActivateChild: [AuthGuard] },
      
      { path: 'approvalsetup', loadChildren: () => import('./approvalsetup/approvalsetup.module').then(m => m.ApprovalSetupModule), canActivateChild: [AuthGuard] },
      { path: 'customerReview', loadChildren: () => import('./CustomerReview/customer-review.module').then(m => m.CustomerReviewModule), canActivateChild: [AuthGuard] },
      { path: 'carrier-config', loadChildren: () => import('./carrier-configuration/carrier-configuration.module').then(m => m.CarrierConfigurationModule) },
      { path: 'config', loadChildren: () => import('./carrier-configuration/carrier-configuration.module').then(m => m.CarrierConfigurationModule), canActivateChild: [AuthGuard] },
      { path: 'wrconfiguration', loadChildren: () => import('./WRConfiguration/WRConfiguration.module').then(m => m.WRConfigurationModule), canActivateChild: [AuthGuard] },
      { path: 'return-policy', loadChildren: () => import('./returnpolicy/returnpolicy.module').then(m => m.ReturnpolicyModule), canActivateChild: [AuthGuard] },
      { path: 'repair', loadChildren: () => import('./repair/repair.module').then(m => m.RepairModule), canActivateChild: [AuthGuard] },
      { path: 'customer-order', loadChildren: () => import('./customerOrder/customerOrder.module').then(m => m.customerOrderModule), canActivateChild: [AuthGuard] },
      { path: 'gdpr', loadChildren: () => import('./gdpr/gdpr.module').then(m => m.gdprModule), canActivateChild: [AuthGuard] },
      { path: 'nopermission', loadChildren: () => import('./no-permission/no-permission.module').then(m => m.NoPermissionModule) },
      { path: 'workbench', loadChildren: () => import('./work-bench/workBench.module').then(m => m.WorkBenchModule), canActivateChild: [AuthGuard] },
      { path: 'orderLookUp', loadChildren: () => import('./order-lookup/orderlookup.module').then(m => m.OrderLookUpModule), canActivateChild: [AuthGuard] },
      { path: 'order-generate', loadChildren: () => import('./order-generate/order-generate.module').then(m => m.OrderGenerateModule) },
      { path: 'rtv', loadChildren: () => import('./Rtv/rtv.module').then(m => m.RtvModule) },
      { path: 'order-bundled-assemble', loadChildren: () => import('./assembled-bundled/assembled-bundled.module').then(m => m.AssembledBundledModule), canActivateChild: [AuthGuard] },
      { path: 'sales-order', loadChildren: () => import('./sales-order/sales-order.module').then(m => m.SalesOrderModule) },
      { path: 'return-types', loadChildren: () => import('./return-types/return-types.module').then(m => m.ReturnTypesModule) },
      { path: 'workflow', loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule), canActivateChild: [AuthGuard] },
      { path: 'return-flow', loadChildren: () => import('./return-flow-rules/return-flow-rules.module').then(m => m.ReturnFlowRulesModule), canActivateChild: [AuthGuard]},
      { path: 'stores', loadChildren: () => import('./storeMaster/storeMaster.module').then(m => m.storeMasterModule), canActivateChild: [AuthGuard] },
      { path: 'accounts', loadChildren: () => import('./Accounts/Accounts.module').then(m => m.AccountsModule), canActivateChild: [AuthGuard] }

    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackOfficeRoutingModule { }
