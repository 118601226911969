import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { Environmenter } from './environmenter.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    private _isProcessing: boolean = false;
 
    public get isProcessing(): boolean {
        return this._isProcessing;
    }

  

    public set isProcessing(v: boolean) {
        this._isProcessing = v;
    }




    get module_code(): string {
        return this.utilities.storage.get('mi');
    }
    private _page_title: string;
    private _page_parent: string;

    get page_title(): string {
        return this._page_title;
    }
    get request_id(): string {
        var rString = this.randomString(32) + "|" + this.randomString(16);
        return rString;
    }
    randomString(length) {
        var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    get page_parent(): string {
        return this._page_parent;
    }
    set page_title(value) {
        this._page_title = value;
    }

    set page_parent(value) {
        this._page_parent = value;
    }
    private _path: string;
    get path(): string {
        return this._path;
    }
    set path(value) {
        this._path = value;
        let mi: any = {};
        if (value == "home") {
            this.page_title = '';
            this.page_parent = '';

        }
        else {
            for (var i = 0; i < this.menus.length; i++) {
                if (this.menus[i].childs !== undefined) {
                    for (var j = 0; j < this.menus[i].childs.length; j++) {
                        if (this.menus[i].childs[j].navigate_url == value) {
                            if (value == "dashboard") {
                                this.page_parent = this.menus[i].description;
                                this.page_title = '';
                                this.utilities.storage.set('mi', this.menus[i].childs[j].module_code);
                            }
                            else {
                                this.page_parent = this.menus[i].description;
                                this.page_title = this.menus[i].childs[j].description;
                                if (this.page_parent == this.page_title) {
                                    this.page_title = '';
                                }
                                if (this.menus[i].childs.length <= 1) {
                                    this.page_parent = '';
                                    this.page_title = this.menus[i].childs[j].description;
                                }
                                this.utilities.storage.set('mi', this.menus[i].childs[j].module_code);
                            }

                        }
                    }
                }
            }
        }
    }

    get menus(): any {
        return JSON.parse((this.utilities.storage.get('resource') || '[]'));
    }
    get homemenus(): any {
        return JSON.parse((this.utilities.storage.get('homepage') || '[]'));
    }
    _environment: any;
    private httpClient: HttpClient;
    constructor(private $http: HttpClient, private $toastr: ToastrService,

        handler: HttpBackend,
        private environment: Environmenter, private $router: Router) {
        this.httpClient = new HttpClient(handler)
        this._environment = environment.getGlobalEnvironment();
    }

    public config = new class {

        constructor(public global: GlobalService) {
        }

    }(this);

    public constants = new class {

        constructor(public global: GlobalService) {

        }
    }(this);

    public utilities = new class {

        constructor(public global: GlobalService) {
        }
        public storage = new class {
            constructor(public global: GlobalService) {
            }

            //The set method is use for encrypt the value.
            set(key, value) {
                var encrypted = CryptoJS.AES.encrypt(value, 'ret001');
                localStorage.setItem(key, encrypted.toString());
            }

            //The get method is use for decrypt the value.
            get(key) {

                var value = localStorage.getItem(key);
                if (!value)
                    return '';
                var decrypted = CryptoJS.AES.decrypt(value, 'ret001');
                return decrypted.toString(CryptoJS.enc.Utf8);
            }

        }(this.global)

        public notify = new class {
            constructor(public global: GlobalService) {
            }

            success(message, title?) {
                this.global.$toastr.success(message, title);
            }
            warning(message, title?) {
                this.global.$toastr.warning(message, title);
            }
            error(message, title?) {
                this.global.$toastr.error(message, title);
            }
            info(message, title?) {
                this.global.$toastr.info(message, title);
            }

        }(this.global)

        public communicate = new class {
            constructor(public global: GlobalService) {
            }

            alert(title?, message?) {
                alert(message);
            }
            confirm(title?, message?, button?) {
                confirm(message);
            }

        }(this.global)

        getUserDetail(): any {
            if (this.global.utilities.storage.get('usedetails') != '') {
                return JSON.parse(this.global.utilities.storage.get('usedetails'));
            }
        }


        getLanguage(): any {
            return this.global._environment.lang;
        }

        Decrypt(value, KEY, IV): any {
            var key = CryptoJS.enc.Utf8.parse(KEY);
            var iv = CryptoJS.enc.Utf8.parse(IV);
            var encryptedHexStr = CryptoJS.enc.Hex.parse(value);
            var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
            var decrypt = CryptoJS.AES.decrypt(srcs, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
            return decryptedStr.toString();
        }

        Encrypt(str, KEY, IV) {

            var key = CryptoJS.enc.Utf8.parse(KEY);
            var iv = CryptoJS.enc.Utf8.parse(IV);
            var encrypted: any;
            var srcs = CryptoJS.enc.Utf8.parse(str);
            encrypted = CryptoJS.AES.encrypt(srcs, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            return encrypted.ciphertext.toString();
        }

        CheckModuleAddPermission() {

            if (this.global.utilities.storage.get('RLRole') != '') {
                var RolePermission = JSON.parse(this.global.utilities.storage.get('RLRole'))
                let module_code = this.global.utilities.storage.get('module_code');
                if (RolePermission.find(x => (x.functionname.toLowerCase() == 'add')
                    && x.module_code == module_code)) {
                    return true;
                }
                else {
                    return false;
                }

            }
            else {
                return false;
            }


            //this.global.$router.navigate.
        }
        CheckModuleEditPermission() {

            if (this.global.utilities.storage.get('RLRole') != '') {
                var RolePermission = JSON.parse(this.global.utilities.storage.get('RLRole'))
                let module_code = this.global.utilities.storage.get('module_code');
                if (RolePermission.find(x => (x.functionname.toLowerCase() == 'edit')
                    && x.module_code == module_code)) {
                    return true;
                }
                else {
                    return false;
                }

            }
            else {
                return false;
            }


            //this.global.$router.navigate.
        }
        CheckModulePermissionWithFunCode(FunCode) {
          
            if (this.global.utilities.storage.get('RLRole') != '') {
           
                var RolePermission = JSON.parse(this.global.utilities.storage.get('RLRole'))
                let module_code = this.global.utilities.storage.get('module_code');
                if (RolePermission.find(x => (x.functionname.toLowerCase() == FunCode.toLowerCase())
                    && x.module_code == module_code)) {
                    return true;
                }
                else {
                    return false;
                }

            }
            else {
                return false;
            }


            //this.global.$router.navigate.
        }

        CheckModulePermissionWithModuleCode(FunCode, module_code) {            
            if (this.global.utilities.storage.get('RLRole') != '') {           
                var RolePermission = JSON.parse(this.global.utilities.storage.get('RLRole'))
                
                if (RolePermission.find(x => (x.functionname.toLowerCase() == FunCode.toLowerCase())
                    && x.module_code == module_code)) {
                    return true;
                }
                else {
                    return false;
                }

            }
            else {
                return false;
            }


            //this.global.$router.navigate.
        }
        CheckModuleViewPermission() {

            if (this.global.utilities.storage.get('RLRole') != '') {
                var RolePermission = JSON.parse(this.global.utilities.storage.get('RLRole'))
                let module_code = this.global.utilities.storage.get('module_code');
                if (RolePermission.find(x => (x.functionname.toLowerCase() == 'view')
                    && x.module_code == module_code)) {
                    return true;
                }
                else {
                    return false;
                }

            }
            else {
                return false;
            }


            //this.global.$router.navigate.
        }

        CheckModuleEditPermissionForRepair(txt) {
            
            if (this.global.utilities.storage.get('RLRole') != '') {
                var RolePermission = JSON.parse(this.global.utilities.storage.get('RLRole'));
                let module_code = this.global.utilities.storage.get('module_code');
                if( RolePermission.find(x => ((x.functionname.toLowerCase().replaceAll(' ','') == txt) ||
                (x.functionname.toLowerCase() == txt) )
                     && x.module_code == module_code)) {
                    return true;
                }
                else {
                    return false;
                }

            }
            else {
                return false;
            }


            //this.global.$router.navigate.
        }

    }(this);

    public ServiceManager = new class {

        constructor(public global: GlobalService) {

        }
        public request = new class {

            constructor(public global: GlobalService) {

            }

            get(endpoint: string): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-get-request',
                        'Content-Type': 'application/json',
                        'm': this.global.module_code || ''
                    })
                };
                let api = `${this.global._environment.node_api}/${endpoint}`;

                return this.global.$http.get(api, httpOptions)
            }


            get_background(endpoint: string): Observable<any> {
                const authUser = `{}`
                let access_token = ''
                try {
                    access_token = JSON.parse(atob(localStorage.getItem(window.location.hostname))).access_token;

                }
                catch
                {

                }

                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-get-request',
                        'Content-Type': 'application/json',
                        'ud': btoa(btoa(authUser)),
                        'scope': 's',
                        'Authorization': 'Bearer ' + access_token
                    })
                };
                let api = `${this.global._environment.node_api}/${endpoint}`;

                return this.global.httpClient.get(api, httpOptions)
            }


            
            get_background_SaveEventLog(endpoint: string,data:any): Observable<any> {
                
                const authUser = `{}`
                let access_token = ''
                try {
                    access_token = JSON.parse(atob(localStorage.getItem(window.location.hostname))).access_token;

                }
                catch
                {

                }

                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-get-request',
                        'Content-Type': 'application/json',
                        'ud': btoa(btoa(authUser)),
                        'scope': 's',
                        'Authorization': 'Bearer ' + access_token
                    })
                };
                let api = `${this.global._environment.node_api}/${endpoint}`;

                return this.global.httpClient.post(api,data, httpOptions)
            }


            getwithpayload(endpoint: string, payload: any): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-get-request',
                        'Content-Type': 'application/json',
                        'm': this.global.module_code || '',
                    })
                };
                let api = `${this.global._environment.node_api}/${endpoint}`;
                return this.global.$http.get(api, httpOptions)
            }
            post(endpoint: string, payload: any): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-post-request',
                        'Content-Type': 'application/json'
                    })
                };
                let api = `${this.global._environment.node_api}/${endpoint}`;
                return this.global.$http.post(api, payload, httpOptions);
            }

            post_background(endpoint: string, payload: any): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-post-request',
                        'Content-Type': 'application/json'
                    })
                };

                let api = `${this.global._environment.node_api}/${endpoint}`;
                return this.global.httpClient.post(api, payload, httpOptions);
            }
            post_webapi(endpoint: string, payload: any): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-post-request',
                        'Content-Type': 'application/json'
                    })
                };
                let api = `${this.global._environment.web_api}/${endpoint}`;
                return this.global.$http.post(api, payload, httpOptions);
            }




            postbyEndPoint(endpoint: string, payload: any): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-post-request',
                        'Content-Type': 'application/json',
                        'm': 'Question'
                    })
                };

                return this.global.$http.post(endpoint, JSON.stringify(payload), httpOptions);
            }


            postwithpromise(endpoint: string, payload: any): Promise<any> {
                this.global.isProcessing = true;
                let promise = new Promise((resolve, reject) => {
                    var httpOptions = {
                        headers: new HttpHeaders({
                            'scope-header': 'x-post-request',
                            'Content-Type': 'application/json',
                            'm': this.global.module_code || ''
                        })
                    };
                    let api = `${this.global._environment.base}/${endpoint}`;
                    return this.global.$http.post(api, JSON.stringify(payload), httpOptions).subscribe((res: any) => {
                        this.global.isProcessing = false;
                        resolve(res);
                    }, (resError: any) => {
                        this.global.isProcessing = false;
                        resolve(resError);
                    });
                })
                return promise;
            }
            put(endpoint: string, payload: any): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-put-request',
                        'Content-Type': 'application/json',
                        'm': this.global.module_code || ''
                    })
                };
                let api = `${this.global._environment.base}/${endpoint}`;
                return this.global.$http.put(api, JSON.stringify(payload), httpOptions)
            }

            delete(endpoint: string): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'scope-header': 'x-delete-request',
                        'Content-Type': 'application/json',
                        'm': this.global.module_code || ''
                    })
                };
                let api = `${this.global._environment.web_api}/${endpoint}`;
                return this.global.$http.delete(api, httpOptions)
            }

            upload(endpoint: string, fileToUpload: File): Observable<any> {
                var httpOptions = {
                    headers: new HttpHeaders({
                        'm': this.global.module_code || ''
                    })
                };
                const formData: FormData = new FormData();
                formData.append('fileKey', fileToUpload, fileToUpload.name);
                let api = `${this.global._environment.web_api}/${endpoint}`;
                return this.global.httpClient.post(api, formData, httpOptions)
            }
            UploadFile(formdata: any): Observable<any> {
                let api = `${this.global._environment.node_api}/uploads/profileimage`;
                return this.global.$http.post(api, formdata);
            }

        }(this.global);

        requestGet(endpoint: string) {
            let httpOptions = {
                headers: new HttpHeaders({
                    'scope-header': 'x-auth-request',
                    'Content-Type': 'application/json',
                    'm': this.global.module_code
                })
            };
            let promise = new Promise((resolve, reject) => {
                let api = `${this.global._environment.base}/${endpoint}`;
                this.global.$http.get(api, httpOptions)
                    .toPromise()
                    .then(
                        res => {
                            resolve(res);
                        },
                        msg => {
                            reject(msg);
                        }
                    );
            });
            return promise;
        }

        requestPost(endpoint: string, payload: any) {
            let httpOptions = {
                headers: new HttpHeaders({
                    'scope-header': 'x-auth-request',
                    'Content-Type': 'application/json',
                    'm': this.global.module_code
                })
            };
            let promise = new Promise((resolve, reject) => {
                let api = `${this.global._environment.base}/${endpoint}`;
                this.global.$http.post(api, JSON.stringify(payload), httpOptions)
                    .toPromise()
                    .then(
                        res => {
                            resolve(res);
                        },
                        msg => {
                            reject(msg);
                        }
                    );
            })
            return promise;
        }

        requestPut(endpoint: string, payload: any) {
            let httpOptions = {
                headers: new HttpHeaders({
                    'scope-header': 'x-auth-request',
                    'Content-Type': 'application/json',
                    'm': this.global.module_code
                })
            };
            let promise = new Promise((resolve, reject) => {
                let api = `${this.global._environment.web_api}/${endpoint}`;
                this.global.$http.put(api, JSON.stringify(payload), httpOptions)
                    .toPromise()
                    .then(
                        res => {
                            resolve(res);
                        },
                        msg => {
                            reject(msg);
                        }
                    );
            })
            return promise;
        }

        requestDelete(endpoint: string) {
            let httpOptions = {
                headers: new HttpHeaders({
                    'scope-header': 'x-auth-request',
                    'Content-Type': 'application/json',
                    'm': this.global.module_code
                })
            };
            let promise = new Promise((resolve, reject) => {
                let api = `${this.global._environment.base}/${endpoint}`;
                this.global.$http.delete(api, httpOptions)
                    .toPromise()
                    .then(
                        res => {
                            resolve(res);
                        },
                        msg => {
                            reject(msg);
                        }
                    );
            })
            return promise;
        }

        requestUoload(endpoint: string, fileToUpload: File) {
            return this.request.upload(endpoint, fileToUpload);
        }

    }(this);
}