   <div class="loading-container">
        <div class="loader"></div>
    </div>
    <app-navigation></app-navigation>
    <div class="main-container container-fluid">
        <div class="page-container">
            <app-sidebar></app-sidebar>
            <div class="page-content">
                <app-command  (toggleScanBox)="toggleScanBox()"  [IsScanBoxVisible]="IsScanBoxVisible" [queue] ="queue" [IsOpenScan]="IsOpenScan" [Breadcrumb] ="Breadcrumb"  [UniqValue] ="UniqValue"   [placeholderText] ="placeholderText"></app-command>                
               <div class="page-body">
                    <div class="blocker blocker-inactive bg">
                        <div class="loader" id="loader-6">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="rl-scanner rl-scanner-inactive">
                        <div class="rl-scanner-loader">
                            <div class="pull-left"><img width="100" src="../../assets/img/ring-spinner.gif" alt="loader" /></div>
                            <div class="pull-left" style="padding-top:28px;">
                                <span style="font-size: 16px;">Scan package to initiate</span><br>
                                <span style="font-size: 12px;">Esc to continue manually</span>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>