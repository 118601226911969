import { CustomerPopupComponent } from './customer-popup.component';
import { FormsModule } from '@angular/forms';
import { NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [CustomerPopupComponent],
    imports: [
        CommonModule,
        FormsModule
    ],
    exports:[CustomerPopupComponent],
    schemas:[NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomerPopupModule { }