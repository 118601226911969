import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { SharedModule } from './shared/shared.module';
import { BackOfficeModule } from './back-office/back-office.module';

import { ControlsModule } from './controls/controls.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Util } from './app.util';
import { WINDOW_PROVIDERS } from './app.window';
import { CKEditorModule } from 'ngx-ckeditor';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ENVIRONMENTER } from 'src/environments/environmenter.token';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsService } from 'src/environments/ApplicationInsightsService';
import  CustomUrlSerializer  from './CustomUrlSerializer';
import { UrlSerializer } from '@angular/router';
import { CallShipmentFunctionSrc } from './shared/call-shipmentfunction';
import {BackOfficeSettingService} from './shared/services/BackOfficeSetting.Service';
import { GlobalErrorHandler } from './shared/GlobalErrorHandler';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { addstringService } from './pages/portal/service/addstring.service';
@NgModule({
  declarations: [
    AppComponent,
    
  ],
  imports: [
    
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    AuthenticationModule,
    SharedModule,
    BackOfficeModule,

    HttpClientModule, 
    FormsModule,
    ControlsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      "closeButton": true,
      "newestOnTop": true,
      "progressBar": true,
      "positionClass": "toast-top-right",
      "preventDuplicates": false,
      "timeOut": 10000,
      "extendedTimeOut": 1000
    }),
    CKEditorModule,
    Ng2SearchPipeModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    Util,
    WINDOW_PROVIDERS,
    ApplicationInsightsService,
    CallShipmentFunctionSrc,
    { provide: ENVIRONMENTER, useValue: environment },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
   //  {provide: ErrorHandler, useClass: GlobalErrorHandler},
    BackOfficeSettingService,
    addstringService
  ],
  bootstrap: [AppComponent],
  schemas:[NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
