import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormBuilder, Validators, NgForm } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { Tabs } from '../controls/tabs/tabs.component.js';
import { Tab } from '../controls/tabs/tab.component.js';
import { hasPipe, indexOfPipe, str2objPipe, containsPipe, actioncodePipe, OrderBy, startFromPipe, hasnotPipe, KeysPipe, limitToPipe } from '../pipes';
import { CommonService } from './common.service';
import { GlobalVariableService } from './globalvariable.service';
import { EditComponent } from './edit.component';
import { AddressEditor } from './address.component';
import { AgGridModule } from 'ag-grid-angular';
import { ClickableLinkComponent } from "../controls/clickable/link.component";
import { ToolTipComponent } from "./tooltip.component";
import { BsModalModule } from 'ng2-bs3-modal';
import { httpInterceptorProviders } from '../interceptors';
import { AddressService } from './address.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ddl } from '../controls/ddl/ddl.component';
import { MultiLevelDropdown } from '../controls/multi-level-ddl/multi-level-ddl.component';
import { ClickableImageLinkComponent } from '../controls/clickable/imageiconlink.component';
import { ClickableComponent } from '../controls/clickable/clickable.component';
import { ToolTipLinkComponent } from '../controls/clickable/tooltiplink.component';
import { AllStates } from './states.components';
import { AllCountries } from './countries.component';
import { StatesService } from './states.service';
import { CountriesService } from './countries.service';
import { CustomerRegions } from '../back-office/region/customerregion.component';
import { checkBoxMandatoryComponent } from '../back-office/returnreason/chkboxmandatory';
import { effecton } from '../back-office/returnreason/effecton.controls';
import { effecttype } from '../back-office/returnreason/effecttype.controls';
import { valuetype } from '../back-office/returnreason/valuetype.controls';
import { checkBoxInputComponent } from '../back-office/returnreason/userinput.component';
import { checkBoxComponent } from '../back-office/returnreason/chkbox.component';
import { multiplecontrols } from '../back-office/returnreason/multiple.controls';
import { EditArtifactComponent } from '../controls/clickable/editartifact.component';
import { EditIamgeComponent } from '../controls/clickable/imagelink.component';
import { ItemSelector } from '../controls/itemselector/itemselector.component';
import { NumericEditorComponent } from '../controls/numericeditor/numericeditor';
import { QuantityEditorComponent } from './quantityeditor.component';
import { ContextMenuService } from '../controls/context-menu';
import { PartnerRuleValueComponent } from './partner-rulevalue.component';
import { ActiveComponent } from './active-repair.component';

import { ImageColumnComponent } from './image-column.component';
import { QuantityComponent } from './quantity.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GridCreditButtonComponent } from './grid-credit-button.component';
import { VerifyTabComponent } from './verify-tab/verify-tab.component';
import { RmaDetailsComponent } from './rma-details/rma-details.component';
import { InspectTabComponent } from './inspect-tab/inspect-tab.component';
import { ApprovelTabComponent } from './approvel-tab/approvel-tab.component';
import { PutawayTabComponent } from './putaway-tab/putaway-tab.component';
import { PutAwayLocation } from './putaway-tab/putaway-loaction.component';
import { AddressPopupModule } from 'src/app/pages/portal/address-popup/address-popup.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppConfirmationComponent } from '../controls/app-confirmation/app-confirmation.component';
import { ComponentComunicationSer } from './call-function-service';
import { EmailMaskPipe } from '../pipes/email-mask.pipe';
import { MobileMaskPipe } from '../pipes/mobile-mask.pipe';
import { AddressMaskPipe } from '../pipes/address-mask.pipe';
import { ShippingInstructionComponent } from './shipping-instruction/shipping-instruction.component';
import { NumberDirective } from '../common-directive/numbers-only.directive';
import { TwoDigitDecimaNumberDirective } from '../common-directive/twodigitdecimalnumber.directive';
import { PaymentConfigService } from '../back-office/payment-configuration/payment-config.service';

import { NumberEditorComponent } from '../controls/number-editor/number-editor.component';
import { IosFixesDirective } from '../common-directive/IosFixesDirective';
import { ProformaInvoice1Component } from './proforma-invoice/proforma-invoice.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { WfActionControlsComponent } from './wf-action-controls/wf-action-controls.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { WordClickHandleDirective } from '../common-directive/word-click-handle.directive';
import { StyleWordPipe } from '../pipes/style-word.pipe';
import { WfSplitComponent } from './wf-split/wf-split.component';
import { CustomerPopupModule } from '../back-office/returns/order/customer-popup/customer-popup.module';
@NgModule({
  declarations: [QuantityComponent, ImageColumnComponent, PartnerRuleValueComponent, QuantityEditorComponent

    , effecton, effecttype, valuetype, checkBoxInputComponent
    , EditArtifactComponent, EditIamgeComponent
    , checkBoxComponent, multiplecontrols
    , checkBoxMandatoryComponent 
    , CustomerRegions
    , AllStates, AllCountries
    , ToolTipLinkComponent, ClickableImageLinkComponent, ClickableComponent, ddl, MultiLevelDropdown
    , actioncodePipe, OrderBy, startFromPipe, hasnotPipe, KeysPipe
    , AddressMaskPipe,MobileMaskPipe,EmailMaskPipe,hasPipe, indexOfPipe, str2objPipe, containsPipe, ToolTipComponent
    , Tabs, Tab, EditComponent, ActiveComponent
    , AddressEditor, ClickableLinkComponent, ItemSelector
    , NumericEditorComponent
    , limitToPipe, GridCreditButtonComponent, VerifyTabComponent,
     RmaDetailsComponent, InspectTabComponent, ApprovelTabComponent, 
     PutawayTabComponent,
     PutAwayLocation,
     AppConfirmationComponent,
     ShippingInstructionComponent,
     NumberDirective,
     TwoDigitDecimaNumberDirective,
     NumberEditorComponent,
     IosFixesDirective,
     ProformaInvoice1Component,
     WfActionControlsComponent,
     ItemDetailsComponent,
     WordClickHandleDirective,
     StyleWordPipe,
     WfSplitComponent

  ],
  imports: [
    BsModalModule,
    ModalModule.forRoot(),
    NgxBarcode6Module,
    CommonModule,
    FormsModule,
    HttpClientModule,
    AddressPopupModule,
    CustomerPopupModule,
    SharedRoutingModule, NgMultiSelectDropDownModule.forRoot(), AgGridModule.withComponents(
      [
        ImageColumnComponent, EditComponent, PartnerRuleValueComponent
        , ClickableImageLinkComponent, ToolTipLinkComponent, ClickableComponent
        , ClickableLinkComponent, EditIamgeComponent, ActiveComponent
      ])
  ],
  providers: [NgForm, FormBuilder, httpInterceptorProviders, CommonService
    , GlobalVariableService, AddressService, CountriesService
    , StatesService, ContextMenuService,ComponentComunicationSer,
    PaymentConfigService
  ],
  exports: [QuantityComponent, ImageColumnComponent, PartnerRuleValueComponent
    ,ShippingInstructionComponent,NumberDirective,TwoDigitDecimaNumberDirective
    , QuantityEditorComponent, EditIamgeComponent, EditArtifactComponent
    , effecton, effecttype, valuetype, checkBoxInputComponent
    , checkBoxComponent, multiplecontrols
    , checkBoxMandatoryComponent 
    , CustomerRegions    
    , AllStates, AllCountries
    , ToolTipLinkComponent, ClickableImageLinkComponent
    , ClickableComponent, ddl, MultiLevelDropdown
    , actioncodePipe, OrderBy, startFromPipe, hasnotPipe
    , AddressMaskPipe,MobileMaskPipe,KeysPipe,EmailMaskPipe, hasPipe, indexOfPipe, str2objPipe, containsPipe, BsModalModule
    , Tabs, ToolTipComponent, Tab, EditComponent, AddressEditor
    , ClickableLinkComponent, ItemSelector
    , NumericEditorComponent
    , ActiveComponent, limitToPipe, VerifyTabComponent, RmaDetailsComponent
    ,InspectTabComponent, ApprovelTabComponent, PutawayTabComponent, PutAwayLocation, AppConfirmationComponent,NumberEditorComponent
    ,ProformaInvoice1Component,WfActionControlsComponent,
    WordClickHandleDirective,
    StyleWordPipe
  ],
  

  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
