import { NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from '../navigation/navigation.component';
import { CommandComponent } from '../command/command.component';

import { SidebarComponent } from '../sidebar/sidebar.component';
import { FormsModule } from '@angular/forms';
import { BsModalModule, BsModalService } from 'ng2-bs3-modal';
import { SharedModule } from '../../shared/shared.module';
import { SidebarService } from '../sidebar/sidebar.service';
import { AuthService } from '../../authentication/auth.service';
import { str2objPipe } from '../../pipes';
import { CommonService } from '../../shared/common.service';
import { GlobalVariableService } from '../../shared/globalvariable.service';
import { BackOfficeRoutingModule } from '../back-office-routing.module';
import { BackOfficeComponent } from '../back-office.component';
import { ComponentComunicationSer } from 'src/app/shared/call-function-service';

@NgModule({
  declarations: [NavigationComponent, SidebarComponent
    , CommandComponent
    , BackOfficeComponent],
  imports: [
    CommonModule,FormsModule, BsModalModule , SharedModule
    , BackOfficeRoutingModule
  ],
  providers:[ SidebarService, 
      str2objPipe, CommonService, 
      GlobalVariableService,
      BsModalService,
      
      AuthService
  ],
  exports:[NavigationComponent, SidebarComponent
    , CommandComponent
    , BackOfficeComponent],
  schemas:[NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule { } 