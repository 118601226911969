import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ConfigurationConstants } from '../../shared/constants';

@Injectable()
export class RtvService {
    private baseUrl: string;
    private action_Url: string;

    constructor(private http: HttpClient) {
        this.baseUrl = ConfigurationConstants.BASEURL;
        this.action_Url = this.baseUrl + "rtvOrder";
    }

    getOrderDetails(internal_code): Observable<any> {
        return this.http.get(`${this.action_Url}/getOrderDetails/${internal_code}`);
    }

    getProformaInvoice(rtv_number: string): Observable<any> {
        return this.http.get(`${this.action_Url}/getProformaInvoice/${rtv_number}`);
    }

    getProductSearch(key: string, internal_code: string, items: any): Observable<any> {
        return this.http.post(`${this.action_Url}/getItems/${key}/${internal_code}`, JSON.stringify(items));
    }

    SaveOrder(order: any): Observable<any> {
        return this.http.post(`${this.action_Url}`, JSON.stringify(order));
    }

    updateStatus(internal_code: string, body): Observable<any> {
        return this.http.put(`${this.action_Url}/updateStatus/${internal_code}`, JSON.stringify(body));
        //return this.http.post(`${this.action_Url}/updateStatus/${internal_code}`, JSON.stringify(body));
    }

}

