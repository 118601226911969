import { Injectable, Inject } from '@angular/core';
import { Observable, of, from, EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationConstants } from '../shared/constants';
import { WINDOW } from '../app.window';
import { CustomURLEncoder } from '../shared/customurlencode';
import { Router } from '@angular/router';
import { Util } from '../app.util';
import CryptoJS from 'crypto-js';
import { GlobalService } from '../shared/services/global-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  previousaction: string = '';
  get loginUserName(): string {
    return JSON.parse(this.getAuthorizationUser()).username;
  }
  get loginUserID(): number {
    return JSON.parse(this.getAuthorizationUser()).UserID;
  }
  isLoggedIn = false;
  // store the URL so we can redirect after logging in
  redirectUrl: string;
  private actionUrl: string;
  baseURL: string = "";



  constructor(@Inject(WINDOW) private window: Window, private _globalService: GlobalService, private http: HttpClient, private router: Router, private _util: Util) {
    this.baseURL = ConfigurationConstants.BASEURL;
  }


  CheckCurrentSession() {
    if (this.window.location.href.toLowerCase().indexOf("back-office") > -1) {
      if (this.window.location.href.toLowerCase().indexOf("returns") == -1) {
        if (localStorage.getItem('dateFilter')) {
          localStorage.removeItem('dateFilter');
        }
        if (localStorage.getItem('filterValue')) {
          localStorage.removeItem('filterValue');
        }
        if (localStorage.getItem('queueName')) {
          localStorage.removeItem('queueName');
        }
      }
    }


    let CurrentMs = new Date().getTime();
    let host = localStorage.getItem(this.getHost());
    if (host) {
      let expirationMS = new Date(JSON.parse(atob(host)).accessTokenExpiresAt).getTime();
      let diff = expirationMS - CurrentMs;
      if (diff < (1000 * 60 * 2)) {
        this.isLoggedIn = false;
        localStorage.removeItem(this.getHost());
        sessionStorage.clear();
        localStorage.clear();
        //this.router.navigate(['/index']);
        //   this.router.navigate(['/auth/login']);
      }
    }
    else {
      if (!(this.window.location.href.toLowerCase().indexOf("login") > -1) && (this.window.location.href.toLowerCase().indexOf("back-office") > -1)) {
        //this.router.navigate(['/auth/login']);
        //this.router.navigate(['/index']);
      }
    }
  }
  login(username: string, password: string): Observable<any> {

    var base64_password = btoa(password) //String with '+'
    let body = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', base64_password);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': "Basic " + btoa("democlient:democlientsecret")
      })
    };
    this.actionUrl = this.baseURL + "oauth/token";
    return this.http.post(this.actionUrl, body, httpOptions)
      .pipe(
        map(user => {
          if (user) {
            setTimeout(() => { this.checkValidSession() }, 1000 * 60 * 59 * 4);
            var loggedInUser = JSON.parse(JSON.stringify(user)).user;
            var currentdate = this.GetDate(new Date());
            var logindate = this.GetDate(loggedInUser.InvalidLoginDate);

            if (!user['user']["IsActive"]) {
              this._util.error('Your account is not active please contact system admin.', "Error");
              return;
            }
            if (loggedInUser.InvalidLogin >= 5 && currentdate === logindate) {
              this._util.error('Your account has been locked, please contact system admin.', "Error");
              return;
            }
            else if (loggedInUser.InvalidLogin > 0) {
              var data = { UnLocked: true };
              this.UpdateInvalidLogin(username, data)
                .subscribe(
                  result => {
                    console.log(result)
                  },
                  error => {
                    console.log(error)
                  });
            }
            else {
              this.isLoggedIn = true;
              if (loggedInUser.InvalidLogin > 0) {
              }
            }
            //=============================
            if (localStorage.getItem(this.getHost()))
              localStorage.removeItem(this.getHost());

            var authuid = '';
            if (sessionStorage.getItem('authUserId'))
              authuid = sessionStorage.getItem('authUserId');
            sessionStorage.clear();
            sessionStorage.setItem('authUserId', authuid);
            localStorage.setItem(this.getHost(), btoa(JSON.stringify(user)));
            this.isLoggedIn = true;
            sessionStorage.setItem('loggedInUser', loggedInUser.UserID);
            //=============================
            return loggedInUser;
          }
        }),
        catchError((err) => {
          this.isLoggedIn = false;

          var data = { UnLocked: false };
          this.UpdateInvalidLogin(username, data).
            subscribe(
              result => {

                if (result.message == 'locked') {
                  this._util.error('Your account has been locked, please contact system admin.', "Error");
                }
                else {
                  this._util.error('Your credentials are invalid.', "Error");
                }
              },
              error => {

                this._util.error('Your credentials are invalid.', "Error");
              });

          return of(this.isLoggedIn);
        })
      );
  }
  logincpportal(username: string, password: string): Observable<any> {

    var base64_password = btoa(password) //String with '+'
    let body = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', base64_password);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': "Basic " + btoa("democlient:democlientsecret")
      })
    };
    this.actionUrl = this.baseURL + "oauth/token";
    return this.http.post(this.actionUrl, body, httpOptions)
      .pipe(
        map(user => {
          if (user) {
            setTimeout(() => { this.checkValidSessionCPPortal() }, 1000 * 60 * 59 * 4);
            var loggedInUser = JSON.parse(JSON.stringify(user)).user;
            var currentdate = this.GetDate(new Date());
            var logindate = this.GetDate(loggedInUser.InvalidLoginDate);

            if (!user['user']["IsActive"]) {
              this._util.error('Your account is not active please contact system admin.', "Error");
              return;
            }
            if (loggedInUser.InvalidLogin >= 5 && currentdate === logindate) {
              this._util.error('Your account has been locked, please contact system admin.', "Error");
              return;
            }
            else if (loggedInUser.InvalidLogin > 0) {
              var data = { UnLocked: true };
              this.UpdateInvalidLogin(username, data)
                .subscribe(
                  result => {
                    console.log(result)
                  },
                  error => {
                    console.log(error)
                  });
            }
            else {
              this.isLoggedIn = true;
              if (loggedInUser.InvalidLogin > 0) {
              }
            }
            //=============================
            if (localStorage.getItem(this.getHost()+'_cp'))
              localStorage.removeItem(this.getHost()+'_cp');

            var authuid = '';
            if (sessionStorage.getItem('authUserId'+'_cp'))
              authuid = sessionStorage.getItem('authUserId');
            sessionStorage.clear();
            sessionStorage.setItem('authUserId'+'_cp', authuid);
            localStorage.setItem(this.getHost()+'_cp', btoa(JSON.stringify(user)));
            this.isLoggedIn = true;
            sessionStorage.setItem('loggedInUser'+'_cp', loggedInUser.UserID);
            //=============================
            return loggedInUser;
          }
        }),
        catchError((err) => {
          this.isLoggedIn = false;

          var data = { UnLocked: false };
          this.UpdateInvalidLogin(username, data).
            subscribe(
              result => {

                if (result.message == 'locked') {
                  this._util.error('Your account has been locked, please contact system admin.', "Error");
                }
                else {
                  this._util.error('Your credentials are invalid.', "Error");
                }
              },
              error => {

                this._util.error('Your credentials are invalid.', "Error");
              });

          return of(this.isLoggedIn);
        })
      );
  }
  private UpdateExpiryfortoken(portal:any): Observable<any> {

    let nullreturn = new Observable();
     var access_token= '';
     var localhostval = portal =='CP' ? localStorage.getItem(this.getHost() + '_cp') : localStorage.getItem(this.getHost() );
    if(localhostval != null && localhostval != undefined){
      access_token = JSON.parse(atob(localhostval)).access_token ;
   
      this.actionUrl = this.baseURL + "authenticate/logoutForCP";
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "access_token" :access_token
        }),
      };
      return this.http.get(this.actionUrl,httpOptions);
     }
     return nullreturn;
    }
  getDifferenceInDays(date1, date2) {

    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }
  GetDate(mydate): string {
    var newdate = new Date(mydate);
    var dd = newdate.getDate();
    var mm = newdate.getMonth() + 1; //January is 0
    var yyyy = newdate.getFullYear();
    let val: string = "";

    if (dd < 10) {
      val = '0' + dd;
    }
    else {
      val = dd.toString();
    }

    if (mm < 10) {
      val = val + '/' + '0' + mm;
    }
    else {
      val = val + '/' + mm;
    }

    return val + '/' + yyyy;
  }

  updateLogin(username, IsUnlocked) {
    var data = { UnLocked: IsUnlocked };
    this.UpdateInvalidLogin(username, data).
      subscribe(
        result => {

        },
        error => {

        });
  }
  isSessionValid() {
    let CurrentMs = new Date().getTime();
    let expirationMS = new Date(JSON.parse(atob(localStorage.getItem(this.getHost()))).accessTokenExpiresAt).getTime();
    let diff = expirationMS - CurrentMs;
    return diff >= 0;
  }
  private checkValidSession() {

    let CurrentMs = new Date().getTime();
    let expirationMS = new Date(JSON.parse(atob(localStorage.getItem(this.getHost()))).accessTokenExpiresAt).getTime();
    let diff = expirationMS - CurrentMs;
    if (diff < (1000 * 60 * 2))
      this.silentlyLogin();

  }
  private silentlyLogin() {
    let userName = JSON.parse(atob(localStorage.getItem(this.getHost()))).user.username;
    let password = JSON.parse(atob(localStorage.getItem(this.getHost()))).user.password;
    this.login(userName, password).subscribe(
      r => {
        if (r)
          return true;
        else
          return false;
      }
    );
  }
  private checkValidSessionCPPortal() {

    let CurrentMs = new Date().getTime();
    let expirationMS = new Date(JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).accessTokenExpiresAt).getTime();
    let diff = expirationMS - CurrentMs;
    if (diff < (1000 * 60 * 2))
      this.silentlyLoginCPPortal();

  }
  private silentlyLoginCPPortal() {
    let userName = JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).user.username;
    let password = JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).user.password;
    this.login(userName, password).subscribe(
      r => {
        if (r)
          return true;
        else
          return false;
      }
    );
  }
  refreshToken(): any {
    let body = 'grant_type=refresh_token&refresh_token=' + this.getRefreshToken();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': "Basic " + btoa("democlient:democlientsecret")
      })
    };
    this.actionUrl = this.baseURL + "oauth/token";
    this.http.post(this.actionUrl, body, httpOptions)
      .pipe(
        map(user => {
          if (user) {
            localStorage.setItem(this.getHost(), btoa(JSON.stringify(user)));
            this.isLoggedIn = true;
            return this.isLoggedIn;
          }
        }),
        catchError((err) => {
          this.isLoggedIn = false;
          return of(this.isLoggedIn);
        })
      );
  }
  logout(): void {    
    this.UpdateExpiryfortoken('BO').subscribe((r) => {
    });
    this.isLoggedIn = false;
    localStorage.removeItem(this.getHost());
    sessionStorage.removeItem('authUserId');
    localStorage.removeItem('partnerinfo');
    //localStorage.clear();
    //sessionStorage.clear();
    sessionStorage.removeItem("isPageSetting")
  }
  logoutCPPortal(): void {  
    this.UpdateExpiryfortoken('CP').subscribe((r) => {
    });  
    this.isLoggedIn = false;
    localStorage.removeItem(this.getHost()+'_cp');
    sessionStorage.removeItem('authUserId'+'_cp');
    localStorage.removeItem('partnerinfo'+'_cp');    
    sessionStorage.removeItem("isPageSetting"+'_cp')
    localStorage.removeItem("isCPHaveLogin");
    sessionStorage.removeItem(this.getHost()+'_cp');
    sessionStorage.removeItem('cpLoginUseremail');


  }

  getAuthorizationToken(): string {
    if (localStorage.getItem(this.getHost()))
      return JSON.parse(atob(localStorage.getItem(this.getHost()))).access_token;
    else
      return null;
  }
  getAuthorizationUser(): string {
    if (localStorage.getItem(this.getHost()))
      return JSON.stringify(JSON.parse(atob(localStorage.getItem(this.getHost()))).user);
    else
      return null;
  }
  getAuthorizationUserCPPortal(): string {
    if (localStorage.getItem(this.getHost()+'_cp'))
      return JSON.stringify(JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).user);
    else
      return null;
  }
  getUserName(): string {
    if (localStorage.getItem(this.getHost()))
      return JSON.parse(atob(localStorage.getItem(this.getHost()))).user.username;
    else
      return null;
  }
  getUserID(): string {
    if (localStorage.getItem(this.getHost()))
      return JSON.parse(atob(localStorage.getItem(this.getHost()))).user.UserID;
    else
      return null;
  }
  getUserIDCPPortal(): string {
    if (localStorage.getItem(this.getHost()+'_cp'))
      return JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).user.UserID;
    else
      return null;
  }
  getScope(): string {
    var host = this.window.location.hostname;
    let scope: string = host.split('.')[0];
    return scope;
  }
  getHost(): string {
    return this.window.location.hostname;
  }
  getUserScope(): string {
    return JSON.parse(atob(localStorage.getItem(this.getHost()))).user.scope;
  }
  getUserScopeCPPortal(): string {
    return JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).user.scope;
  }
  public GetPasswordForUserName(user: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'

      })
    };

    this.actionUrl = this.baseURL + 'authenticate/forgotpassword';
    return this.http.post(this.actionUrl, JSON.stringify(user), httpOptions);
  }
  private getRefreshToken(): any {
    if (localStorage.getItem(this.getHost()))
      return JSON.parse(atob(localStorage.getItem(this.getHost()))).refresh_token;
    else
      return null;
  }

  lookup(email: string): Observable<any> {
    this.actionUrl = this.baseURL + 'lookup/user/' + email;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(this.actionUrl, httpOptions);
  }

  userChangePassword(user: any): Observable<any> {
    return this.http.post(this.baseURL + 'users/changepassword', JSON.stringify(user));
  }

  ChangePassword(user: any): Observable<any> {
    return this.http.post(this.baseURL + 'users/resetpassword', JSON.stringify(user));
  }
  ResetPassword(user: any): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };


    return this.http.post(`${this.baseURL}lookup/resetpassword`, JSON.stringify(user), { headers });
  }

  checkLinkValidation(internalCode: string): Observable<any> {
    return this.http.get(`${this.baseURL}lookup/checkLinkValidation/${internalCode}`);
  }

  UpdateInvalidLogin(username: string, Alldata: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(`${this.baseURL}lookup/UpdateInvalidLogin/${username}`, JSON.stringify(Alldata), httpOptions);
  }

  public UserAuthenticationLogin(username: string, password: string, loginfrom: string): Observable<any> {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
    return this.http.get(`${this.baseURL}authenticate/authenticateUserLogin/${username}/${password}/${loginfrom}`, { headers });
  }

  public GetAccountSetting(userid: any): Observable<any> {
    return this.http.get(`${this.baseURL}authenticate/getAccountSetting/${userid}`);
  }

  public SaveAuthenticateUserLog(UserId: number, IPAddress: string, LoginFrom: string, LogType: string, purpose_type: number, client_name: string): Observable<any> {
    var data = { UserId: UserId, IPAddress: IPAddress, LoginFrom: LoginFrom, LogType: LogType, PurposeType: purpose_type, ClientName: client_name }
    console.log(JSON.stringify(data));
    return this.http.post(`${this.baseURL}authenticate/saveAuthenticateUserLog`, data);
  }
  public SaveUserEventsLog(aUserId: number, UserId: number, ModuleName: string, url: string, action: string, IPAddress: string) {

    this.previousaction = sessionStorage.getItem("action");

    if (aUserId) {
      sessionStorage.setItem("action", action);
      if (this.previousaction != action) {
        setTimeout(() => {
          var data = { authUserId: aUserId, UserId: UserId, ModuleName: ModuleName, url: url, action: action, IPAddress: IPAddress }
          this._globalService.ServiceManager.request.get_background_SaveEventLog('authenticate/saveUserEventsLog', JSON.stringify(data)).subscribe((res: any) => {

          });
        }, 900);
      }
    }
  }


  public CheckForTwoStepLogin(): Observable<any> {
    return this.http.get(`${this.baseURL}authenticate/CheckForTwoStepLogin`);
  }
  public getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }
  setEncryption(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  async getEncodedQueryString(returnnumber: any): Promise<any> {
    return await this.http.get(`${this.baseURL}authenticate/encryptQueryString/${returnnumber}`).toPromise();
  }

  SAMLLogin() {
   
    return new Promise((resolve, reject) => {
     window.location.href = ConfigurationConstants.BASEAPIURLForCarrier + "api/Account/ExternalLogin?provider=saml2&returnUrl=" + ConfigurationConstants.clientbase + "/samllogin";

    });
  }

  ssoLog(payload: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.actionUrl = this.baseURL + 'authenticate/azouressologin';
    return this.http.post(this.actionUrl, JSON.stringify(payload),httpOptions);
  }

  ssoLogError(payload: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.actionUrl = this.baseURL + 'authenticate/sso_log';
    return this.http.post(this.actionUrl, JSON.stringify(payload),httpOptions);
  }

  ssoLogin(user: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.actionUrl = this.baseURL + 'authenticate/azouressologin';
    return this.http.post(this.actionUrl, JSON.stringify(user),httpOptions);
  }

  getSSOUserByEmail(email: string): Observable<any> {
    this.actionUrl = this.baseURL + 'lookup/getSSOUserByEmail/' + email;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(this.actionUrl, httpOptions);
  }

  loginSSO(username: string, password: string): Observable<any> {

    //var base64_password = btoa(password) //String with '+'
    let body = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': "Basic " + btoa("democlient:democlientsecret")
      })
    };
    this.actionUrl = this.baseURL + "oauth/token";
    return this.http.post(this.actionUrl, body, httpOptions)
      .pipe(
        map(user => {
          if (user) {
            setTimeout(() => { this.checkValidSession() }, 1000 * 60 * 59 * 4);
            var loggedInUser = JSON.parse(JSON.stringify(user)).user;
            var currentdate = this.GetDate(new Date());
            var logindate = this.GetDate(loggedInUser.InvalidLoginDate);

            if (!user['user']["IsActive"]) {
              this._util.error('Your account is not active please contact system admin.', "Error");
              return;
            }
            if (loggedInUser.InvalidLogin >= 5 && currentdate === logindate) {
              this._util.error('Your account has been locked, please contact system admin.', "Error");
              return;
            }
            else if (loggedInUser.InvalidLogin > 0) {
              var data = { UnLocked: true };
              this.UpdateInvalidLogin(username, data)
                .subscribe(
                  result => {
                    console.log(result)
                  },
                  error => {
                    console.log(error)
                  });
            }
            else {
              this.isLoggedIn = true;
              if (loggedInUser.InvalidLogin > 0) {
              }
            }
            //=============================
            if (localStorage.getItem(this.getHost()))
              localStorage.removeItem(this.getHost());

            var authuid = '';
            if (sessionStorage.getItem('authUserId'))
              authuid = sessionStorage.getItem('authUserId');
            sessionStorage.clear();
            sessionStorage.setItem('authUserId', authuid);
            localStorage.setItem(this.getHost(), btoa(JSON.stringify(user)));
            this.isLoggedIn = true;
            sessionStorage.setItem('loggedInUser', loggedInUser.UserID);
            //=============================
            return loggedInUser;
          }
        }),
        catchError((err) => {
          this.isLoggedIn = false;

          var data = { UnLocked: false };
          this.UpdateInvalidLogin(username, data).
            subscribe(
              result => {

                if (result.message == 'locked') {
                  this._util.error('Your account has been locked, please contact system admin.', "Error");
                }
                else {
                  this._util.error('Your credentials are invalid.', "Error");
                }
              },
              error => {

                this._util.error('Your credentials are invalid.', "Error");
              });

          return of(this.isLoggedIn);
        })
      );
  }

  save(user: any): Observable<any> {
    return this.http.post(`${this.baseURL}portal/uploads/UpdateUserSSO`, user);
  }

  guestUserLogin(token, isCPAuthorized, isGuestUser) {
    localStorage.setItem("isGuestUser", isGuestUser);
    sessionStorage.setItem('isCPAuthorized', isCPAuthorized);
    var user = JSON.parse(this.getAuthorizationUser());

    if (this.router.url.indexOf("rma-status") == -1) {
      if (isCPAuthorized && !isGuestUser && (!user || user.scope != "PTR004")) {
        this.logout();
        this.router.navigate(['/auth/portal']);
      }
    }

    if (!isCPAuthorized || isGuestUser) {
      var loggedInUser = JSON.parse(JSON.stringify(token)).user;
      if (localStorage.getItem(this.getHost()))
        localStorage.removeItem(this.getHost());

      var authuid = '';
      if (sessionStorage.getItem('authUserId'))
        authuid = sessionStorage.getItem('authUserId');
      sessionStorage.clear();
      sessionStorage.setItem('authUserId', authuid);
      localStorage.setItem(this.getHost(), btoa(JSON.stringify(token)));
      sessionStorage.setItem('loggedInUser', loggedInUser.UserID);
    }
  }
  getFullName(): string {
    if (localStorage.getItem(this.getHost()))
      {
        let fullName =  JSON.parse(atob(localStorage.getItem(this.getHost()))).user.FirstName + ' ' + JSON.parse(atob(localStorage.getItem(this.getHost()))).user.LastName;
      
        return fullName;
      }
        else
      return null;
  }
  getFullNameCPPortal(): string {
    if (localStorage.getItem(this.getHost()+'_cp'))
      {
        let fullName =  JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).user.FirstName + ' ' + JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).user.LastName;
      
        return fullName;
      }
        else
      return null;
  }
  getUserEmailCPPortal(): string {
    if (localStorage.getItem(this.getHost()+'_cp'))
      return JSON.parse(atob(localStorage.getItem(this.getHost()+'_cp'))).user.username;
    else
      return null;
  }
  getUserEmail(): string {
    if (localStorage.getItem(this.getHost()))
      return JSON.parse(atob(localStorage.getItem(this.getHost()))).user.username;
    else
      return null;
  }
  getUserCPPortal(): string {
    if (sessionStorage.getItem('loggedInUser'+'_cp'))
      {
        let userId =  sessionStorage.getItem('loggedInUser'+'_cp')
      
        return userId;
      }
        else
      return null;
  }
}