import { EventEmitter, Injectable } from "@angular/core"
import { Subscription } from "rxjs";


@Injectable({    
    providedIn: 'root'    
  })    
export class CallShipmentFunctionSrc {

    
  subsVar1: Subscription;    
  scannedRMA = new EventEmitter();    
    
  constructor() { }    
    
 
  openScannedRMA(name) {    
    this.scannedRMA.emit(name);    
  }   
  
    
}



