import { Component } from "@angular/core";

// both this and the parent component could be folded into one component as they're both simple, but it illustrates how
// a fuller example could work
@Component({
    selector: 'editimage-link',
    template: `<i class="fa fa-check-circle green" aria-hidden="true" style="font-size:20px!important;margin-top:3px!important;" *ngIf="canActive && this.isActive"></i>
    <i class="fa fa-times-circle red" aria-hidden="true" style="font-size:20px!important;margin-top:3px!important;" *ngIf="canActive && !this.isActive"></i>`
})

export class ImageColumnComponent {
    params: any;
    cell: any;
    isActive: boolean = false;
    canActive: boolean = true;
    agInit(params: any): void {
        this.params = params;
        if(!params.data){
            this.canActive = false;
            return;
        }
        if (params.colDef.field == "IsActive" ) {
            this.isActive = params.data.IsActive;
        }
        else if (params.colDef.field == "requiredonCustomerPortal") {
            this.isActive = params.data.requiredonCustomerPortal;
        }
        else if (params.colDef.field == "RequiredonBackOffice") {
            this.isActive = params.data.RequiredonBackOffice;
        }
    }
}

