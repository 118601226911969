import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs/observable";
import 'rxjs/add/operator/map';
import { ConfigurationConstants } from '../../shared/constants';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PaymentConfigService {
    private baseUrl: string;
    constructor(private http: HttpClient) {
        this.baseUrl = ConfigurationConstants.BASEURL;
    }
    GetGeneralConfigById(Id): any {
        return this.http.get(`${this.baseUrl}payment-config/general-config/${Id}`);
    }
    GetPaymentConfigById(Id): any {
        return this.http.get(`${this.baseUrl}payment-config/${Id}`);
    }
    SavePaymentConfig(Id,_data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}payment-config/${Id}`, JSON.stringify(_data));
    }

}
