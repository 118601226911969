import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { Util } from '../../app.util';
import { Menu } from '../../back-office/sidebar/menu.model';
import { SidebarService } from '../../back-office/sidebar/sidebar.service';
import { UserService } from '../../back-office/user/user.service';
import { User } from 'src/app/shared/common.model';
import { GlobalService } from 'src/app/shared/services/global-service';
declare var $: any;


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [UserService]
})
export class ResetPasswordComponent implements OnInit {
  resetUser: User = new User();
  loginLoader: boolean = false;
  brand_code: string;
  internal_code: any;
  logo_login: any = 'assets/img/Colony_Black_Logo.png';
  constructor(private _util: Util, private router: Router
    , private _actRoute: ActivatedRoute,
    private userService: UserService,
    private _auth: AuthService,
    private _glob: GlobalService

  ) {    
    this.brand_code = 'coo';
    this.resetUser = new User();
    this.logo_login = this._glob.utilities.storage.get('logo');
  }

  ngOnInit() {
    this.internal_code = this._actRoute.snapshot.paramMap.get("ic");
    // CHeck if session and url ic is same
    if (this.internal_code != sessionStorage.getItem('ic')) {
      this.router.navigate(['/auth/login']);
    }
   
  }
  ChangePassword() {
    if (this.resetUser.newPassword == '') {
      this._util.error('Please enter the New password.', "warning");
      return;
    }
    if (this.resetUser.confirmPassword == '') {
      this._util.error('Please enter the Confirm Password.', "warning");
      return;
    }
    if (this.resetUser.newPassword != this.resetUser.confirmPassword) {
      this._util.warning('New password and Confirm password does not match.', "warning");
      return;
    }
    if (!this.isValidPassword(this.resetUser.newPassword)) {
      this._util.warning('Password must be Uppercase, Lowercase, special character,number and limit 6 to 30.', "warning");
      return;
    }
    this.loginLoader = true;
    this.resetUser.UserID= this.internal_code;// parseInt(sessionStorage.getItem('uid'));
    
    this._auth.ResetPassword(this.resetUser).subscribe(data => {
      this.loginLoader = false;

      if (data.message == "success") {
        
        this._util.success('Password has been changed successfully.', "Success", "Success");
        this.router.navigate(['/auth/login']);

      }
      else {
        this._util.error(data.message,  "Success");

      }

    }, error => {
      this.loginLoader = false;
      this._util.error(error, 'error')

    });
  }


  private isValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  isValidPassword(password) {// One Lower Case,One Upper case, one specail
    var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,30})");
    return regex.test(password);
  }

}
