// import { Injectable, OnInit } from '@angular/core';
// import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
// import { filter } from 'rxjs/operators';
// import { Subscription } from 'rxjs';
// import { environment } from './environment';


// import { Injectable } from '@angular/core';
// import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// import { environment } from './environment';



// @Injectable()
// export class MyMonitoringService {
// //   appInsights: ApplicationInsights;
// //   constructor() {
// //     this.appInsights = new ApplicationInsights({
// //       config: {
// //         instrumentationKey: environment.appInsights.instrumentationKey,
// //         enableAutoRouteTracking: true // option to log all route changes
// //       }
// //     });
// //    this.appInsights.loadAppInsights();
// //   }

// //   logPageView(name?: string, url?: string) { // option to call manually
// //     this.appInsights.trackPageView({
// //       name: name,
// //       uri: url
// //     });
// //   }

// //   logEvent(name: string, properties?: { [key: string]: any }) {
// //    //  this.appInsights.trackEvent({ name: name}, properties);
// //   }

// //   logMetric(name: string, average: number, properties?: { [key: string]: any }) {
// //    //  this.appInsights.trackMetric({ name: name, average: average }, properties);
// //   }

// //   logException(exception: Error, severityLevel?: number) {
// //    //  this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
// //   }

// //   logTrace(message: string, properties?: { [key: string]: any }) {
// //     this.appInsights.trackTrace({ message: message}, properties);
// //   }
// // }
//  Jim Suggection
import { Injectable } from '@angular/core';
import { environment } from './environment';
import { AppInsights } from 'applicationinsights-js';
@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService {
  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: environment.appInsights.instrumentationKey
  };
  constructor() {
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
    }
  }
  logPageView(name?: string, url?: string, properties?: any,

    measurements?: any, duration?: number) {

    AppInsights.trackPageView(name, url, properties, measurements, duration);

  }
  startNavigationEvent(url: string) {

   AppInsights.startTrackEvent(url);

  }
  endNavigationEvent(url: string) {

   AppInsights.stopTrackEvent(url, { type: 'PAGE LOAD TIME' });

  }
    clearUserId() {
    AppInsights.clearAuthenticatedUserContext();
  }
}
// Rajesh Ref ******************************************************************************************

// @Injectable()
// export class ApplicationInsightsService {
//   private routerSubscription: Subscription;

//   // private appInsights = new ApplicationInsights({
//   //   config: {
//   //     instrumentationKey: environment.appInsights.instrumentationKey
//   //   }
//   // });

//   constructor(private router: Router) {
//     // this.appInsights.loadAppInsights();
//     // this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
//     //   const activatedComponent = this.getActivatedComponent(event.state.root);
//     //   if (activatedComponent) {
//     //     this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
//     //   }
//     // });
//   }

//   setUserId(userId: string) {
//    // this.appInsights.setAuthenticatedUserContext(userId);
//   }

//   clearUserId() {
//    // this.appInsights.clearAuthenticatedUserContext();
//   }

//   logPageView(name?: string, uri?: string) {
//    // this.appInsights.trackPageView({ name, uri });
//   }

//   private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
//     // if (snapshot.firstChild) {
//     //   return this.getActivatedComponent(snapshot.firstChild);
//     // }
//     // return snapshot.component;
//   }

//   private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
//     // let path = '';
//     // if (snapshot.routeConfig) {
//     //   path += snapshot.routeConfig.path;
//     // }
//     // if (snapshot.firstChild) {
//     //   return path + this.getRouteTemplate(snapshot.firstChild);
//     // }
//     // return path;
//     return '';
//   }
// }


