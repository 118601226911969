import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../app.window';
import { UserService } from './user/user.service';
import { GlobalService } from '../shared/services/global-service';
import { AuthService } from '../authentication/auth.service';
import { Router } from '@angular/router';
import { ApplicationInsightsService } from 'src/environments/ApplicationInsightsService';

@Component({
  selector: 'app-back-office',
  templateUrl: './back-office.component.html',
  styleUrls: ['./back-office.component.css'],
  providers: [UserService, AuthService, ApplicationInsightsService]
})
export class BackOfficeComponent implements OnInit {
  queue: string;
  IsScanBoxVisible: boolean = false;
  IsOpenScan: boolean;
  Breadcrumb: string;
  UniqValue:string;
  brand_code: string = 'coo';
  queueType: string;
  placeholderText: string;
  constructor(@Inject(WINDOW) private window: Window, @Inject(DOCUMENT)
  private _document: HTMLDocument, private userService: UserService, private _auth: AuthService,
    private _appInsightService: ApplicationInsightsService,
    private _glob: GlobalService, private router: Router) {


  }
  ngAfterContentInit() {
    var urlParts = this.router.url.split('/');
    this.queueType = urlParts.findIndex(x => x == 'repair') == -1 ? 'return' : 'repair';
  }
  ngOnInit() {
  
    if (!this._auth.isSessionValid()) {
      this._auth.CheckCurrentSession();
    }
    var host = this.window.location.hostname;
    let scope: string = host.split('.')[0];
    // this._document.title = "Returns";
    //alert( this._glob.utilities.storage.get('applicationtitle'))
    this._document.title =   this._glob.utilities.storage.get('application_title')// "Return and Repair";
    this.brand_code = this._glob.utilities.storage.get('brand_code');
    this._document.getElementById('appFavicon').setAttribute('href', this._glob.utilities.storage.get('favicon_icon'));
    if (this.brand_code == '') {
      this.userService.getBrand().subscribe(response => {
        if (response.length > 0) {
         
          this._glob.utilities.storage.set('brand_code', response[0].PartnerCode)
          this._glob.utilities.storage.set('application_title', response[0].applicationTitle)
          this._document.title= response[0].applicationTitle;
          this._glob.utilities.storage.set('applicationSetting', response[0].applicationSetting)
        
          this.brand_code = response[0].PartnerCode;
        
          this._document.getElementById('appFavicon').setAttribute('href', this._glob.utilities.storage.get('favicon_icon'));
        }
        else {
          this.brand_code = 'coo';
          this._document.getElementById('appFavicon').setAttribute('href', this._glob.utilities.storage.get('favicon_icon'));
        }
      });
    }
  }
  toggleScanBox() {
    this.IsOpenScan = !this.IsOpenScan;
  }
  GotoLogin() {
    this._appInsightService.clearUserId();
  }
}
