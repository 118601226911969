import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { Subject, Observable, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { ConfigurationConstants } from 'src/app/shared/constants';
import { WINDOW } from 'src/app/app.window';
@Injectable()
export class addstringService {
 
  givenvalue:any;
  private idleTimeoutInSeconds: number = 910; // 15 minutes
  private idleTimer$ = new Subject<void>();
  private modalRefs: BsModalRef[] = [];
  private _router:Router
  ssologout:any;
  apiUrl:any;
  isLoggedIn = false;
  redirectUrl: string;
  private actionUrl: string;
  baseURL: string = "";
  constructor(@Inject(WINDOW) private window: Window, private router: Router,private http: HttpClient, private _util: Util) {
    this.baseURL = ConfigurationConstants.BASEURL;
    this.apiUrl = 'https://secureidentity-uat.schneider-electric.com/identity/idplogin?app=ReverseLogix&response_type=code&client_id=3MVG9M43irr9JAuwrD5_t.uz0aodM6Q0WqWfvjdPo0SICQOUCih4GAV0JDRau_A6q1eM30Duf8C3EKRjf8Mco&redirect_uri=https://se-uat-api.reverselogix.io/api/accounts/fossologin';
    this.ssologout="https://secureidentity-uat.schneider-electric.com/identity/IDMS_IDPSloInit?RelayState=https://se-uat.reverselogix.io/auth/portal&app=ReverseLogix"
  }
  hashvalue()
  {
    this.givenvalue=' Number';
    return this.givenvalue;

  }

  fetchData(url: string): Observable<any> {
    return this.http.get(url);
  }

  getssourl()
  {
    return this.apiUrl;
  }
  getssologout()
  {
    debugger
    return this.ssologout;
  }

  startIdleTimer(url): void {
    timer(this.idleTimeoutInSeconds * 1000)
      .pipe(takeUntil(this.idleTimer$))
      .subscribe(() => {
        this.onIdleTimeout(url);
      });
  }
  private onIdleTimeout(url): void {
    // Perform logout or any other actions on idle timeout
    console.log('User has been idle for 2 minutes. Logging out...');
    this.isLoggedIn = false;
    //localStorage.removeItem(this.getHost()+'_cp');
    // sessionStorage.removeItem('authUserId'+'_cp');
    // localStorage.removeItem('partnerinfo'+'_cp');    
    // sessionStorage.removeItem("isPageSetting"+'_cp')
    // localStorage.removeItem("isCPHaveLogin");
    // //sessionStorage.removeItem(this.getHost()+'_cp');
    // sessionStorage.removeItem('cpLoginUseremail');
    
    // localStorage.clear();
    // sessionStorage.clear();
    // sessionStorage.removeItem("isPageSetting")   
    // sessionStorage.setItem("IsAutoExpired","true")

    this.logoutCPPortal(url);
    
    //this.router.navigate(["/portal/"+url]);
  
      this.closeAllModals();
    //this.router.navigate(['/auth/portal']);
    //this.router.navigate(['/auth/portal']);
    //this.modalRef.hide();
    

   

    // Implement your logout logic here
  }

  closeAllModals(): void {
    this.modalRefs.forEach((modalRef) => {
      modalRef.hide();
    });
  }
  
  getIdleTimerObservable(): Observable<void> {
    return this.idleTimer$.asObservable();
  }

  resetIdleTimer(url): void {
    this.idleTimer$.next();
    this.startIdleTimer(url);
  }

  getHost(): string {
    return this.window.location.hostname;
  }


  private UpdateExpiryfortoken(portal:any): Observable<any> {

    let nullreturn = new Observable();
     var access_token= '';
     var localhostval = portal =='CP' ? localStorage.getItem(this.getHost() + '_cp') : localStorage.getItem(this.getHost() );
    if(localhostval != null && localhostval != undefined){
      access_token = JSON.parse(atob(localhostval)).access_token ;
   
      this.actionUrl = this.baseURL + "authenticate/logoutForCP";
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "access_token" :access_token
        }),
      };
      return this.http.get(this.actionUrl,httpOptions);
     }
     return nullreturn;
    }
    logoutCPPortal(url): void {  
      this.UpdateExpiryfortoken('CP').subscribe((r) => {
      });  
      this.isLoggedIn = false;
      localStorage.removeItem(this.getHost()+'_cp');
      sessionStorage.removeItem('authUserId'+'_cp');
      localStorage.removeItem('partnerinfo'+'_cp');    
      sessionStorage.removeItem("isPageSetting"+'_cp')
      localStorage.removeItem("isCPHaveLogin");
      sessionStorage.removeItem(this.getHost()+'_cp');
      sessionStorage.removeItem('cpLoginUseremail');
      //localStorage.clear();
      //sessionStorage.clear();
      //this.router.navigate(["/portal/ms/portal-login/en-us"]);
      this.window.location.href = url;//'/portal/ms/portal-login/en-us';
    }
}