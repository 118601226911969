<div id="bg">
        <img src="assets/img/bg-1920.jpg" alt="Reverselogix">
    </div>
   
    <div class="login-container animated fadeInDown">
        <div class="loginbox bg-white col-centered">
        <div class="bg-wihte-t">
            <div class="text-center" style="margin-top:10px;">
                <img src="{{logo_login}}" alt="Logo" /></div>
            <div class="" style="padding:20px 20px;">Reset Password of your account</div>
                    <div class="login-control">
                <div class="loginbox-textbox">
                    <div class="form-group">
                        <input type="password" onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" class="form-control" placeholder="New Password"
                            [(ngModel)]="resetUser.newPassword" name="newPassword" /> 
                    </div>
                </div>
                <div class="loginbox-textbox">
                    <div class="form-group">
                        <input type="password" onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" class="form-control" placeholder="Confirm Password"
                            [(ngModel)]="resetUser.confirmPassword"  name="confirmPassword" />                    
                    </div>
                </div>
                <div class="loginbox-submit">
                    <button type="button" class="btn btn-primary btn-block" (click)="ChangePassword()">                      
                            <i *ngIf="loginLoader" class="glyphicon glyphicon-refresh spinning1" aria-hidden="true"></i>                    
                        Submit</button>
                </div>
               <div style="padding-left: 20px;padding-right:20px;">
                <hr>
            </div>
              
            </div>
        
        <div class="clearfix"></div>
    </div>
        <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
    </div>
