
<div id="bg">
    <img src="assets/img/bg-1920.jpg" alt="Reverselogix">
</div>
<div class="login-container animated fadeInDown">
    <div class="loginbox bg-white col-centered">
        <div class="bg-wihte-t">
            <div class="text-center" style="margin-top:10px;">
                <img src="{{logo_login}}" alt="Logo" /></div>
            <div class="" style="padding:5px 20px;">&nbsp;</div>
            <div class="loginbox-textbox">
            <input type="text" class="form-control" autocomplete="off" ng-class="submited?'ngdirty':''"
             [(ngModel)]="userName" 
             placeholder="Username" required />
             <div>
             </div>
        </div>
        <div class="loginbox-submit text-align-right" style="cursor:pointer;">
            <input type="button" class="btn btn-primary btn-block" (click)="onForgotPassword()" [disabled]="userName.length<=0"
                value="Submit">
            <div class="clearfix"></div>
        </div>
        <div style="padding-left: 20px;padding-right:20px;">
                <hr>
            </div>
        <div class="loginbox-forgot">
                <button class="btn btn-link" routerLink="/auth/login">Back To Login</button>
                <label class="control-label" for="submit"></label>
            </div>
            <div class="clearfix"></div>
</div>
<div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
</div>