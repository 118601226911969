import { Injectable } from "@angular/core"
import { Observable, BehaviorSubject, EMPTY, observable, of } from "rxjs";
import { switchMap } from 'rxjs/operators';
import { ConfigurationConstants } from '../../shared/constants';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { Util } from '../../app.util';
import CryptoJS from "crypto-js";

@Injectable()
export class ReturnsService {
    tenantAdmins: Observable<any>
    _tenantAdmins: BehaviorSubject<any>;
    baseUrl: string;
    carrierAPI: string;
    actionUrl: string;
    private httpBGClient: HttpClient;
    constructor(private http: HttpClient, private _util: Util,handler: HttpBackend) {
        this.baseUrl = ConfigurationConstants.BASEURL;
        this.carrierAPI = ConfigurationConstants.BASEAPIURLForCarrier;
        this.httpBGClient = new HttpClient(handler)
    }

    order(filterValue: string): Observable<any> {
        var options = filterValue.split('|');
        var data = { "ordernumber": options[0], "email": options[1], "LanguageCode": options[2],  "BrandCode": (options.length > 3 && options[3] || 'none'), "Source": options[4]}
        return this.http.post(`${this.baseUrl}return/findorder`, JSON.stringify(data));
    }
    orderNew(filterValue: string): Observable<any> {
        var options = filterValue.split('|');
        var data = { "ordernumber": options[0], "email": options[1], "LanguageCode": options[2],  "BrandCode": (options.length > 3 && options[3] || 'none'), "Source": options[4]}
        return this.http.post(`${this.baseUrl}return/findorderNew`, JSON.stringify(data));
    }
    orders(AdvancefilterValue: any): Observable<any> {
        
        return this.http.post(`${this.baseUrl}return/orders`, JSON.stringify(AdvancefilterValue));
    }
    saveMarkRTV_Shipment(data: any): Observable<any> 
    {
        return this.http.post(`${this.baseUrl}repair/saveMarkRTV_Shipment`, JSON.stringify(data));
    }
    approveOrRejectAll(data: any): Observable<any> {
        
        return this.http.post(`${this.baseUrl}return/approveOrRejectAll`, JSON.stringify(data));
    }

    returnOrder(filterValue: string, queueStatus: string, srNo: string = '0', ic: string = '0'): Observable<any> {
        debugger;
        if (queueStatus == "shipped") {
            filterValue = filterValue.replace("|en-us", "");
            return this.http.get(`${this.baseUrl}return/tracking/ShippedTracking/${filterValue}`);
        }
        else
            return this.http.get(`${this.baseUrl}return/${queueStatus}/${filterValue}/${srNo}/${ic}`);
    }

    return(): boolean {
        return true;
    }
    getReturnOrders(startIndex, endIndex, sortColumn, sortDirection, filterValue, Status, fromDate, toDate): Observable<any> {
        return this.http.get(`${this.baseUrl}return/${startIndex}/${endIndex - startIndex}/${sortColumn}/${sortDirection}/${filterValue}/${fromDate}/${toDate}/${Status}`);
    }

    getlabels(data: any): Observable<any> {
        if (data.brand.toLowerCase() == 'zw')
            return this.http.post(`${this.carrierAPI}api/carrier/GetLabelByPackage`, JSON.stringify(data));
        else
            return this.http.post(`${this.carrierAPI}api/carrier`, JSON.stringify(data));
    }

    getreturnaddressbyselecteditems(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/GetReturnAddress`, JSON.stringify(data));
    }
    
    checkRmaFraud(email: string, source: string, type: string): Observable<any> {
        let body={
            email:email
        }
        return this.http.post(`${this.baseUrl}return/checkRmaFraud/${source}/${type}`,body);
    }

    saveRMA(data: any, isWarrantyReturnApproved: boolean, generate_label: boolean,resultLabelResponse:any=null): Observable<any> {
        debugger;

        if (data.returnfreight && data.returnfreight.active == true && isWarrantyReturnApproved == true && data.is_approval_required == false) {


            return this.getreturnaddressbyselecteditems(data).pipe(switchMap(returnObject => {
                if (returnObject && returnObject.return_address && returnObject.return_address.length > 0) {

                    if(!data.isDropShipReturn){
                        data.return_DC = returnObject.return_address[0];
                    }
                   
                    data.referenceNumber = data.order_number_reference;

                    if (data.items) {

                        data.items.forEach(element => {
                            element.weight = parseFloat(element.weight) * element.return_quantity;
                            element.weight = element.weight.toString();
                        });

                    }

                    if(data.isRuleBasedCarrier && data.ruleBasedCarrierDetails?.carrierName 
                        && data.ruleBasedCarrierDetails?.configGroup && (data.returnfreight?.ServiceLevel!=data.ruleBasedCarrierDetails?.CarrierName)){
                          data.returnfreight.ServiceLevel=data.ruleBasedCarrierDetails.carrierName
                          data.returnfreight.carrier_code=data.ruleBasedCarrierDetails.configGroup
                          data.returnfreight.carrierLogo=data.ruleBasedCarrierDetails.carrierLogo
                          data.returnfreight.dropOff=data.ruleBasedCarrierDetails.dropOff
                      }

                    if(generate_label && data?.needToReturnItems){
                        
                        if (resultLabelResponse != null && resultLabelResponse.status != "ERROR" && resultLabelResponse.status != 'FATAL') {
                            return this.updateLabelGenerationData(data,resultLabelResponse);
                        }
                        else{
                            return this.getlabels(data).pipe(switchMap(result => {
                                return this.updateLabelGenerationData(data,result);
                            }));
                        }   
                    }
                    else{
                        data.labels = [];
                        data.returnfreight.active = false;
                        return this.http.post(`${this.baseUrl}return/customer`, JSON.stringify(data));
                    }
                    

                }
            }));
        }
        else {
            return this.http.post(`${this.baseUrl}return/customer`, JSON.stringify(data));
        }
    }

    GenerteLableForApproved(data: any, IsAllReject: boolean, returnOrder: any, items,generate_shipping_label:boolean): Observable<any> {
        
        if (IsAllReject == false) {
            
            let isfinalapprover = returnOrder.items[0].next_level_approver == 0 ? true : false;
            if (isfinalapprover && generate_shipping_label) {
                return this.getreturnaddressbyselecteditems(data).pipe(switchMap(returnObject => {
                    if (returnObject && returnObject.return_address && returnObject.return_address.length > 0) {
                        data.return_DC = returnObject.return_address[0];
                        return this.getlabels(data).pipe(switchMap(result => {
                            if (result.status != "ERROR" && result.status != 'FATAL') {
                                debugger
                                var _parcels = data.shipment.parcels;
                                if (result.packages) {
                                    data.shipment.parcels = result.packages;
                                }
                                data.labels = result.labelURLs;
                                data.custom_declaration = result.customURLs;

                                let RetOrder = {
                                    items: items,
                                    return_order_number: returnOrder.return_order_number,
                                    parcel: result,
                                    labels: result.labelURLs,
                                    mode: 'approve'
                                }
                                return this.PostDataToServer("usp_returns_approve", RetOrder)
                            }
                            else {

                                this._util.error((result.errorMessage ? result.errorMessage : "An error has occured while generating the shipping label. Apologies for the inconvenience."), "Alert");
                                return EMPTY;
                            }
                        }));
                    }
                    else {
                        return EMPTY;
                    }
                }));
            }
            else if(!generate_shipping_label && isfinalapprover)
            {
                let RetOrder = {
                    items: items,
                    return_order_number: returnOrder.return_order_number,
                    parcel: '',
                    labels: '',
                    mode: 'approvewithoutShippingLabel'
                }
                return this.PostDataToServer("usp_returns_approve", RetOrder)
            }
            else {
                let RetOrder = {
                    items: items,
                    return_order_number: returnOrder.return_order_number,
                    mode: 'pending'
                }
                return this.PostDataToServer("usp_returns_approve", RetOrder)
            }
        }
        else {
            let RetOrder = {
                items: items,
                return_order_number: returnOrder.return_order_number,
                mode: 'reject',
                comment: returnOrder.items[0].remark
            }
            return this.PostDataToServer("usp_returns_approve", RetOrder)
        }
    }

    CheckStatusOfLabelGeneration(data,generate_label){
        if(generate_label)
        {
                return new Promise(async (resolve, reject) => {
                try {
                    this.getlabels(data).subscribe((response)=>{
                    resolve(response);
                    })  
                }
                catch (error) {
                    reject(error);
                }
            
            });
        }
        else{
            return new Promise(async (resolve, reject) => {
                try {                    
                    resolve({"status":"OK","packages":[{"tracking_no":"","route_number":"","service_code":"","carrier_code":"","carrier_tracking_number":"","tracking_url":""}],"labelURLs":[{"url":"","type":"","format":""}],"shipment_id":"","errorMessage":null,"ReferenceNumber":null});
                   
                }
                catch (error) {
                    reject(error);
                }

              
            });
        }
      }

    updateLabelGenerationData(data,result)
    {
        if (result.status != "ERROR" && result.status != 'FATAL') {
            debugger;
            console.log(result);
            var _parcels = data.shipment.parcels;
            if (result.packages) {
                data.shipment.parcels = result.packages;
            }
            data.labels = result.labelURLs;
            data.custom_declaration = result.customURLs;
            // carrier implementation
            //data.shipment.parcels[0].carrier_code= data.CarrierId;
            //data.shipment.parcels[0].tracking_no= data.tracking_no;

            //=========New for oversized========//
            if (data.shipment.parcels.length == _parcels.length) {
                for (let index = 0; index < data.shipment.parcels.length; index++) {
                    data.shipment.parcels[index].oversized = _parcels[index].oversized;
                }
            }

            return this.http.post(`${this.baseUrl}return/customer`, JSON.stringify(data));
        }
        else {

            this._util.error((result.errorMessage ? result.errorMessage : "An error has occured while generating the shipping label. Apologies for the inconvenience."), "Alert");
        
            let errorRequestBody={
                errorMessage:JSON.stringify(result),
                requestPayload:data,
                errorSource:'Source API - label generation'
              }
              this.loggError(errorRequestBody).subscribe((result)=>{console.log(result)})
            return of(result);
        }
    }
    UpdateRMA(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/updaterma/${data.return_order_number}`, JSON.stringify(data));

    }


    uploadImages(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}uploads/files`, data);
    }

    postReturnNotes(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/note`, JSON.stringify(data));
    }

    getReturnNotes(order: string): Observable<any> {
        return this.http.get(`${this.baseUrl}return/notes/${order}`)
    }

    scanRMA(RMANumber: string, oldStatus: string, newStatus: string): Observable<any> {
        //RMANumber = encodeURIComponent(RMANumber);
        debugger;
        RMANumber = btoa(RMANumber);
        return this.http.get(`${this.baseUrl}return/status/${RMANumber}/${oldStatus}/${newStatus}`)
    }

    UpdateRMAFile(data: any, rmanumber: string): Observable<any> {
        return this.http.post(`${this.baseUrl}return/files/${rmanumber}`, data);
    }

    getUBParcelStatus1(trackNumber): Promise<any> {
        let headers: HttpHeaders = new HttpHeaders({
            'api_key': '72f01b7f-9bc6-41c9-ba1e-8d7481d2cfa7'
        });
        let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        httpOptions.headers = httpOptions.headers.append('api_key', '72f01b7f-9bc6-41c9-ba1e-8d7481d2cfa7');
        return new Promise((resolve, reject) => {
            this.http.get('https://api.coureon.com/api/v1_6/tracking/' + trackNumber, httpOptions)
                .toPromise()
                .then(
                    res => { // Success
                  
                        resolve(res);
                    }
                );
        });
    }

    GenerateCustomDecalartionLabel(data: any): Observable<any> {
        return this.http.post(`${this.carrierAPI}api/carrier/GenerateCustomDecalartionLabel`, JSON.stringify(data));
    }

    getPartners(): Observable<any> {
        return this.http.get(`${this.baseUrl}common/partners/assigned`);
    }

    getUPSShipmentOptions(data: any): Observable<any> {
       
        return this.http.post(`${this.carrierAPI}api/carrier/GetShippingCharges`, JSON.stringify(data));
    }

    cancelRMA(data: any): Observable<any> {
        debugger
        return this.http.post(`${this.baseUrl}return/CancelRMA`, JSON.stringify(data));
    }

    cancelGlobalRMA(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/CancelGlobalRMA`, JSON.stringify(data));
    }

    updateCanceledRMAStatus(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/updatecanceledRMAStatus`, JSON.stringify(data));
    }

    cancelUPSPickup(data: any): Observable<any> {
       debugger;
        return this.http.post(`${this.carrierAPI}api/carrier/PickupCancelRequest`, JSON.stringify(data));
    }

    reAssignRma(data: any): Observable<any> {
        debugger
        return this.http.post(`${this.baseUrl}repair/ReAssignRma`, JSON.stringify(data));
    }
    checkItemGuid(filterValue: string): Promise<any> {
        return this.http.get(`${this.baseUrl}return/checkItemGuid/${filterValue}`).toPromise();
    }

    automaticClosureOfItems(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/AutomaticClosureOfItems`, JSON.stringify(data));
    }
    GenerateCreditMemo(data: any): Observable<any> {
        
        return this.http.post(`${this.carrierAPI}api/ImportData/CreditMemo`, JSON.stringify(data));
    }
    PostDataToServer(proc_name: string, data: any): Observable<any> {
        return this.http.post(
            `${this.baseUrl}common/postdataserver/${proc_name}`,
            JSON.stringify(data)
        );
    }

    GetCustomerReview(startIndex, endIndex, sortColumn, filterRating, filterValue, partnerID): Observable<any> {
        return this.http.get(`${this.baseUrl}return/customerReview/${startIndex}/${endIndex}/${sortColumn}/${filterRating}/${filterValue}/${partnerID}`);
    }

    GetPageSetting(data): Observable<any> {
        return this.http.post(
            `${this.baseUrl}portal/return/postdataserver/usp_get_Page_control_setting`,
            JSON.stringify(data)
        );
    }

    EncryptByAES(str) {
        var KEY = "$aS^AmR$@Cx2o19!$aS^AmR$@Cx2o19!";//32 bit
        var IV = "$aS^AmR$@Cx2o19!";//16 bits
        var key = CryptoJS.enc.Utf8.parse(KEY);
        var iv = CryptoJS.enc.Utf8.parse(IV);
        var encrypted: any;
        var srcs = CryptoJS.enc.Utf8.parse(str);
        encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString();
    }

    DecryptByAES(value): any {
        var KEY = "$aS^AmR$@Cx2o19!$aS^AmR$@Cx2o19!";//32 bit
        var IV = "$aS^AmR$@Cx2o19!";//16 bits
        var key = CryptoJS.enc.Utf8.parse(KEY);
        var iv = CryptoJS.enc.Utf8.parse(IV);
        var encryptedHexStr = CryptoJS.enc.Hex.parse(value);
        var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        var decrypt = CryptoJS.AES.decrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }

    SaveRMAApprovalEmail(data): Observable<any> {
        return this.http.post(
            `${this.baseUrl}portal/return/postdataserver/usp_rma_approval_email`,
            JSON.stringify(data)
        );
    }
    rmaInitiateEmail(data: any): Observable<any> {
        return this.http.post(
            `${this.baseUrl}return/rmaInitiateEmail`,
            JSON.stringify(data)
        );
    }

    syncCustomerOrder(): Observable<any> {
        return this.http.get(`${this.carrierAPI}api/ftpSync/SyncOrder`);
    }

    syncData(): Observable<any> {
        return this.http.get(`${this.baseUrl}return/customerOrderSyncData`);
    }

     //call YRSH in return back office
    getOrderWithSageX3PostApi(obj: any): Observable<any> {
        debugger
        return this.http.post(`${this.carrierAPI}api/Carrier/SageX3ApiCallYSRHPost/`, JSON.stringify(obj));
    }

    getReturnType(orderNumber: string): Observable<any> {
        return this.http.get(`${this.baseUrl}lookup/returnType/${orderNumber}`);
    }
    public SaveUserEventsLog(aUserId: number, UserId: number, ModuleName: string, url: string, action: string, IPAddress: string) {
        var data = { authUserId: aUserId, UserId: UserId, ModuleName: ModuleName, url: url, action: action, IPAddress: IPAddress }
        return this.http.post(`${this.baseUrl}authenticate/saveUserEventsLog`, JSON.stringify(data)).subscribe((res: any) => { })
      }

      GetUPSPickUp(data: any): Observable<any> {

        return this.http.post(`${this.carrierAPI}api/Carrier/UPSCarrierPickUp/`, JSON.stringify(data));
      }

      saveUpsPickUp(data: any): Observable<any> {
        return this.http.post(
          `${this.baseUrl}portal/return/saveUpsPickUp`,
          JSON.stringify(data)
        );
      }
	  GenerateServiceNotes(data: any): Observable<any> {
        return this.http.post(`${this.carrierAPI}api/carrier/HTMLToPdf`, JSON.stringify(data));
      }

      getCompensationTypesForItem(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/getCompensationTypesForItem`,payload)
      }

      getServiceTypes(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/getServiceTypes`,payload)
      }

      getRefundAmount(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/GetRefundAmount`,payload)
      }
    
      getProductVariants(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/GetProductVariants`,payload)
      }
    
      checkInventory(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/CheckInventory`,payload)
      }

      saveErrorLog(error: any, source: string): Observable<any> {
        
        let body = {payload:error, source: source}
        return this.http.post(`${this.baseUrl}return/saveErrorLog`,body)
      }
      returnNextSequenceOrder(filterValue: string, srNo: string, ic: string): Observable<any> {
        srNo=srNo?srNo:'0';
        if(ic == undefined || ic == null)
        {
            ic = '0';
        }
        let data: any = {};
        data.filterValue = filterValue;
        data.srNo = srNo;
        data.ic = ic;
        return this.http.post(`${this.baseUrl}return/GetDetailNextStatus`, JSON.stringify(data));
        // return this.http.get(`${this.baseUrl}return/${queueStatus}/${filterValue}/${srNo}/${ic}`);
    }

    returnNextSequenceOrderWithPromise(filterValue: string, srNo: string, ic: string): Promise<any> {
        srNo=srNo?srNo:'0';
        if(ic == undefined || ic == null)
        {
            ic = '0';
        }
        let data: any = {};
        data.filterValue = filterValue;
        data.srNo = srNo;
        data.ic = ic;
        return new Promise((resolve, reject) => {
            this.http.post(`${this.baseUrl}return/GetDetailNextStatus`, JSON.stringify(data))
                .toPromise()
                .then(
                    res => { // Success
                  
                        resolve(res);
                    }
                );
        });
        // return this.http.post(`${this.baseUrl}return/GetDetailNextStatus`, JSON.stringify(data)).toPromise;
        // return this.http.get(`${this.baseUrl}return/${queueStatus}/${filterValue}/${srNo}/${ic}`);
    }

      returnRMAWorkflowDetail(filterValue: string, srNo: string, ic: string, queue: string): Observable<any> {
        srNo?srNo:'0';
        if(ic == undefined || ic == null)
        {
            ic = '0';
        }
        let data: any = {};
        data.filterValue = filterValue;
        data.srNo = srNo;
        data.ic = ic;
        data.queue = queue;
        return this.http.post(`${this.baseUrl}return/GetDetailRMAWorkflowDetail`, JSON.stringify(data));
        // return this.http.get(`${this.baseUrl}return/${queueStatus}/${filterValue}/${srNo}/${ic}`);
    }


      getOrderDetailsByEmailOnCp(Order: string, brand: string, language: string): Observable<any> {
        var data = { "Order": Order,  "Brand": brand, "Language": language}
        return this.http.post(`${this.baseUrl}return/getOrderDetailsByEmail/`, JSON.stringify(data));
      }

      getOrderNumberOnCp(Order: string, brand: string, language: string): Observable<any> {
        debugger
        var data = { "Order": Order,  "Brand": brand, "Language": language}
        return this.http.post(`${this.baseUrl}portal/return/GetOrderNoCP/`, JSON.stringify(data));
      }
      GetFedExPickUp(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}FedExPickup`, JSON.stringify(data));
      }
      rlUpdateCustomerEmail(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLApi/CustomerOrderEmail`, JSON.stringify(data));
      }
      RMAProcess(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/RMAProcess`,payload)
      }

      callApproveRMAEndPoints(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/CallApproveRMAEndPoints`, payload);
      }

      callPreRMAEndPoints(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/CallPreRMACreationEndPoints`, payload);
      }

      createManualRefund(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/ManualRefund`, payload);
      }

      createProcessRefund(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/ProcessRefund`, JSON.stringify(payload));
      }

      getProductSubstitutes(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}RLAPIIntegration/GetProductSubstitutes`,payload)
      }

      callPostRMAEndPoints(payload: any): Observable<any> {
        return this.httpBGClient.post(`${this.baseUrl}RLAPIIntegration/CallPostRMACreationEndPoints`, payload);
      }

      getItemAddress(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}lookup/getItemAddress`,payload);
      }
      // notes implementations start added by rahulp on 29/02/2024
      save_rmaNoteFileUpload(id: number, rmaNo: string,UploadFilename:string , CreatedBy: number,FileUrl:string,DOA:boolean,files:string,source:string): Observable<any> {
            
        var data = { "id": id, "createdBy": CreatedBy, "rmaNo": rmaNo, "UploadFilename": UploadFilename,"FileUrl":FileUrl,"DOA":DOA,"files":files,"source": source}
       
        return this.http.post(`${this.baseUrl}return/saveOrder_rmaNoteFile/`, JSON.stringify(data));
      }

      save_rma_Note(Note: string, rmaNo: string, source: string, CreatedBy: number): Observable<any> {
            
        var data = { "Note": Note, "CreatedBy": CreatedBy, "rmaNo": rmaNo, "source": source }
        return this.http.post(`${this.baseUrl}return/saveOrder-rma/`, JSON.stringify(data));
      }

      getRmaNoteByRmaNo(rmaNo: string): Observable<any> {
            
        var data = {"rmaNo": rmaNo}
        return this.http.post(`${this.baseUrl}return/getRmaNote_byRmaNo/`, JSON.stringify(data));
      }
      getfileuploadList_by_rmaNo(rmaNo: string): Observable<any> {
            
        var data = {"rmaNo": rmaNo}
        return this.http.post(`${this.baseUrl}return/getUploadFileList_byRmaNo/`, JSON.stringify(data));
      }
      // notes implementations end added by rahulp on 29/02/2024


      getCarrierDetailsForOverride(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}return/getCarrierDetailsForOverride`,payload)
      }

      EncryptByAESPayment(str) {
        var KEY = "cff83aefdad031dd74a8704be5afed30";//32 bit
        var IV = "62c7941526b701c1";//16 bits
        var key = CryptoJS.enc.Utf8.parse(KEY);
        var iv = CryptoJS.enc.Utf8.parse(IV);
        var encrypted: any;
        var srcs = CryptoJS.enc.Utf8.parse(str);
        encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString();
    }
      loggError(payload: any): Observable<any> {
        return this.httpBGClient.post(`${this.baseUrl}RLAPIIntegration/errorlog`, payload);
      }
    

}
