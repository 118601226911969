import { Injectable, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { ConfigurationConstants } from '../../shared/constants';


@Injectable()
export class BackOfficeSettingService {
    private baseUrl: string;
  
    constructor(private http: HttpClient) {
        this.baseUrl = ConfigurationConstants.BASEURL;
    }

    getBOPageSetting(brand: string, language: string): Observable<any> {
        return this.http.get(`${this.baseUrl}lookup/languages/${brand}/${language}`);
    }
    
    async getBOPageSettingAsync(brand: string, language: string): Promise<any> {
         return await this.http.get(`${this.baseUrl}lookup/languages/${brand}/${language}`).toPromise()
    }
}
