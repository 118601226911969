<div id="setting" class="page-setting" style="height: 336px;">
    <div class="padding-20" style="color:#666;font-size: 14px !important;">
        <strong class="text-primary">Landing Page</strong><br> Set your current page as your landing page and will be
        applicable from your next login, set this page as your landing page ?
        <div class="clearfix"></div>
        <div class="padding-top-10 ">
            <button (click)="resetLanding()" class="btn btn-sm btn-primary1 margin-right-5" style="padding:5px 12px!important;">Clear</button>
                <button (click)="setLanding()" class="btn btn-sm btn-success" style="padding:5px 12px!important;">Ok</button>
        </div>
        <hr style="margin: 8px 0px;" />
        <div class="form-group" style="color:#666;">
            <strong class="text-primary">Timezone</strong><br>
            <div class="clearfix"></div>
            <div class="padding-top-10 ">
                <select [(ngModel)]="Timezone" #timezone="ngModel" name="timezone" class="form-control">
                    <option *ngFor="let tz of _Timezones;" [value]="tz.timezoneId">
                        {{tz.timezonedescription}}
                    </option>
                </select>
            </div>
            <div class="clearfix"></div>
            <div class="padding-top-10 "><button (click)="resetTimezone()" class="btn btn-sm btn-success"
                    style="padding:5px 12px!important;">Ok</button></div>
            <hr style="margin: 8px 0px;" />
        </div>
    </div>
</div>
<div class="page-breadcrumbs page-header">
    <ul class="breadcrumb">
        <li *ngIf="_route && (_route.split('/')?.length>0)" class="active">{{_route?.split('/')[0]}}</li>
        <li *ngIf="_route && (_route.split('/')?.length>1)" class="active">{{_route?.split('/')[1]}}</li>
        <li *ngIf="_route && (_route.split('/')?.length>2)" class="active">{{_route?.split('/')[2]}}</li>
    </ul>
    <div class="col-md-4" style="position: absolute;top: 1px;left:66%;">
        <div class="input-group" style="top: 2px; width: 100%;"*ngIf="IsScanBoxVisible">
            <input  #autoFocous  [(ngModel)]="scanRMA"  maxlength= "25"
                (keyup.enter)="scanRMAByRMANumber(scanRMA,queue)" class="form-control form-control-sm" 
                style="border-radius: 25px !important;border-color: #00bcd5!important;color:#000000!important" type="text" 
                [placeholder]="placeholderText"  >
            <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2" style="background-color: transparent;border: 0px;margin-left: -40px;
                z-index: 11;padding-top:11px !important;">
                <i class="fa fa-barcode" aria-hidden="true" style="font-size:20px;color: #00bcd5;"></i></span>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>
<div id="searchpanel">
</div>