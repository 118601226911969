import { Pipe, PipeTransform } from '@angular/core';
declare var $: any;
@Pipe({ name: 'startFrom' })
export class startFromPipe implements PipeTransform {
   
    transform(input, start)  {
            start = +start; //parse to int
            if (typeof input != "undefined")
                return input.slice(start);
        }
    }
