import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, EMPTY } from "rxjs";
import { switchMap } from "rxjs/internal/operators/switchMap";
import { ConfigurationConstants } from "src/app/shared/constants";
import { AuthService } from "src/app/authentication/auth.service";
import {
  HttpHeaders,HttpBackend,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { Util } from "src/app/app.util";

@Injectable()
export class RepairService {
  tenantAdmins: Observable<any>;
  _tenantAdmins: BehaviorSubject<any>;
  baseUrl: string;
  carrierAPI: string;
  actionUrl: string;
  private httpBGClient: HttpClient;
  constructor(
    private http: HttpClient,
    private _auth: AuthService,
    private _util: Util, handler: HttpBackend
  ) {
    this.baseUrl = ConfigurationConstants.BASEURL;
    this.carrierAPI = ConfigurationConstants.BASEAPIURLForCarrier;
    this.httpBGClient = new HttpClient(handler)
  }

  
  getFreightFacility(originCountryCode: string, brandCode: string): Observable<any> {
    return this.http.get(this.baseUrl + "portal/repair/GetFreightFacility/" + originCountryCode + "/" + brandCode);
  }
  order(filterValue: string): Observable<any> {
    var options = filterValue.split("|");
    var data = {
      ordernumber: options[0],
      email: options[1],
      LanguageCode: options[2],
      BrandCode: (options.length > 3 && options[3]) || "none",
    };

    return this.http.post(
      `${this.baseUrl}repair/findorder`,
      JSON.stringify(data)
    );
  }

  orders(AdvancefilterValue: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}repair/orders`,
      JSON.stringify(AdvancefilterValue)
    );
  }

  returnOrder(filterValue: string, queueStatus: string): Observable<any> {
    return this.http.get(`${this.baseUrl}repair/${queueStatus}/${filterValue}`);
  }

  returnReasonList(): Observable<any> {
    return this.http.get(`${this.baseUrl}repair/resultresultresult`);
  }
  

  return(): boolean {
    return true;
  }

  getReturnOrders(startIndex, endIndex, sortColumn, sortDirection, filterValue, Status, fromDate, toDate, brandCode ): Observable<any> {
    if(filterValue)
      return this.http.get(`${this.baseUrl}repair/${startIndex}/${endIndex - startIndex
        }/${sortColumn}/${sortDirection}/${encodeURIComponent(filterValue)}/${fromDate}/${toDate}/${Status}/${brandCode}`
      );
    else
      return this.http.get(`${this.baseUrl}repair/${startIndex}/${endIndex - startIndex
      }/${sortColumn}/${sortDirection}/${filterValue}/${fromDate}/${toDate}/${Status}/${brandCode}`
    );
  }

  getlabels(data: any): Observable<any> {
    if (data.brand.toLowerCase() == 'zw')
      return this.http.post(`${this.carrierAPI}api/carrier/GetLabelByPackage`, JSON.stringify(data));
    else
      return this.http.post(`${this.carrierAPI}api/carrier`, JSON.stringify(data));
  }

  saveGNRequest(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}repair/saveGNreturn`,
      JSON.stringify(data)
    );
  }


  saveRMA(data: any, isWarrantyReturnApproved: boolean): Observable<any> {
    if (
      data.returnfreight &&
      data.returnfreight.active == true &&
      isWarrantyReturnApproved == true
    ) {
      return this.getlabels(data).pipe(
        switchMap((result) => {
          if (result.status != "ERROR" && result.status != "FATAL") {
            var _parcels = data.shipment.parcels;
            if (result.packages) {
              data.shipment.parcels = result.packages;
            }
            data.labels = result.labelURLs;
            data.custom_declaration = result.customURLs;

            //=========New for oversized========//
            if (data.shipment.parcels.length == _parcels.length) {
              for (
                let index = 0;
                index < data.shipment.parcels.length;
                index++
              ) {
                data.shipment.parcels[index].oversized =
                  _parcels[index].oversized;
              }
            }
            return this.http.post(
              `${this.baseUrl}repair/customer`,
              JSON.stringify(data)
            );
          } else {
            this._util.error(
              "An error has occured while generating the shipping label. Apologies for the inconvenience." +
                (result.errorMessage ? " - " + result.errorMessage : ""),
              "Alert"
            );
            return EMPTY; //Observable.empty();
          }
        })
      );
    } else {
      return this.http.post(
        `${this.baseUrl}repair/customer`,
        JSON.stringify(data)
      );
    }
  }
  PostDataToServer(proc_name: string, data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}repair/postdataserver/${proc_name}`,
      JSON.stringify(data)
    );
  }
  get_charge_id(data: any): Observable<any> {
  
    return this.http.post(`${this.baseUrl}repair/get_charge_id`, JSON.stringify({ "paymentId": data}))
  }
  uploadImages(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}uploads/files`, data);
  }
  getmodellist( productTypeID): Observable<any> {
    return this.http.get(
      `${this.baseUrl}repair/getmodellist/${productTypeID}`
    );
  }


  CheckOrderExists(obj:any)
  {
    return this.http.post(
      `${this.baseUrl}repair/CheckOrderExists`, JSON.stringify(obj)
    );
  }
  getNestSuitetails(): Observable<any>
  {
    return this.http.get(
      `${this.baseUrl}repair/getNestSuitetails`
    );
  }
  SaveUplodedFiles(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}repair/UploadFiles`, JSON.stringify(data));
  }

  postReturnNotes(data: any): Observable<any> {
    debugger
    return this.http.post(`${this.baseUrl}repair/note`, JSON.stringify(data));
  }

  postAuthorizedRequest(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}repair/boOrderReject`,
      JSON.stringify(data)
    );
  }

  getReturnNotes(refnumber: string): Observable<any> {
    return this.http.get(`${this.baseUrl}repair/notes/${refnumber}`);
  }

  scanRMA(
    RMANumber: string,
    oldStatus: string,
    newStatus: string,
    queueComment:string
    
  ): Observable<any> {
    //RMANumber =  encodeURIComponent(RMANumber);
    RMANumber =  btoa(RMANumber);
    let data={'queueComment':queueComment}
    return this.http.post(
      `${this.baseUrl}repair/scanrma/${RMANumber}/${oldStatus}/${newStatus}`,
      JSON.stringify(data)
    );
  }
  
  SaveRepairdData(
    RMANumber: string,
    oldStatus: string
  ): Observable<any> {
    return this.http.get(
      `${this.baseUrl}repair/saveRepairdStatus/${RMANumber}/${oldStatus}`
    );
  }
  UpdateDefect_details(orderNumber,issueId, brandCode,SelectedWarrantyId,defectsList,queueComment): Observable<any>
  {
  
     return this.http.post(`${this.baseUrl}repair/UpdateDefect_details/${orderNumber}/${brandCode}/${SelectedWarrantyId}/${issueId}/${queueComment}`, defectsList);
  } 
  

  updateRTV(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}repair/updateRTV`,
      JSON.stringify(data)
    );
  }
  UpdateCustomerAddress(orderNumber, customerShippingAddressList): Observable<any>
  {
    //    
     return this.http.post(`${this.baseUrl}repair/UpdateCustomerAddress/${orderNumber}`,customerShippingAddressList);
  } 
  UpdateCustomerAddressBO(orderNumber, customerShippingAddressList): Observable<any>
  {
    debugger
     return this.http.post(`${this.baseUrl}repair/UpdateCustomerAddressBO/${orderNumber}`,customerShippingAddressList);
  } 

  UpdateCustomerDetails(orderNumber, customerDetails): Observable<any>
  {
     return this.http.post(`${this.baseUrl}repair/UpdateCustomerDetails/${orderNumber}`,customerDetails);
  } 
  UpdateCustomerProduct(orderNumber, productTypeId,ItemId,returnDetailsId,sku, serialNumber): Observable<any>
  {
    //    
     return this.http.post(`${this.baseUrl}repair/UpdateCustomerProduct/${productTypeId}/${ItemId}/${returnDetailsId}/${orderNumber}/${sku}/${serialNumber}`,'');
  } 

  AddInternalNotes(orderNumber: string, NotesData: string): Observable<any>
  {
    //    
     return this.http.post(`${this.baseUrl}repair/AddInternalNotes/${orderNumber}`, NotesData);
  } 

  getReturnNotes1(refnumber: string): Observable<any> {
    return this.http.get(`${this.baseUrl}repair/internalNotes/${refnumber}`);
  }

  UpdateSendQuote(orderNumber,ShippingCost,ShippingTax,Quote_Amount,RepairServiceCost,defectsList,SelectedWarrantyId,isQuoteAutoApprove): Observable<any>
  {
    
    return this.http.post(`${this.baseUrl}repair/UpdateSendQuote/${orderNumber}/${ShippingCost}/${ShippingTax}/${Quote_Amount}/${RepairServiceCost}/${SelectedWarrantyId}/${isQuoteAutoApprove}`,defectsList);
  }
 SendPaymentQuoteLink(orderNumber,ShippingCost,ShippingTax,Quote_Amount,RepairServiceCost,defectsList,SelectedWarrantyId,isQuoteAutoApprove): Observable<any>
  {
    
    return this.http.post(`${this.baseUrl}repair/SendPaymentQuoteLink/${orderNumber}/${ShippingCost}/${ShippingTax}/${Quote_Amount}/${RepairServiceCost}/${SelectedWarrantyId}/${isQuoteAutoApprove}`,defectsList);
  }
  SendMail(){
    return this.http.post(`${this.baseUrl}repair/SendMailCV`,'');
  }

  UpdateRMAFile(data: any, rmanumber: string): Observable<any> {
    return this.http.post(`${this.baseUrl}repair/files/${rmanumber}`, data);
  }

  rmaqueueApproval(orderNumber:string,statusType:string,DateCode:string,Comment:string,RejectReason:string): Observable<any>
  {
    return this.http.get(`${this.baseUrl}repair/rmaqueueApproval/${orderNumber}/${statusType}/${DateCode}/${Comment}/${RejectReason}`);
  }

  SavePartsAllocated(orderNumber, repairPartsList,queueComment): Observable<any>{
    
    return this.http.post(`${this.baseUrl}repair/SavePartsAllocated/${orderNumber}/${queueComment}`, repairPartsList);
  }

  SaveUnRepairable(orderNumber, repairPartsList,queueComment): Observable<any>{
    
    return this.http.post(`${this.baseUrl}repair/SaveUnRepairable/${orderNumber}/${queueComment}`, repairPartsList);
  }

  getUBParcelStatus1(trackNumber): Promise<any> {
    let headers: HttpHeaders = new HttpHeaders({
      api_key: "72f01b7f-9bc6-41c9-ba1e-8d7481d2cfa7",
    });
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    httpOptions.headers = httpOptions.headers.append(
      "api_key",
      "72f01b7f-9bc6-41c9-ba1e-8d7481d2cfa7"
    );
    return new Promise((resolve, reject) => {
      this.http
        .get(
          "https://api.coureon.com/api/v1_6/tracking/" + trackNumber,
          httpOptions
        )
        .toPromise()
        .then((res) => {
          resolve(res);
        });
    });
  }

  //for saveing shipping abel

  UpdateShippingLabel(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })//,
    };
 //   return this.httpBGClient.post(`${this.baseUrl}netSuite/createUpdateRA`, JSON.stringify(data), httpOptions).toPromise();
    
    return this.httpBGClient.post(`${this.baseUrl}portal/repair/UpdateShippingLabel`, JSON.stringify(data),httpOptions);
  }
// Custom Decalartion Label  
  GenerateCustomDecalartionLabel(data: any): Observable<any> {
    return this.http.post(
      `${this.carrierAPI}api/carrier/GenerateCustomDecalartionLabel`,
      JSON.stringify(data)
    );
  }
  
  // Generate Service Notes 
  GenerateServiceNotes(data: any): Observable<any> {
   
    return this.http.post(
      `${this.carrierAPI}/api/carrier/HTMLToPdf`,
      JSON.stringify(data)
    );
  }
  // Load assing Partners 
  getPartners(): Observable<any> {
    return this.http.get(`${this.baseUrl}common/partners/assigned`);
  }
  getShipmentOrders(startIndex,endIndex,filterValue,brandCode): Observable<any> {
    return this.http.get(
      `${this.baseUrl}repair/shipment/${startIndex}/${endIndex - startIndex}/${filterValue}/${brandCode}`
    );
  }

  updateAWB(data:any):Observable<any>{
    return this.http.put(`${this.baseUrl}return`,JSON.stringify(data));
  }
  getQueueStripData(ReturnData: string):Observable<any>{
    return this.http.get(`${this.baseUrl}repair/getQueueStripData/${ReturnData}`);
  }
  //=================serivce for NS===========
  async CreateUpdateRAinNS(data: any): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })//,
    };
    return this.httpBGClient.post(`${this.baseUrl}netSuite/createUpdateRA`, JSON.stringify(data), httpOptions).toPromise();
}

UpdateRAinNSObservable(data: any): Observable<any> {
  debugger
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    })//,
  };
  return this.httpBGClient.post(`${this.baseUrl}netSuite/createUpdateRA`, JSON.stringify(data), httpOptions);
}

GetInboundSOData(SONumber: string): Observable<any> {
  return this.http.get(
    `${this.baseUrl}repair/GetInboundSOData/${SONumber}/${'ss'}`
  );
}

async updateRAItem(RAData: any): Promise<any> {
  return await this.http.post(`${this.baseUrl}netSuite/updateRALineItem`,JSON.stringify(RAData)).toPromise();
}
  //==========================================
  GetNetSuiteRAStatus(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}netSuite/updateRALineItem`,
      JSON.stringify(data)
    );
  }

  GetUPSPickUp(data: any): Observable<any> {

    return this.http.post(`${this.carrierAPI}api/Carrier/UPSCarrierPickUp/`, JSON.stringify(data));
  }

  saveUpsPickUp(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}portal/return/saveUpsPickUp`,
      JSON.stringify(data)
    );
  }


}