// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

  // export const environment = {
  //   production: false,
  //   scheme:'https',
  //   name:'UAT',
  //   alias:'lorex-ws-uat',
  //   host:'azurewebsites.net',
  //   logistics:'lorex-api-uat.azurewebsites.net/',
  //   cacheLocation:sessionStorage,
  //   node_api:'https://lorex-ws-uat.azurewebsites.net',
  //   web_api:'https://lorex-api-uat.azurewebsites.net',
  //   brand:'lrx',
  //   appInsights: {
  //    instrumentationKey: 'ca2008f0-3681-4568-9ea2-6e03d85d5866'
  //   }
  //   //logistics:'amer-carrier-test'+'.azurewebsites.net/'
  // };

  
  // export const environment = {
  //   production: false,
  //   scheme:'https',
  //   name:'UAT',
  //   alias:'naqel-ws-uat',
  //   host:'reverselogix.io',
  //   logistics:'naqel-api-uat.reverselogix.io/',
  //   cacheLocation:sessionStorage,
  //   node_api:'https://naqel-ws-uat.reverselogix.io',
  //   web_api:'https://naqel-api-uat.reverselogix.io',
  //   brand:'NQL',
  //   appInsights: {
  //    instrumentationKey: 'ca2008f0-3681-4568-9ea2-6e03d85d5866'
  //   }
  //   //logistics:'amer-carrier-test'+'.azurewebsites.net/'
  // };


/*   export const environment = {
    production: false,
    scheme:'https',
    name:'UAT',
    alias:'rl-actegy-ws-uat',
    host:'rl-actegy-uat.azurewebsites.net',
    logistics:'rl-actegy-api-qa.azurewebsites.net/',
    cacheLocation:localStorage,
    node_api:'https://rl-actegy-ws-uat.azurewebsites.net',
    web_api:'https://actegy-api-uat.reverselogix.io/',
    brand:'agy',
    appInsights: {
     instrumentationKey: 'ca2008f0-3681-4568-9ea2-6e03d85d5866'
    }
    //logistics:'amer-carrier-test'+'.azurewebsites.net/'
  }; */

/*   export const environment = {
    production: false,
    scheme:'https',
    name:'UAT',
    alias:'rl-wilsonpulse-ws-uat',
    host:'rl-wilsonpulse-uat.azurewebsites.net',
    logistics:'https://wilsonpulse-api-uat.reverselogix.io/',
    cacheLocation:localStorage,
    node_api:'https://wilsonpulse-ws-uat.reverselogix.io/',
    web_api:'https://wilsonpulse-api-uat.reverselogix.io/',
    brand:'EVOS',
    page:'forward-track-order-search',
    appInsights: {
     instrumentationKey: '7eb676ae-f919-4c6e-a95f-9caf9854abae'
    }
    //logistics:'amer-carrier-test'+'.azurewebsites.net/'
  }; */

/*   export const environment = {
    production: false,
    scheme:'https',
    name:'UAT',
    alias:'RL-OverClockers-UAT',
    host:'rl-actegy-uat.azurewebsites.net',
    logistics:'rl-actegy-api-qa.azurewebsites.net/',
    cacheLocation:localStorage,
    node_api:'https://overclockers-uat-ws.reverselogix.io',
    web_api:'https://OverClockers-uat-api.reverselogix.io/',
    brand:'ocfi',
    appInsights: {
     instrumentationKey: 'ca2008f0-3681-4568-9ea2-6e03d85d5866'
    }
    //logistics:'amer-carrier-test'+'.azurewebsites.net/'
  }; */

  // export const environment = {
  //   production: false,
  //   scheme:'https',
  //   name:'UAT',
  //   alias:'ekwb-uat-ws',
  //   host:'ekwb-uat.reverselogix.io',
  //   logistics:'https://ekwb-uat-api.reverselogix.io/',
  //   cacheLocation:localStorage,
  //   node_api:'https://ekwb-uat-ws.reverselogix.io/',
  //   web_api:'https://ekwb-uat-api.reverselogix.io',
  //   brand:'EKWB',
  //   appInsights: {
  //    instrumentationKey: '91ce9050-9c25-4dc4-887a-a25a8f6f2a6c'
  //   },
  //   clientbase: '',
  //   clientId: '',//'824706f9-4577-4407-aae2-68a55fa509ea'
  //   authority:'',

  //   //logistics:'amer-carrier-test'+'.azurewebsites.net/'
  // };

   export const environment = {
    production: true,
    scheme: 'https',
    name: 'UAT',
    alias: 'Zwift-UAT',
    host: 'reverselogix.io',
    logistics: 'https://zwift-api-uat.reverselogix.io/',
    cacheLocation: localStorage,
    node_api: 'https://zwift-ws-uat.reverselogix.io/',
    brand:'zw',
    web_api: 'https://zwift-api-uat.reverselogix.io/',
    clientbase: '',
    clientId: '',
    authority:'', 
    appInsights: {
       instrumentationKey: 'f0c6e353-c6f7-47a5-bfef-baf37354a5e9'
    }
  }; 

  //  export const environment = {
  //   production: true,
  //   scheme: 'https',
  //   name: 'uat',
  //   alias: 'colonybrands-ws-uat',
  //   host: 'reverselogix.io',
  //   logistics: 'https://colonybrands-api-uat.reverselogix.io',
  //   cacheLocation: localStorage,
  //   node_api: 'https://colonybrands-ws-uat.reverselogix.io',
  //   brand:'MW',
  //   web_api: 'https://colonybrands-api-uat.reverselogix.io',
  //   clientbase: 'https://colonybrands-uat.reverselogix.io/',
  //   clientId: 'bb49fd4f-a4fb-4a94-bbe5-c9eff5a549c2',//'824706f9-4577-4407-aae2-68a55fa509ea'
  //   authority:'https://sso.connect.pingidentity.com/sso/sp/initsso?saasid=5e29eb44-d64d-4ead-85b2-3187f4cf93cb&idpid=a9e41d47-dfcc-4868-b3f6-26bc24ea61bc', 
  //   appInsights: {
  //      instrumentationKey: '0b89b9ac-64e4-41b4-9918-681addcc3454'
  //   }
  // }; 

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  