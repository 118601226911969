
<ng-container  *ngIf="!loader">
    <router-outlet></router-outlet>
  </ng-container>
  
<div  *ngIf="loader">
    
  <div class="blocker">
    <div class="loader" id="loader-6">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
</div>