<div class="navbar">
    <div class="navbar-inner">
        <div class="navbar-container">
            <div class="navbar-header pull-left">
                <a href="javascript:void(0)" class="navbar-brand" style="cursor: initial!important;">
                    <div class="product__image_admin_logo">
                        <div class="product-thumbnail_admin_logo">
                            <div class="product-thumbnail__wrapper_admin_logo">
                                <img class="product-thumbnail__image_admin_logo" src="{{logo_nav}}" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="navbar-header pull-right">
                <div class="navbar-account">
                    <ul class="account-area">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li>
                            <a class="login-area dropdown-toggle" data-toggle="dropdown">
                                <div class="avatar" title="">
                                    <img [src]="LogInUserImageSrc" id="userProfileImage" alt="User Logo" />
                                </div>
                                <section style="line-height: 14px;">
                                    <span class="profile">
                                          <span><strong>{{LogInUserName}}</strong></span>
                                    <span style="font-size:12px;display: block;"><strong></strong>{{UserAssignedRole}}</span>
                                    </span>

                                </section>
                            </a>
                            <ul class="pull-right dropdown-menu dropdown-arrow dropdown-login-area">
                                <li class="username"><a>{{LogInUserName}}</a></li>
                                <li class="email"><a>{{LoggedInUserEmail}}</a></li>
                                <li>
                                    <div class="avatar-area" style="width:100%!important;">
                                        <img [src]="LogInUserImageSrc" class="avatar" alt="Awatar Logo" style="margin-bottom:40px!important;">
                                        <div class="browsefile" style="margin-bottom:10px;margin-left: 43px;">
                                            <span class="caption" style="width:100%!important;color:#FFFFFF!important;" (click)="triggerChangeImage();">
                                            <label *ngIf="LogInUserImageSrc !=='assets/img/noimage.png'" style="margin-bottom:0px !important;">Change Photo</label> <label *ngIf="LogInUserImageSrc =='assets/img/noimage.png'" style="margin-bottom:0px !important;">Upload Photo</label> 
                                              <input type="file" style="width:100%; display: none" id="file" accept="image/*"
                                                     name="UserImage" #UserImage="ngModel"
                                                     (change)="handleInputChange(UserImage, $event);"
                                                     [(ngModel)]="UserImageValue">
                                          </span>
                                        </div>
                                        <input type="hidden" id="currentUserID" />
                                    </div>
                                </li>
                                <li class="edit">
                                    <button class="btn btn-link text-center" (click)="ResetPassword()" style="display:block;font-size: 14px!important;">Reset Password</button>
                                </li>
                                <li class="text-align-left">
                                    <button class="btn btn-link" style="font-size: 14px!important;" routerLink="/auth/login" (click)="signOut()">
                                        Sign out
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #changePassword>
    <modal-header class="{{brand_code}}">
        <div class="modal-header" style="background-color: #A867DD!important;border-color: #A867DD!important;">
            <h4 class="modal-title">Reset Password</h4>
        </div>
    </modal-header>
    <modal-body>
        <form #passwordPopup="ngForm" name="passwordPopup">
            <div class="form-group" style="margin-top: 15px !important;">
                <label class="control-label col-sm-5 col-md-5 col-lg-5">New Password:<span class="red">*</span></label>
                <div class="col-sm-7 col-md-7 col-lg-7">
                    <input type="password" id="newPassword" onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" name="newPassword" #newPassword="ngModel" [(ngModel)]="cpUser.newPassword" class="form-control input-sm" required validateEqual="confirmpassword" reverse="true" maxlength="50" />
                    <div class="clearfix"></div>
                    <span class="red" *ngIf="!newPassword.valid && newPassword.touched">
                          <span *ngIf="!newPassword.valid && newPassword.errors.required">Password is required.</span>
                    <span *ngIf="!newPassword.valid && newPassword.value!='' && newPassword.errors.validateEqual">Password don't match.</span>
                    </span>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="form-group">
                <label class="control-label col-sm-5 col-md-5 col-lg-5">Confirm Password:<span class="red">*</span></label>
                <div class="col-sm-7 col-md-7 col-lg-7">
                    <input type="password" onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" name="confirmpassword" #confirmpassword="ngModel" [(ngModel)]="cpUser.confirmPassword" class="form-control input-sm" required maxlength="50" validateEqual="newPassword" reverse="false" />
                    <div class="clearfix"></div>
                    <span class="red" *ngIf="!confirmpassword.valid && confirmpassword.touched">
                          <span *ngIf="!confirmpassword.valid && confirmpassword.errors.required">Password is required.</span>
                    <span *ngIf="!confirmpassword.valid && confirmpassword.value!='' && confirmpassword.errors.validateEqual">Password don't match.</span>
                    </span>
                </div>
                <div class="clearfix"></div>
            </div>
            <div style="text-align: center;"><span class="red">{{alertLabel}}</span></div>
            <div class="modal-footer" style="margin-top: 15px !important;">
                <button type="reset" class="btn btn-danger" [disabled]="isSubmittedForPwdChange" data-dismiss="modal" (click)="Close()"><i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
                <button type="submit" class="btn btn-success" [disabled]="isSubmittedForPwdChange || passwordPopup.invalid" (click)="ChangePassword()"><i class="fa fa-check-circle" aria-hidden="true"></i> Submit</button>
            </div>
        </form>
    </modal-body>
</ng-template>
<message cssClass="modal-msg" #pop></message>