import { _repairsObject } from '../models/repair.model';
import { TypedJson } from 'src/app/app.util';
declare var $: any;
export class Notes {
  ID: number;
  NoteComment: string;
  AddFiles: file;
  ParentID: number;
  Source: string;
  internal_ref_number: string;
  constructor();
  constructor(_notes?: any) {
    this.ID = (_notes && _notes.ID) || 0;
    this.NoteComment = (_notes && _notes.NoteComment) || '';
    this.AddFiles = (_notes && _notes.AddFiles) || '';
    this.ParentID = (_notes && _notes.ParentID) || null;
    this.Source = (_notes && _notes.Source) || 'CP';
    this.internal_ref_number = (_notes && _notes.internal_ref_number) || '';
    this.AddFiles = _notes == undefined ? new file() : new file(_notes.file);
  }
}

export class GetNotes {
  ID: number;
  NoteComment: string;
  AddFiles: file;
  UserName: string;
  Source: string;
  Childs: Array<Childs>;
  CreatedOn: string;
  constructor();
  constructor(_getnotes?: any) {
    this.ID = (_getnotes && _getnotes.ID) || 0;
    this.NoteComment = (_getnotes && _getnotes.NoteComment) || '';
    this.AddFiles = (_getnotes && _getnotes.AddFiles) || '';
    this.UserName = (_getnotes && _getnotes.UserName) || 'Customer';
    this.Source = (_getnotes && _getnotes.Source) || 'CP';
    this.Childs =
      (_getnotes &&
        _getnotes.Childs &&
        $.map(TypedJson.parse<Childs>(_getnotes.Childs), function (v) {
          return new Childs(v);
        })) ||
      [];
    this.CreatedOn = (_getnotes && _getnotes.CreatedOn) || '';
    this.AddFiles =
      _getnotes == undefined ? new file() : new file(_getnotes.file);
  }
}
export class Childs {
  ID: number;
  NoteComment: string;
  AddFiles: string;
  UserName: string;
  CreatedOn: string;
  constructor();
  constructor(_getChilds: Childs);
  constructor(_getChilds?: any) {
    this.ID = (_getChilds && _getChilds.ID) || 0;
    this.NoteComment = (_getChilds && _getChilds.NoteComment) || '';
    this.AddFiles = (_getChilds && _getChilds.AddFiles) || '';
    this.UserName = (_getChilds && _getChilds.UserName) || '';
    this.CreatedOn = (_getChilds && _getChilds.CreatedOn) || '';
  }
}
export class file {
  type: string;
  url: string;
  name: string;
  constructor();
  constructor(_file: file);
  constructor(_file?: any) {
    this.type = (_file && _file.type) || '';
    this.url = (_file && _file.url) || '';
    this.name = (_file && _file.name) || '';
  }
}

export class NSAddress {
  zip: string;
  country: any;
  addressee: string;
  city: string;
  addr1: string;
  addr2: string;
  addrphone: string;
  override: boolean;
  addrtext: string;
  defaultshipping: string;
  defaultbilling: string;
  state: string;
  constructor();
  constructor(obj: file);
  constructor(obj?: any) {
    this.zip = (obj && obj.zip) || '';
    this.country = (obj && obj.country) || '';
    this.addressee = (obj && obj.addressee) || '';
    this.city = (obj && obj.city) || '';
    this.addr1 = (obj && obj.addr1) || '';
    this.addr2 = (obj && obj.addr2) || '';
    this.addrphone = (obj && obj.addrphone) || '';
    this.override = (obj && obj.override) || false;
    this.addrtext = (obj && obj.addrtext) || '';
    this.defaultbilling = (obj && obj.defaultbilling) || '';
    this.defaultshipping = (obj && obj.defaultshipping) || '';
    this.state = (obj && obj.state) || '';
  }
}
export class NSRAModel {
  type: string;
  transtype: string;
  servicetype: string;
  transname: string;
  nstransid: string;
  return_order_number: string;
  entity: string;
  customForm: any;
  otherRefNum: string;
  custbody_returntrack: string;
  subsidiary: string;
  memo: string;
  department: string;
  location: string;
  trandate: string;
  orderStatus: string;
  item: any[];
  custbodyrma_rep: string;
  billingaddress: NSAddress;
  shippingaddress: NSAddress;
  currency: any;
  email: string;
  custentity_2663_email_address_notif: string;
  altemail: string;
  shipaddress: string;
  billaddress: string;
  price: string;
  amount: string;
  warrantyType: string;
  paymentSource: string;
  paymentId: string;
  paymentOrderId: string;
  paymentProvider: string;
  actionType: string;
  nsServiceData: any;
  returnType: string;
  leadsource: string;
  custbody_customer_phone: string;
  custbody_customer_email: string;
  partner: string;
  return_header_id: number;
  createdfrom: string;

  Repairlocation: string;
  Returnlocation: string;
  Returnleadsource: string;
  Repairleadsource: string;
  custbody_suitesync_authorization_code: string;
  shipmethod: string;
  shippingcost: number;
  custbody_dhl_tracking: string;
  repairFee: string;
  repairFeeAmount: string;
  transTypePurpose: string;
  custbody_rl_ra_number: string;
  code: string;
  constructor();
  constructor(obj: NSRAModel);
  constructor(obj?: any) {
    this.returnType = (obj && obj.returnType) || '';
    this.nsServiceData = (obj && obj.nsServiceData) || null;
    this.actionType = (obj && obj.actionType) || '';
    this.paymentSource = (obj && obj.paymentSource) || '';
    this.paymentId = (obj && obj.paymentId) || '';
    this.paymentOrderId = (obj && obj.paymentOrderId) || '';
    this.paymentProvider = (obj && obj.paymentProvider) || '';
    this.warrantyType = (obj && obj.warrantyType) || '';
    this.type = (obj && obj.type) || '';
    this.transtype = (obj && obj.transtype) || '';
    this.servicetype = (obj && obj.servicetype) || '';
    this.transname = (obj && obj.transname) || '';
    this.nstransid = (obj && obj.nstransid) || '';
    this.return_order_number = (obj && obj.return_order_number) || '';
    this.entity = (obj && obj.entity) || '';
    this.customForm = (obj && obj.customForm) || '';
    this.otherRefNum = (obj && obj.otherRefNum) || '';
    this.custbody_returntrack = (obj && obj.custbody_returntrack) || '';
    this.subsidiary = (obj && obj.subsidiary) || '';
    this.memo = (obj && obj.memo) || '';
    this.department = (obj && obj.department) || '';
    this.location = (obj && obj.location) || '';
    this.trandate = (obj && obj.trandate) || '';
    this.orderStatus = (obj && obj.orderStatus) || '';
    this.item = (obj && obj.item) || [];
    this.billingaddress = (obj && obj.billingaddress) || new NSAddress();
    this.shippingaddress = (obj && obj.shippingaddress) || new NSAddress();
    this.currency = (obj && obj.currency) || '';
    this.email = (obj && obj.email) || '';
    this.custentity_2663_email_address_notif =
      (obj && obj.custentity_2663_email_address_notif) || '';
    this.altemail = (obj && obj.altemail) || '';
    this.shipaddress = (obj && obj.shipaddress) || '';
    this.billaddress = (obj && obj.billaddress) || '';
    this.price = (obj && obj.price) || '-1';
    this.amount = (obj && obj.amount) || '0';
    this.leadsource = (obj && obj.leadsource) || '';
    this.custbody_customer_phone = (obj && obj.custbody_customer_phone) || '';
    this.custbody_customer_email = (obj && obj.custbody_customer_email) || '';
    this.partner = (obj && obj.partner) || '';
    this.return_header_id = (obj && obj.return_header_id) || 0;
    this.custbodyrma_rep = (obj && obj.custbodyrma_rep) || '';
    this.createdfrom = (obj && obj.createdfrom) || '';

    this.Repairlocation = (obj && obj.Repairlocation) || '';
    this.Returnlocation = (obj && obj.Returnlocation) || '';
    this.Returnleadsource = (obj && obj.Returnleadsource) || '';
    this.Repairleadsource = (obj && obj.Repairleadsource) || '';
    this.custbody_suitesync_authorization_code =
      (obj && obj.custbody_suitesync_authorization_code) || '';
    this.shipmethod = (obj && obj.shipmethod) || '';
    this.shippingcost = (obj && obj.shippingcost) || 0;
    this.custbody_dhl_tracking = (obj && obj.custbody_dhl_tracking) || '';
    this.repairFee = (obj && obj.repairFee) || '';
    this.repairFeeAmount = (obj && obj.repairFeeAmount) || '';
    this.transTypePurpose = (obj && obj.transTypePurpose) || '';
    this.custbody_rl_ra_number = (obj && obj.custbody_rl_ra_number) || '';
    this.code = (obj && obj.code) || '';
  }
}

export class NSUpdateModel {
  type: string;
  transtype: string;
  nstransid: string;
  field: string;
  value: string;
  return_header_id: string;
  rmaNumber: string;
  code: string;
  constructor();
  constructor(obj: NSRAModel);
  constructor(obj?: any) {
    this.type = (obj && obj.type) || '';
    this.transtype = (obj && obj.transtype) || '';
    this.nstransid = (obj && obj.nstransid) || '';
    this.field = (obj && obj.field) || '';
    this.value = (obj && obj.value) || '';
    this.return_header_id = (obj && obj.return_header_id) || '';
    this.rmaNumber = (obj && obj.rmaNumber) || '';
    this.code = (obj && obj.code) || '';
  }
}

export class NSCaseModel {
  type: string;
  transtype: string;
  customForm: any;
  status: string;
  category: string;
  title: string;
  Subject: string;
  company: string;
  assigned: string;
  incomingmessage: string;
  outgoingmessage: string;
  internalonly: string;
  custevent_close_category: string;
  custevent_case_closure_summary: string;
  custevent_rmano: string;
  custevent_serial_lot_no: string;
  item: any;
  return_header_id: string;
  nstransid: string;
  custevent_defect_code: string;
  productName: string;
  code: string;
  constructor();
  constructor(obj: NSRAModel);
  constructor(obj?: any) {
    this.type = (obj && obj.type) || '';
    this.transtype = (obj && obj.transtype) || '';
    this.customForm = (obj && obj.customForm) || '';
    this.status = (obj && obj.status) || '';
    this.category = (obj && obj.category) || '';
    this.title = (obj && obj.title) || '';
    this.Subject = (obj && obj.Subject) || '';
    this.company = (obj && obj.company) || '';
    this.assigned = (obj && obj.assigned) || '';
    this.productName = (obj && obj.productName) || '';
    this.incomingmessage = (obj && obj.incomingmessage) || '';
    this.outgoingmessage = (obj && obj.outgoingmessage) || '';
    this.internalonly = (obj && obj.internalonly) || '';
    this.custevent_close_category = (obj && obj.custevent_close_category) || '';
    this.custevent_case_closure_summary =
      (obj && obj.custevent_case_closure_summary) || '';
    this.custevent_rmano = (obj && obj.custevent_rmano) || '';
    this.custevent_serial_lot_no = (obj && obj.custevent_serial_lot_no) || '';
    this.item = (obj && obj.item) || '';
    this.return_header_id = (obj && obj.return_header_id) || '';
    this.nstransid = (obj && obj.nstransid) || '';
    this.custevent_defect_code = (obj && obj.custevent_defect_code) || '';
    this.productName = (obj && obj.productName) || '';
    this.code = (obj && obj.code) || '';
  }
}

export class UpdateRAItem {
  type: string;
  RAId: string;
  transname: string;
  oldItem: RAItem;
  newItem: RAItem;
  constructor();
  constructor(obj: UpdateRAItem);
  constructor(obj?: any) {
    this.type = (obj && obj.type) || '';
    this.RAId = (obj && obj.RAId) || '';
    this.transname = (obj && obj.transname) || '';
    this.oldItem = (obj && obj.oldItem) || new RAItem();
    this.newItem = (obj && obj.newItem) || new RAItem();
  }
}
export class RAItem {
  id: string;
  quantity: number;
  memo: string;
  rmaNumber: string;
  productType: string;
  price: string;
  amount: string;
  grossAmount: string;
  itemType: string;
  custcol_bb1_rareason: string;
  location: string;
  VAT: string;
  return_header_id: string;
  return_number: string;
  custbody_rl_ra_number: string;
  custbody_rl_ra_type: string;
  isInWarranty: boolean;
  constructor();
  constructor(obj: RAItem);
  constructor(obj?: any) {
    this.id = (obj && obj.id) || '';
    this.quantity = (obj && obj.quantity) || '';
    this.memo = (obj && obj.memo) || '';
    this.rmaNumber = (obj && obj.rmaNumber) || '';
    this.productType = (obj && obj.productType) || '';
    this.price = (obj && obj.price) || '0';
    this.grossAmount = (obj && obj.grossAmount) || '0';
    this.itemType = (obj && obj.itemType) || '';
    this.custcol_bb1_rareason = (obj && obj.custcol_bb1_rareason) || '';
    this.location = (obj && obj.location) || '';
    this.location = (obj && obj.VAT) || '';
    this.amount = (obj && obj.amount) || '';
    this.return_header_id = (obj && obj.return_header_id) || '';
    this.custbody_rl_ra_number = (obj && obj.custbody_rl_ra_number) || '';
    this.isInWarranty = (obj && obj.isWarranty) || true;
    this.custbody_rl_ra_type = (obj && obj.custbody_rl_ra_type) || '';
  }
}
