import { Component, OnInit } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { Menu, MenuCollection } from './menu.model';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/services/global-service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  Menus: Menu[];
  constructor(private _sidebar: SidebarService, private _router: Router,
    private _globService: GlobalService,
    private _menu: SidebarService) { }
  get _route(): string {
    return this._router.url;
  }
  get _proute(): string {
    var _thisRoute = this._menu.menus.filter(f => f.routeKey === this._router.url)[0];
    return _thisRoute ? _thisRoute.parent : [];
  }

  activeChild(Child, index, array) {
    return this._route.indexOf(Child) > -1 ? true : false;
  }
  SetModuleCode(module_code) {

    this._globService.utilities.storage.set('module_code', module_code);
  }

  RedirectToPage(url, module_code, isParent) {
    if(isParent)
    {
      $(".childUL").css("display", "none");
      $(".open").removeClass("open");
    }
    

    this._globService.utilities.storage.set('module_code', module_code);
    this._router.navigate([url])
  }

  ngOnInit() {

    if (this._sidebar.Menus) {
      this.Menus = this._sidebar.Menus;
      return;
    }
    this._sidebar.getDyMenu()
      .subscribe(
        (_Menus: any) => {
          // 
          this.Menus = this._sidebar.Menus = _Menus;
        });
  }
  trackByFn(index, item) {
    return item.ID
  }
}
