import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
declare var $: any;
let requests: number = 0;
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    requests++;
    // Get Page Height
    var body = document.body,
      html = document.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);

    $(".blocker").removeClass("blocker-inactive");
    // Set Page Height
    $(".blocker").css("height", height + 'px');

    // $("body").addClass("no-scroll");
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {

          if (error instanceof HttpErrorResponse && error.status === 401) {
            this._router.navigate(['/auth/login']);
            // this._router.navigate(['/index'])
            // return this.handle401Error(authReq, next);
          }
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          return throwError(errorMessage);
        }),
        finalize(() => {
          requests--;
          if (requests <= 0) {
            $(".blocker").addClass("blocker-inactive");

            // $("body").removeClass("no-scroll");

          }

        }
        )
      )
  }


}