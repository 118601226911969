import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { User } from '../../shared/common.model';
import { message } from '../../controls/pop';
import { Util } from 'src/app/app.util';
import { UserService } from 'src/app/back-office/user/user.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/services/global-service';
declare var $: any;
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
  providers: [UserService]
})
export class PasswordComponent implements OnInit {
  @ViewChild('pop') _popup: message;
  model: User;
  brand_code:string;
  forgetPassword: boolean = false;
  userName: string ="";
  logo_login: any = '';
  favicon_icon:any='';


  constructor(private authService: AuthService,private _util: Util,
    private userService: UserService,
    private _glob: GlobalService,
    private router: Router) {
    this.model = new User();
    this.userService.getBrand().subscribe(response => {
      this._glob.utilities.storage.set('logo', response[0].login_logo);
      this._glob.utilities.storage.set('favicon_icon', response[0].favicon_icon);
      this.favicon_icon=this._glob.utilities.storage.get('favicon_icon');
      this.logo_login = this._glob.utilities.storage.get('logo');
      if(response.length > 0){
        this.brand_code = response[0].PartnerCode;
      }
      else{
        this.brand_code = 'coo';
      }      
       $("#appFavicon").attr('href', this.favicon_icon);
      
      sessionStorage.setItem('brand_code',this.brand_code);
    });
  }
  ngOnInit() {
    
  }

  onForgotPassword() {
    this.model.userName=this.userName;
    this.model.brand_url = location.origin;
  
    this.authService.GetPasswordForUserName(this.model)
      .subscribe((data) => {
        if (data.success == false) {
          this._util.error(data.message, "Alert");
        }
        else {
          var me = this;
          this._util.success('Mail has been sent to registered email.', '');
          this.router.navigate(['/auth/login']);
        }
      });
  }
}
