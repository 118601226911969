import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{AuthenticationComponent} from './authentication.component'


const routes: Routes = [
  {
    path: '', 
      component: AuthenticationComponent,
      children: [       
        { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)}, 
        { path: 'password', loadChildren: () => import('./password/password.module').then(m => m.PasswordModule)},
        { path: 'reset-password', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)},
        { path: 'forget-password', loadChildren: () => import('./forget-password/forget-password.module').then(m => m.forgetPasswordModule)},


      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
