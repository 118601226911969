import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalVariableService } from '../../shared/globalvariable.service';
import { CommonService } from '../../shared/common.service';
import { User } from '../../shared/common.model';
import { BsModalComponent } from 'ng2-bs3-modal';
import { AuthService } from '../../authentication/auth.service';
import { Util } from '../../app.util';
import { UserService } from '../../back-office/user/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalService } from 'src/app/shared/services/global-service';
import { SidebarService } from '../../back-office/sidebar/sidebar.service';
declare var $: any;
@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
    providers: [AuthService, UserService]
})
export class NavigationComponent implements OnInit {
    //Owner: Tenant;
    modalRef: BsModalRef;
    errorMessage: string;
    message: string;
    title: string;
    LogInUserName: string;
    LoggedInUserEmail: string;
    LogInUserPartnerName: string;
    LoginUserPartnerType: string;
    UserAssignLocation: string;
    UserAssignedRole: string;
    brand_code: string
    //LoggedIn
    LogInUserImageSrc: string = "assets/img/noimage.png";
    TrialMessage: string;
    IsShowTrialMessage: boolean = false;
    IsDashBordShow: boolean = false;
    ipAddress: string = "NA";
    logo_nav:string='assets/img/logo-nav.png';
    @ViewChild('changePassword', { static: true }) pwdmodal: ElementRef;
    @ViewChild('modal1') modal: BsModalComponent;

    lastPing?: Date = null;
    toLogoutMessage: boolean = false;
    PartnerInfo: any;
    UserInfo = { UserImage: '', UserId: 0 };
    //private idle: Idle, private keepalive: Keepalive
    roleCount: number;
    AssignedRoles = [];
    havePartner: boolean = false;
    chatbar: boolean = false;
    PortalImageSrc: string;
    canChangeTheme: boolean = false;
    _subscription: Subscription ;
    _isRoleFound:boolean = false;

    constructor(private _global: GlobalVariableService
        , private commonser: CommonService
        , private authService: AuthService
        , private _util: Util
        , private userService: UserService,
        private modelService: BsModalService,
        private _glob: GlobalService,
        private _sidebar: SidebarService,

    ) {


        this.brand_code = this._glob.utilities.storage.get('brand_code');
        this.logo_nav=this._glob.utilities.storage.get('backoffice_logo');
        
        if (!this.brand_code) {
            this.userService.getBrand().subscribe(response => {
                this._glob.utilities.storage.set('logo', response[0].login_logo);
                this._glob.utilities.storage.set('favicon_icon', response[0].favicon_icon);
                this._glob.utilities.storage.set('backoffice_logo', response[0].backoffice_logo);
                this.logo_nav=this._glob.utilities.storage.get('backoffice_logo');
                if (response.length > 0) {
                    this.brand_code = response[0].PartnerCode;
                }
                else {
                    this.brand_code = 'coo';
                }
            });
        }

        this.PartnerInfo = this._glob.utilities.storage.get('partnerinfo');



        if (!this.PartnerInfo) {
            this.commonser.getDefaultPartner().subscribe(
                _res => {
                    this._glob.utilities.storage.set('partnerinfo', JSON.stringify(_res));
                    this.bindDefaultPartner();
                },
                error => this.errorMessage = <any>error);
        }
        else {
            this.bindDefaultPartner();
        }
    }
    bindDefaultPartner() {

        this.havePartner = true;
        this.PartnerInfo = JSON.parse(this._glob.utilities.storage.get('partnerinfo'));
        var data = this.PartnerInfo[0];
        this.UserInfo.UserImage = data.UserImage;
        this.UserInfo.UserId = data.UserId;
        this.LogInUserImageSrc = this.UserInfo.UserImage == null || this.UserInfo.UserImage == "" ? "assets/img/noimage.png" : this.UserInfo.UserImage;
        this.PortalImageSrc = data.PortalIcon;
        if (data.DashBoardMasterID == 6)
            this.IsDashBordShow = false;
        else
            this.IsDashBordShow = true;

        var role = this.PartnerInfo[0]['RoleName'];
        this.AssignedRoles.push(role);
        this.roleCount = 1;
        this.LogInUserPartnerName = JSON.stringify(data.LogInUserPartnerName).replace(/\"/g, "");
        this.LoginUserPartnerType = data.TypeCode;
        this.LogInUserName = data.LogInUserName;
        this.LoggedInUserEmail = JSON.stringify(data.LogInUserEmail).replace(/\"/g, "");
        var UserAssignedRole = JSON.stringify(data.RoleName).replace(/\"/g, "");
        if (UserAssignedRole.indexOf('Admin') > -1)
            this.canChangeTheme = true;
        if (UserAssignedRole.indexOf(',') > -1) {
            var newuser = UserAssignedRole.split(',');
            this.UserAssignedRole = newuser[0] + ',...';
        }
        else {
            this.UserAssignedRole = UserAssignedRole;
        }
    }
    ngOnInit() {
        $.InitiateSideMenu();

        this.ipAddress = localStorage.getItem('ipaddress');
        //===============Check of Role==========
        this.getRole()
    }
    // changed by vipin 4March 2022
    async getRole(){
        // //var role = await this._sidebar.getUserRoleSync()
        // if(role[0]['UserRole's]==null){
        //     this._isRoleFound = true;
        // } else {
        //     this._isRoleFound = false;
        // }
    }

    signOut(): void {
        let aUserId = Number(sessionStorage.getItem('authUserId'));    
        this.authService.SaveUserEventsLog(aUserId, this.UserInfo.UserId, 'Index', sessionStorage.getItem('navurl'), 'Logout', this.ipAddress);
        this.authService.SaveAuthenticateUserLog(aUserId, this.ipAddress, 'BO','logout', 0, '').subscribe(
        r => {});
        this.authService.logout();
    }
    triggerChangeImage() {
        $("#file").trigger('click');
    }
    UserImageValue: any;
    UserImage: FileList;
    handleInputChange(ctrl, e) {
        var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        if (file.length > 0) {

            var pattern = /image-*/;
            var reader = new FileReader();
            if (!file[0].type.match(pattern)) {
                this._util.error('invalid format', "Alert");
                return;
            }

            this.UserImage = file;
            let formData: FormData = new FormData();
            for (let i = 0; i < this.UserImage.length; i++) {
                formData.append('UserImage', this.UserImage[0]);
            }
            formData.append('UserID', this.UserInfo.UserId.toString());
            this.commonser.UploadUserImage(formData).subscribe(data => {
                if (data.result == 'Success') {
                    this.LogInUserImageSrc = data.url;
                }

            });
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(file[0]);
            localStorage.removeItem('msglog');
            let aUserId = Number(sessionStorage.getItem('authUserId'));  
              
            this.authService.SaveUserEventsLog(aUserId, this.UserInfo.UserId, sessionStorage.getItem('navmenu'), sessionStorage.getItem('navurl'), 'Changed profile picture', this.ipAddress);
        }
    }
    _handleReaderLoaded(e) {
        var reader = e.target;
        this.LogInUserImageSrc = reader.result;
    }

    cpUser: User = new User();
    ResetPassword() {
        this.cpUser = new User();

        this.modalRef = this.modelService.show(this.pwdmodal, { backdrop: 'static' });
    }
    Close() {
        this.cpUser = new User();
        this.modalRef.hide();
    }
    isInvalidpassword: boolean = false;
    ValidateOldPassword(toSend) {
        if (toSend) {

        }
    }
    OnChange() {
        this.isInvalidpassword = false;

    }
    alertLabel: string = '';

    isSubmittedForPwdChange: boolean = false;
    ChangePassword() {
        if (this.cpUser.newPassword != this.cpUser.confirmPassword) {
            this._util.warning('New password and Confirm password does not match.', "warning");
            return;
        }
        if (!this.isValidPassword(this.cpUser.newPassword)) {
            this._util.warning('Password must be Uppercase, Lowercase, special character,number and limit 6 to 30.', "warning");
            return;
        }
        this.isSubmittedForPwdChange = true;
        this.authService.userChangePassword(this.cpUser).subscribe(data => {
            if (data.message.toLowerCase() == "success") {
                this.alertLabel = '';
                this._util.success('Password has been changed successfully.', "Success", "Success");
                this.modalRef.hide();
                localStorage.removeItem('msglog');
                let aUserId = Number(sessionStorage.getItem('authUserId'));    
                this.authService.SaveUserEventsLog(aUserId, this.UserInfo.UserId, sessionStorage.getItem('navmenu'), sessionStorage.getItem('navurl'), 'Changed password', this.ipAddress);
            }
            else {
                this.alertLabel = data.message;
            }
            this.isSubmittedForPwdChange = false;
        }, error => this._util.error(error, 'error'));
    }

    open() {
        this.modal.open();
    }
    isValidPassword(password) {
        var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,30})");
        return regex.test(password);
    }
}

