import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WINDOW } from './app.window';
import { BackOfficeSettingService } from './shared/services/BackOfficeSetting.Service';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from './shared/services/global-service';
declare var $: any;

@Injectable()
export class Util {
  RepairSetting: pageSettingBOCPModel;
  ReturnSetting: pageSettingBOCPModel;
  currentStepObservable = new BehaviorSubject(1);
  currentHeaderObservable = new BehaviorSubject('RMA Request');
  lblHeaderLabelObservable = new BehaviorSubject<string>('');

  constructor(
    private toastr: ToastrService,
    @Inject(WINDOW) private window: Window,
    private pageSettingService: BackOfficeSettingService,
    private _glob: GlobalService
  ) {}
  error(message, title, ToastConfig?) {
    this.toastr.error(message, '', ToastConfig);
  }
  success(message, title, ToastConfig?) {
    this.toastr.success(message, '', ToastConfig);
    this.getToastrMessage(message);
  }
  info(message, title, ToastConfig?) {
    this.toastr.info(message, '', ToastConfig);
  }
  warning(message, title, ToastConfig?) {
    this.toastr.warning(message, '', ToastConfig);
  }

  fileSizeValidate(fileSize, maxSize = 0, minSize = 0) {
    if (maxSize == 0) maxSize = 5;
    if (minSize == 0) minSize = 0;
    let fileSizeInKB = Math.round(fileSize / 1024);
    if (fileSizeInKB > maxSize * 1024) return true;
    else return false;
  }
  toDecimal(number, point) {
    return parseFloat(parseFloat(number).toFixed(point));
  }
  getAuthorizationUser(): string {
    if (localStorage.getItem(this.getHost()))
      return JSON.stringify(
        JSON.parse(atob(localStorage.getItem(this.getHost()))).user
      );
    else return null;
  }
  getHost(): string {
    return this.window.location.hostname;
  }
  getUBStatuses(): any {
    let statusList = [];
    statusList.push({ ubstatus: 'NOT_AVAILABLE', instatus: 'Initiated' });
    statusList.push({ ubstatus: 'LABEL_PRINTED', instatus: 'Initiated' });
    statusList.push({ ubstatus: 'IN_TRANSIT', instatus: 'In-Transit' });
    statusList.push({ ubstatus: 'HANDOVER', instatus: 'In-Transit' });
    statusList.push({ ubstatus: 'WAREHOUSE', instatus: 'In-Transit' });
    statusList.push({ ubstatus: 'IN_DELIVERY', instatus: 'In-Transit' });
    statusList.push({
      ubstatus: 'DELIVERY_FAILED',
      instatus: 'Delivery Failed',
    });
    statusList.push({ ubstatus: 'RECEIVER_MOVED', instatus: 'In-Transit' });
    statusList.push({ ubstatus: 'NO_HANDOVER', instatus: 'Delivery Failed' });
    statusList.push({ ubstatus: 'DELIVERED', instatus: 'Delivered' });
    statusList.push({ ubstatus: 'CANCELLED', instatus: 'Delivery Failed' });
    statusList.push({ ubstatus: 'SHIPPING_GENERATED', instatus: 'Initiated' });
    statusList.push({ ubstatus: 'PICKUP_SCHEDULED', instatus: 'Initiated' });
    statusList.push({
      ubstatus: 'PARCEL_DELIVERED_AT_COUREON_HUB',
      instatus: 'In-Transit',
    });
    statusList.push({ ubstatus: 'PARCEL_PROCESSED', instatus: 'In-Transit' });
    statusList.push({ ubstatus: 'PARCEL_TRANSIT', instatus: 'In-Transit' });
    statusList.push({
      ubstatus: 'TRANSIT_TO_DESTINATION_COUNTRY',
      instatus: 'In-Transit',
    });
    statusList.push({
      ubstatus: 'PARCEL_AT_CARRIER_NATIONAL_HUB',
      instatus: 'In-Transit',
    });
    statusList.push({ ubstatus: 'PARCEL_IN_DELIVERY', instatus: 'In-Transit' });
    statusList.push({ ubstatus: 'MISSED_DELIVERY', instatus: 'In-Transit' });
    statusList.push({ ubstatus: 'PARCEL_STORED', instatus: 'Delivery Failed' });
    statusList.push({ ubstatus: 'DELIVERY_PLANNED', instatus: 'In-Transit' });
    statusList.push({ ubstatus: 'PARCEL_DELIVERED', instatus: 'Delivered' });
    statusList.push({ ubstatus: 'UNDELIVERABLE', instatus: 'Delivery Failed' });
    statusList.push({ ubstatus: 'IGNORE', instatus: 'Delivery Failed' });
    statusList.push({
      ubstatus: 'WAREHOUSE_INBOUND_SCAN',
      instatus: 'In-Transit',
    });
    statusList.push({
      ubstatus: 'WAREHOUSE_OUTBOUND_SCAN',
      instatus: 'In-Transit',
    });
    return statusList;
  }

  getUPSStatuses(): any {
    let upsstatusList = [];
    upsstatusList.push({ upsstatus: 'I', instatus: 'In-Transit' });
    upsstatusList.push({ upsstatus: 'P', instatus: 'Initiated' });
    upsstatusList.push({ upsstatus: 'M', instatus: 'Initiated' });
    upsstatusList.push({ upsstatus: 'X', instatus: 'Delivery Failed' });
    upsstatusList.push({ upsstatus: 'D', instatus: 'Delivered' });
    return upsstatusList;
  }
  getToastrMessage(message): void {
    localStorage.setItem('msglog', message);
  }

  async getConfigSetting() {
    var isPageSetting = sessionStorage.getItem('isPageSetting');

    if (!isPageSetting) {
      return new Promise<any>(async (resolve) => {
        await this.pageSettingService
          .getBOPageSettingAsync('brand', 'language')
          .then(
            (data) => {
              debugger;
              this.RepairSetting = new pageSettingBOCPModel();
              this.ReturnSetting = new pageSettingBOCPModel();

              var pageSettings = JSON.parse(data.pagesetting[0].JSONValue);
              if (
                pageSettings.filter((x) => x.FieldName == 'Return')[0]['Active']
              ) {
                var returnConfig = pageSettings.filter(
                  (x) => x.FieldName == 'Return'
                )[0]['ControlList'];
                this.ReturnSetting.isRtv = returnConfig.filter(
                  (x) => x.FieldName == 'RTV'
                )[0]['Active'];
                if (this.ReturnSetting.isRtv) {
                  var rtvReturnControls = returnConfig.filter(
                    (x) => x.FieldName == 'RTV'
                  )[0]['ControlList'];
                  this.ReturnSetting.isGenerateInvoice =
                    rtvReturnControls.filter(
                      (x) => x.FieldName == 'Generate_Invoice'
                    )[0].Active || false;
                  this.ReturnSetting.isGenerateShipment =
                    rtvReturnControls.filter(
                      (x) => x.FieldName == 'Generate_Shipping_Label'
                    )[0].Active || false;
                }
              }

              this.RepairSetting.isRepair = pageSettings.filter(
                (x) => x.FieldName == 'Repair'
              )[0]['Active'];
              this.RepairSetting.isReturn = pageSettings.filter(
                (x) => x.FieldName == 'Return'
              )[0]['Active'];
              var returnSetting = pageSettings.filter(
                (x) => x.FieldName == 'Return'
              )[0]['ControlList'];
              this.ReturnSetting.isReturn_Processing = returnSetting.filter(
                (x) => x.FieldName == 'Returns_Processing'
              )[0]['Active'];
              this.ReturnSetting.guidedSearch =
                returnSetting
                  ?.filter((x) => x.FieldName == 'RMA_Generation')?.[0]
                  ?.ControlList?.filter(
                    (x) => x.FieldName == 'Guided_Search'
                  )?.[0]?.Active || false;
              debugger;
              if (
                pageSettings.filter((x) => x.FieldName == 'Repair')[0]['Active']
              ) {
                var repairConfig = pageSettings.filter(
                  (x) => x.FieldName == 'Repair'
                )[0]['ControlList'];
                this.RepairSetting.isRepair = pageSettings.filter(
                  (x) => x.FieldName == 'Repair'
                )[0]['Active'];
                this.RepairSetting.isReturn = pageSettings.filter(
                  (x) => x.FieldName == 'Return'
                )[0]['Active']; //true

                this.RepairSetting.isRtv = repairConfig.filter(
                  (x) => x.FieldName == 'RTV'
                )[0]['Active'];
                //Added by Ritesh / 22/12/2022
                this.RepairSetting.isUnRepairable = repairConfig.filter(
                  (x) => x.FieldName == 'Un-Repairable'
                )[0]['Active'];
                if (this.RepairSetting.isRtv) {
                  var rtvControls = repairConfig.filter(
                    (x) => x.FieldName == 'RTV'
                  )[0]['ControlList'];
                  this.RepairSetting.isGenerateInvoice =
                    rtvControls.filter(
                      (x) => x.FieldName == 'Generate_Invoice'
                    )[0].Active || false;
                  this.RepairSetting.isGenerateShipment =
                    rtvControls.filter(
                      (x) => x.FieldName == 'Generate_Shipping_Label'
                    )[0].Active || false;
                }

                if (
                  repairConfig.filter(
                    (x) => x.FieldName == 'Repair_Processing'
                  )[0]['Active']
                ) {
                  var repair = repairConfig.filter(
                    (x) => x.FieldName == 'Repair_Processing'
                  )[0]['ControlList'];
                  this.RepairSetting.Files =
                    repair.filter((x) => x.FieldName == 'Files')[0].Active ||
                    false;
                  this.RepairSetting.sendQuot =
                    repair.filter((x) => x.FieldName == 'Send_Quot')[0]
                      .Active || false;
                  this.RepairSetting.InternalNote =
                    repair.filter((x) => x.FieldName == 'Internal_Notes')[0]
                      .Active || false;
                  this.RepairSetting.ExchangeProduct =
                    repair.filter((x) => x.FieldName == 'Exchange_Product')[0]
                      .Active || false;

                  this.RepairSetting.guidedSearch =
                    repairConfig
                      .filter((x) => x.FieldName == 'RMA_Generation')[0]
                      .ControlList.filter(
                        (x) => x.FieldName == 'Guided_Search'
                      )[0].Active || false;
                  this.RepairSetting.orderSearch =
                    repairConfig
                      .filter((x) => x.FieldName == 'RMA_Generation')[0]
                      .ControlList.filter(
                        (x) => x.FieldName == 'Advanced_Search'
                      )[0].Active || false;
                  this.RepairSetting.Advanced_Search =
                    repairConfig
                      .filter((x) => x.FieldName == 'RMA_Generation')[0]
                      .ControlList.filter(
                        (x) => x.FieldName == 'Advanced_Search'
                      )[0].Active || false;

                  debugger;
                  this.RepairSetting.Advanced_Search =
                    repairConfig
                      .filter((x) => x.FieldName == 'RMA_Generation')[0]
                      .ControlList.filter(
                        (x) => x.FieldName == 'Advanced_Search'
                      )[0].Active || false;
                  this.RepairSetting.Pickup = repairConfig
                    .filter((x) => x.FieldName == 'RMA_Generation')[0]
                    .ControlList.filter((x) => x.FieldName == 'Pick_Up')[0];
                }

                if (
                  repairConfig.filter(
                    (x) => x.FieldName == 'RMA_Generation'
                  )[0]['Active']
                ) {
                  var rmaGeneration = repairConfig.filter(
                    (x) => x.FieldName == 'RMA_Generation'
                  )[0]['ControlList'];
                  this.RepairSetting.Discussion =
                    rmaGeneration.filter((x) => x.FieldName == 'Discussion')[0]
                      .Active || false;
                  this.RepairSetting.Reverse_Tracking_Advertisements =
                    rmaGeneration.filter(
                      (x) => x.FieldName == 'Reverse_Tracking_Advertisements'
                    )[0].Active || false;
                  this.RepairSetting.ispaymentConfiguration =
                    rmaGeneration.filter(
                      (x) => x.FieldName == 'PaymentConfiguration'
                    )[0].Active || false;

                  debugger;
                  this.RepairSetting.isgdpr =
                    rmaGeneration.filter((x) => x.FieldName == 'GDPR')[0]
                      .Active || false;
                  this.RepairSetting.isShipping_Instruction =
                    rmaGeneration.filter(
                      (x) => x.FieldName == 'Shipping_Instructions'
                    )[0].Active || false;
                  this.RepairSetting.isFixedAmount =
                    rmaGeneration
                      .filter((x) => x.FieldName == 'PaymentConfiguration')[0]
                      .ControlList.filter(
                        (x) => x.FieldName == 'FixedAmount'
                      )[0].Active || false;
                  this.RepairSetting.isApproval =
                    rmaGeneration.filter((x) => x.FieldName == 'Approval')[0]
                      .Active || false;
                  this.RepairSetting.isApproveRejectPermission =
                    rmaGeneration
                      .filter((x) => x.FieldName == 'Approval')[0]
                      .ControlList.filter(
                        (x) => x.FieldName == 'Approve_Reject'
                      )[0].Active || false;
                  this.RepairSetting.isApproveRejectComment =
                    rmaGeneration
                      .filter((x) => x.FieldName == 'Approval')[0]
                      .ControlList.filter(
                        (x) => x.FieldName == 'Approval_Rejection_Comments'
                      )[0].Active || false;
                  this.RepairSetting.serialNumberSearch =
                    rmaGeneration.filter(
                      (x) => x.FieldName == 'Serial_Number_Search'
                    )[0].Active || false;
                }
                // if(repairConfig.filter(x => x.FieldName == "Integration")[0]["Active"]){
                //     var updateProcessing = repairConfig.filter(x => x.FieldName == "Integration")[0].ControlList
                //     if(updateProcessing.filter(x => x.FieldName == "updateProcessedData")[0]["Active"]){
                //         var processingControls = updateProcessing.filter(x => x.FieldName == "updateProcessedData")[0]['ControlList']
                //         this.RepairSetting.netSuiteRAGeneration = processingControls.filter(x=>x.FieldName=='RAGeneration')[0].Active || false;
                //         this.RepairSetting.netSuiteSOGeneration = processingControls.filter(x=>x.FieldName=='SOGeneration')[0].Active || false;;
                //         this.RepairSetting.netSuiteCSGeneration = processingControls.filter(x=>x.FieldName=='CSGeneration')[0].Active || false;;
                //         this.RepairSetting.netSuiteInvoiceGeneration = processingControls.filter(x=>x.FieldName=='InvoiceGeneration')[0].Active || false;;
                //         this.RepairSetting.netSuiteOrderFetch = processingControls.filter(x=>x.FieldName=='OrderFetch')[0].Active || false;;
                //     }
                // }
                if (data.repairSetting.length > 0) {
                  this.RepairSetting.netSuiteRAGeneration =
                    data.repairSetting.find((x) => x.byname == 'RACreation') &&
                    data.repairSetting.find((x) => x.byname == 'RACreation')[
                      'value'
                    ];
                  this.RepairSetting.netSuiteSOGeneration =
                    data.repairSetting.find((x) => x.byname == 'SOCreation') &&
                    data.repairSetting.find((x) => x.byname == 'SOCreation')[
                      'value'
                    ];
                  this.RepairSetting.netSuiteCSGeneration =
                    data.repairSetting.find(
                      (x) => x.byname == 'CaseCreation'
                    ) &&
                    data.repairSetting.find((x) => x.byname == 'CaseCreation')[
                      'value'
                    ];
                  this.RepairSetting.netSuiteInvoiceGeneration =
                    data.repairSetting.find((x) => x.byname == 'InvCreation') &&
                    data.repairSetting.find((x) => x.byname == 'InvCreation')[
                      'value'
                    ];
                  this.RepairSetting.netSuiteOrderFetch =
                    data.repairSetting.find((x) => x.byname == 'OrderFetch') &&
                    data.repairSetting.find((x) => x.byname == 'OrderFetch')[
                      'value'
                    ];
                }
              }
              if (data.returnSetting.length > 0) {
                this.ReturnSetting.netSuiteRAGeneration =
                  data.returnSetting.find((x) => x.byname == 'RACreation') &&
                  data.returnSetting.find((x) => x.byname == 'RACreation')[
                    'value'
                  ];
                this.ReturnSetting.netSuiteSOGeneration =
                  data.returnSetting.find((x) => x.byname == 'SOCreation') &&
                  data.returnSetting.find((x) => x.byname == 'SOCreation')[
                    'value'
                  ];
                this.ReturnSetting.netSuiteCSGeneration =
                  data.returnSetting.find((x) => x.byname == 'CaseCreation') &&
                  data.returnSetting.find((x) => x.byname == 'CaseCreation')[
                    'value'
                  ];
                this.ReturnSetting.netSuiteInvoiceGeneration =
                  data.returnSetting.find((x) => x.byname == 'InvCreation') &&
                  data.returnSetting.find((x) => x.byname == 'InvCreation')[
                    'value'
                  ];
                this.ReturnSetting.netSuiteOrderFetch =
                  data.returnSetting.find((x) => x.byname == 'OrderFetch') &&
                  data.returnSetting.find((x) => x.byname == 'OrderFetch')[
                    'value'
                  ];
              }
              //   if(pageSettings.filter(x => x.FieldName == "Return")[0]["Active"]){
              //     var returnConfig = pageSettings.filter(x => x.FieldName == "Return")[0]['ControlList']
              //     this.ReturnSetting.isReturn = pageSettings.filter(x => x.FieldName == "Return")[0]["Active"];//true
              //     this.RepairSetting.isReturn = pageSettings.filter(x => x.FieldName == "Return")[0]["Active"];//true
              //     if(returnConfig.filter(x => x.FieldName == "Integration")[0]["Active"]){
              //         var updateProcessing = returnConfig.filter(x => x.FieldName == "Integration")[0].ControlList
              //         if(updateProcessing.filter(x => x.FieldName == "updateProcessedData")[0]["Active"]){
              //             var processingControls = updateProcessing.filter(x => x.FieldName == "updateProcessedData")[0]['ControlList']
              //             this.ReturnSetting.netSuiteRAGeneration = processingControls.filter(x=>x.FieldName=='RAGeneration')[0].Active || false;
              //             this.ReturnSetting.netSuiteSOGeneration = processingControls.filter(x=>x.FieldName=='SOGeneration')[0].Active || false;;
              //             this.ReturnSetting.netSuiteCSGeneration = processingControls.filter(x=>x.FieldName=='CSGeneration')[0].Active || false;;
              //             this.ReturnSetting.netSuiteInvoiceGeneration = processingControls.filter(x=>x.FieldName=='InvoiceGeneration')[0].Active || false;;
              //             this.ReturnSetting.netSuiteOrderFetch = processingControls.filter(x=>x.FieldName=='OrderFetch')[0].Active || false;;
              //         }
              //     }
              //   }
            },
            () => {}
          );
        var returnRepair = {
          ReturnSetting: this.ReturnSetting,
          RepairSetting: this.RepairSetting,
        };
        //resolve(this.RepairSetting)
        let pageSettingModel = {
          Return: this.ReturnSetting,
          Repair: this.RepairSetting,
        };

        sessionStorage.setItem(
          'isPageSetting',
          JSON.stringify(pageSettingModel)
        );
        resolve(returnRepair);
      });
    } //uncomment the block amrendra
  }

  getPageSettingFiledValue(
    filedName: string,
    parrent: string,
    root_parrent: string,
    pageSetting?: []
  ) {
    let app_setting: any = [];
    if (pageSetting) {
      app_setting = pageSetting;
    } else {
      app_setting = JSON.parse(
        this._glob.utilities.storage.get('applicationSetting')
      );
      // app_setting=JSON.parse(strappSetting)
    }
    let returnValue: boolean = false;

    try {
      return app_setting.filter(
        (x) =>
          x.fieldname.toLowerCase() == filedName.toLowerCase() &&
          x.parrent.toLowerCase() == parrent.toLowerCase() &&
          x.root_parrent.toLowerCase() == root_parrent.toLowerCase()
      )[0]?.isactive;
    } catch {
      returnValue = false;
    }
    return returnValue;
  }
}

export class TypedJson {
  static parse<T>(value: any): T {
    if (typeof value === 'object' || value === undefined) return value;
    return JSON.parse(value) as T;
  }
}

export class ParseJson {
  static parse(value: any) {
    if (
      value !== '' &&
      value !== null &&
      typeof value !== 'undefined' &&
      typeof value !== 'object'
    ) {
      
      return JSON.parse(value);
    }
    return value;
  }

  static DeepParse(jsonString: any) {
    const parsedData= this.parse(jsonString);
  
    function parse (value){
      if (
        value !== '' &&
        value !== null &&
        typeof value !== 'undefined' &&
        typeof value !== 'object'
      ) {
        
        return JSON.parse(value);
      }
      return value;
    }
    function processObject(obj){
      try{
        for (const key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            processObject(obj[key]);
          } else if (typeof obj[key] === 'string' && obj[key]!='') {
              obj[key] = parse(obj[key]);
          }
        }
      }
       catch (error){
       console.log(error)
      }
    }

      processObject(parsedData);

    return parsedData;
  }
  
static isNumString = (str) => !isNaN(Number(str));
static deepParseJson(jsonString) {
  if (typeof jsonString === 'string') {
    if (this.isNumString(jsonString)) {
      return jsonString;
    }
    try {
      return this.deepParseJson(JSON.parse(jsonString));
    } catch (err) {
      return jsonString;
    }
  } else if (Array.isArray(jsonString)) {
    return jsonString.map(val => this.deepParseJson(val));
  } else if (typeof jsonString === 'object' && jsonString !== null) {
    return Object.keys(jsonString).reduce((obj, key) => {
      const val = jsonString[key];
      obj[key] = this.isNumString(val) ? val : this.deepParseJson(val);
      return obj;
    }, {});
  } else {
    return jsonString;
  }
}
}


export class FormatArray {
  static formatArrayToObject(originalValue: any, keyName: any, ValueName: any) {
    let parsedValue = ParseJson.parse(originalValue);
    let valueToBeReturned;

    if (Array.isArray(parsedValue)) {
      valueToBeReturned = parsedValue.reduce((acc, obj) => {
        acc[obj[keyName]] = obj[ValueName];
        return acc;
      }, {});
    } else {
      valueToBeReturned = parsedValue;
    }

    return valueToBeReturned;
  }
}

export class pageSettingBOCPModel {
  isRepair: boolean;
  isReturn: boolean;
  ExchangeProduct: boolean;
  InternalNote: boolean;
  Files: boolean;
  Discussion: boolean;
  ispaymentConfiguration: boolean;
  isgdpr: boolean;
  guidedSearch: boolean;
  orderSearch: boolean;
  Advanced_Search: boolean;
  sendQuot: boolean;
  netSuiteRAGeneration: boolean;
  netSuiteSOGeneration: boolean;
  netSuiteCSGeneration: boolean;
  netSuiteInvoiceGeneration: boolean;
  isFixedAmount: boolean;
  isApproval: boolean;
  isApproveRejectPermission: boolean;
  isApproveRejectComment: boolean;
  Reverse_Tracking_Advertisements: boolean;
  netSuiteOrderFetch: boolean;
  isRtv: boolean;
  isGenerateInvoice: boolean;
  isGenerateShipment: boolean;
  isReturn_Processing: boolean = false;
  isShipping_Instruction: boolean = false;
  isUnRepairable: boolean;
  serialNumberSearch: boolean;
  Pickup: any[];
  sageRAGeneration: boolean;
  sageSOGeneration: boolean;

  constructor();
  constructor(obj: pageSettingBOCPModel);
  constructor(obj?: any) {
    this.ExchangeProduct = (obj && obj.ExchangeProduct) || false;
    this.InternalNote = (obj && obj.InternalNote) || false;
    this.Files = (obj && obj.Files) || false;
    this.Discussion = (obj && obj.Discussion) || false;
    this.isgdpr = (obj && obj.isgdpr) || false;
    this.ispaymentConfiguration = (obj && obj.ispaymentConfiguration) || false;
    this.guidedSearch = (obj && obj.guidedSearch) || false;
    this.orderSearch = (obj && obj.orderSearch) || false;
    this.netSuiteRAGeneration = (obj && obj.netSuiteRAGeneration) || false;
    this.netSuiteSOGeneration = (obj && obj.netSuiteSOGeneration) || false;
    this.netSuiteCSGeneration = (obj && obj.netSuiteCSGeneration) || false;
    this.netSuiteInvoiceGeneration =
      (obj && obj.netSuiteInvoiceGeneration) || false;
    this.sendQuot = (obj && obj.sendQuot) || false;
    this.isFixedAmount = (obj && obj.isFixedAmount) || false;
    this.isShipping_Instruction = (obj && obj.isShipping_Instruction) || false;
    this.isRepair = (obj && obj.isRepair) || false;
    this.isReturn = (obj && obj.isReturn) || false;
    this.Advanced_Search = (obj && obj.Advanced_Search) || false;
    this.isApproval = (obj && obj.isApproval) || false;
    this.isApproveRejectPermission =
      (obj && obj.isApproveRejectPermission) || false;
    this.isApproveRejectComment = (obj && obj.isApproveRejectComment) || false;
    this.Reverse_Tracking_Advertisements =
      (obj && obj.Reverse_Tracking_Advertisements) || false;
    this.netSuiteOrderFetch = (obj && obj.netSuiteOrderFetch) || false;
    this.isRtv = (obj && obj.isRtv) || false;
    this.isGenerateInvoice = (obj && obj.isGenerateInvoice) || false;
    this.isGenerateShipment = (obj && obj.isGenerateShipment) || false;
    this.isReturn_Processing = (obj && obj.isReturn_Processing) || false;
    this.Reverse_Tracking_Advertisements =
      (obj && obj.Reverse_Tracking_Advertisements) || false;
    this.netSuiteOrderFetch = (obj && obj.netSuiteOrderFetch) || false;
    this.Pickup = (obj && obj.Pickup) || null;
    this.isUnRepairable = (obj && obj.isUnRepairable) || false;
    this.serialNumberSearch = (obj && obj.serialNumberSearch) || false;
    this.sageRAGeneration = (obj && obj.sageRAGeneration) || false;
    this.sageSOGeneration = (obj && obj.sageSOGeneration) || false;
  }
}

export class Property {
  filterValue: string = '';
  booleanText: string;
  LocalAccess: Array<string> = [];
  errorMessage: string;
  reg: any[];
  h_localize: any;
  e_localize: any;
  Permissions: any;
  moduleTitle: string;
  moduleDescription: string;
  //===========new code for getting page setting===========
  customer_display: any;
  pageSetting: any;
  constructor();
  constructor(prop: Property);
  constructor() {
    this.booleanText = 'Activate';
    this.filterValue = null;
  }

  OnValidForm(form: any) {
    if (!form.valid) {
      for (var i in form.controls) {
        form.controls[i].markAsTouched();
      }
      return;
    }
  }
  hasPermission(p: string): boolean {
    return $.inArray(p, this.LocalAccess) != -1;
  }
}

export class MaskString {
  mask(
    startIndex: number,
    numberOfCharacterToBeMasked: number,
    interval: number,
    maskCharacter: string,
    plainString: string
  ): string {
    let finalMaskedString = '';
    let localMaskedInterval: number = 0;
    let localUnMaskedInterval: number = interval;
    let stringToMask = plainString.substr(startIndex, plainString.length);
    let startChars = plainString.substr(0, startIndex);
    stringToMask.split('').forEach((element) => {
      if (localMaskedInterval < numberOfCharacterToBeMasked) {
        if (element != ' ') {
          finalMaskedString += maskCharacter;
          localMaskedInterval++;
        }
      } else {
        if (localUnMaskedInterval == 0) {
          localMaskedInterval = 0;
          localUnMaskedInterval = interval;
        } else {
          finalMaskedString += element;
          localUnMaskedInterval--;
        }
      }
    });
    return startChars + finalMaskedString;
  }
}
