import { Component } from '@angular/core';
import { NavigationStart, Event, NavigationError, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsService } from 'src/environments/ApplicationInsightsService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'rl3x1';
  private _router = Subscription.EMPTY;
  _environment: any;
  loader:boolean=true;
  constructor(private _ApplicationInsightsService: ApplicationInsightsService,
     private router: Router,
   
     
     ) 
     {
    this._router = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {

      }
      if (event instanceof NavigationEnd) {
        this.loader = false;
      }
      if (event instanceof NavigationError) {
        this.router=this.router.navigate[''];
      }
    });
    this._ApplicationInsightsService.logPageView('Return Reason', this.router.url);
    // if(window.location.origin == 'http://localhost:4200'){
    //   this.router.config[4].redirectTo = "portal/coo/rma/en-us";
    // }
    // else if(window.location.origin.includes('actegy')){
    //   this.router.config[4].redirectTo = "portal/coo/rma/en-gb";
    // }
    // else{
    //   this.router.config[4].redirectTo = "portal/coo/rma/en-us";
    // }

  }
  ngOnInit(): void {
   //Loader variable set false after page load
//    setTimeout(()=>{                           
//     // this.loader = false;
//  }, 1000);
 }
}
