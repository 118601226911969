<div class="page-sidebar" id="sidebar">

    <ul class="nav sidebar-menu">
        <li *ngFor="let mod of Menus; let i = index; trackBy: trackByFn" [routerLinkActive]="['active']" [ngClass]="{open:mod.Childs | indexOf: 'NavigateURL': _route}" id="p{{mod.ID}}">
            <a *ngIf="mod.Childs.length > 1" href="javascript:void(0)" class="menu-dropdown">
                <span class="menu-text"><i class="{{mod.LabelCode}}" aria-expanded="true"></i> {{mod.Title}} </span>
                <i class="menu-expand" aria-expanded="true"></i>
            </a>

            <ul *ngIf="mod.Childs.length > 1" class="submenu childUL">
                <li *ngFor="let submenu of mod.Childs; let k = index; trackBy: trackByFn" id="psub{{submenu.ID}}" [ngClass]="{active:_route.indexOf(submenu.NavigateURL) > -1, open:_proute | contains:submenu.Title}">
                    <a *ngIf="submenu.Childs.length == 0" href="javascript:void(0)" (click)="RedirectToPage(submenu.NavigateURL,submenu.module_code, false)" [ngClass]="submenu.Childs.length > 1 ?'menu-dropdown':''">
                        <span class="menu-text spnMenuLabel" id="spn{{submenu.ID}}"><i class="{{submenu.LabelCode}}" aria-expanded="true"></i>{{submenu.Title}}</span>
                        <i *ngIf="submenu.Childs.length > 1" class="menu-expand" aria-expanded="true"></i>
                    </a>
                    <a *ngIf="submenu.Childs.length > 0" href="javascript:void(0)" [ngClass]="submenu.Childs.length > 1 ?'menu-dropdown':''">
                        <span class="menu-text spnMenuLabel" id="spn{{submenu.ID}}"><i class="{{submenu.LabelCode}}" aria-expanded="true"></i>{{submenu.Title}}</span>
                        <i *ngIf="submenu.Childs.length > 1" class="menu-expand" aria-expanded="true"></i>
                    </a>
                    <ul *ngIf="submenu.Childs.length > 1" class="submenu">
                        <li *ngFor="let modk of submenu.Childs" id="psub{{modk.ID}}" [ngClass]="{active:modk.NavigateURL==_route}">
                            <a href="javascript:void(0)" (click)="RedirectToPage(modk.NavigateURL,modk.module_code, false)">
                                <span class="menu-text spnSubMenuLabel1" id="subspn{{modk.ID}}"><i class="{{modk.LabelCode}}" aria-expanded="true"></i>{{modk.Title}}
                                </span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <a href="javascript:void(0)" *ngIf="mod.Childs.length == 1" (click)="RedirectToPage(mod.Childs[0].NavigateURL,mod.Childs[0].module_code, true)" [ngClass]="{active1:_route.indexOf(mod.Childs[0].NavigateURL) > -1}">
                <span class="menu-text"><i class="{{mod.Childs[0].LabelCode}}" aria-expanded="true"></i> {{mod.Childs[0].Title}}</span>
            </a>
        </li>
    </ul>
</div>
<div class="clearfix"></div>
<div class="text-center powered-by">
    Powered by<br>
    <img src="assets/img/RL-logo-powered-by.png" alt="ReverseLogix" /></div>