import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import{AuthGuard} from './auth.guard';
import { environment } from "src/environments/environment";
import { ObjectFlags } from 'typescript';
const routes: Routes = [
  { path: 'portal_unavailable', loadChildren: () => import('./pages/portal/deactivatebrand/deactivatebrand.module').then(m => m.DeactivatebrandModule)},
  { path: 'portal', loadChildren: () => import('./pages/portal/cp-portal/cp-portal.module').then(m => m.CpPortalModule)},
  { path: 'auth', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'back-office', loadChildren: () => import('./back-office/back-office.module').then(m =>m.BackOfficeModule), canActivate:[AuthGuard] },
  { path: 'index', loadChildren: () => import('./authentication/index/index.module').then(m =>m.IndexModule) },
  { path: '', redirectTo: `portal/${environment.brand}/${(environment.hasOwnProperty('page') == true && environment['page'] != null) ? environment['page'] : 'rma'}/en-us`,pathMatch :"full"}, 
  { path: 'samllogin',  loadChildren: () => import('./authentication/samllogin/samllogin.module').then(m => m.SamlloginModule)},
  { path: '**',loadChildren: () => import('./pages/portal/page404/page404.module').then(m => m.Page404Module)},
];
//,scrollPositionRestoration: 'disabled'
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false ,scrollPositionRestoration: 'disabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
