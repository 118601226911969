import { environment } from "src/environments/environment";

export class ConfigurationConstants {    
    public static get ADMINPORTALBASEURL(): string { return environment.node_api +"/manage/"; }
    public static get CPORTALBASEURL(): string { return environment.node_api +"/portal/"; }
    public static get BASEURL(): string {  return environment.node_api +"/"; }
    public static get BASEAPIURL(): string { return environment.node_api +"/"; }
    public static get BASEAPIURLForCarrier(): string { return environment.web_api +"/"; }
    public static get clientbase(): string { return environment.clientbase; }

}

export enum eTypeLookup
{
    AddressType = <any>"AddressType",
    CityCategory = <any>"CityCategory",
    SponsorshipType = <any>"SponsorshipType",
    OrganizationType = <any>"OrganizationType"    
}

export enum eStatus {
    Cancelled= <any>"Cancelled",
    Closed= <any>"Closed",
    InRepair= <any>"In Repair",
    Received= <any>"Received",
    Repaired= <any>"Repaired",
    Shipped= <any>"Shipped",
   
    
    }
    export class CommonConstants {
        //static readonly CityCategory = "CityCategory";
        static readonly NOPREVIEW_IMGPATH = 'assets\\img\\PreviewUnavailable.png';
        static readonly RETURN = "Return";
        static readonly DI_RLAPI_ENV: string = "DI_RLAPI_ENV";
        static readonly DI_RLAPI_RMAEvent: string = "DI_RLAPI_RMAEvent";
        static readonly DI_RLAPI_ITEMEvent: string = "DI_RLAPI_ITEMEvent";
        static readonly RLAPI: string = "RLAPI";
        static readonly CHECKBOX: string = "CHECKBOX";
        static readonly clientId: string = environment.clientId;
        static readonly authority: string = environment.authority;
        static readonly envtype: string = environment.name;

    }    